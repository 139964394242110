import {
    GET_BADGE_SUCCESS,
    GET_BADGE_FAIL
} from "../actions/types";

const badge = {};
const initialState = badge;
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BADGE_SUCCESS:
            return {
                ...state,
                badge: payload,
            };
        case GET_BADGE_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
}