import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CasualThought = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_1/checklist_of_errors", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/what_is_a_causal_thought`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Page Params  *******/
  const title = "Causal Thoughts";

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Causal Thoughts| ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style m-0">{title}</div>
                  <div className="card-body">
                    <div className="welcome-content">
                      {/* <p className="component-heading-style">
                        The causal thoughts that lower social confidence the
                        most can be divided into 3 types.
                      </p>
                      <div style={{ background: '#7640cc1a', borderRadius: '10px' }}>
                        <p className="mb-0" style={{ background: '#6a3851', color: '#fff', padding: '10px 20px' }}>
                          See if you can relate
                          to any of these:
                        </p>
                        <div className="row m-0 m-0" style={{ padding: '20px' }}>
                          <div className="col-lg-4 p-0 pr-lg-3">
                            <p className="normal-text-black">
                              1. Perfectionist standards for social situations.
                            </p>
                            <ul className="listing">
                              <li>I must always sound intelligent and fluent</li>
                              <li>I must not show any signs of weakness</li>
                              <li>
                                I must not interrupt or slow down other people’s
                                speech
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-4 p-0 pr-lg-3">
                            <p className="normal-text-black">
                              2. Negative beliefs about what will happen if you act a
                              certain way in public.
                            </p>
                            <ul className="listing">
                              <li>If I stutter, people won’t like me</li>
                              <li>If I disagree with someone, they’ll reject me</li>
                              <li>If I’m quiet, people will think I’m weird</li>
                            </ul>
                          </div>
                          <div className="col-lg-4">
                            <p className="normal-text-black">
                              3. Concrete negative beliefs about one’s self.
                            </p>
                            <ul className="listing">
                              <li>I’m unlikable</li>
                              <li>I’m boring</li>
                              <li>I’m stupid</li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      <p className="component-heading-style mb-2">
                        Casual Thought Examples
                      </p>
                      <div className="casualThought-table">
                        <p>
                          Let's look at some more examples of causal thoughts in
                          the table below.
                        </p>
                        <table className="table">
                          <tbody>
                            <tr className="headings">
                              <td>
                                <p className="m-0">Common causal thoughts</p>
                              </td>
                              <td>
                                <p className="m-0"> Possible consequences</p>
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                I must be loved and approved of by everyone.
                              </td>
                              <td className="small-text-black">
                                Anxiety, low self-esteem.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                I must be good at everything I do.
                              </td>
                              <td className="small-text-black">
                                Anxiety, shame, procrastination.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                The world should be a fair place and I should
                                always be treated fairly.
                              </td>
                              <td className="small-text-black">
                                Anger, resentment, frustration.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                When I do something badly, I am a bad person, a
                                failure, an idiot.
                              </td>
                              <td className="small-text-black">
                                Low self-esteem, sadness, depression.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                The world should provide me with what I need.
                                Life should be comfortable. I shouldn't have to
                                suffer or be inconvenienced.
                              </td>
                              <td className="small-text-black">
                                Anger, frustration.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                It's easier to avoid problems than to confront
                                and deal with them.
                              </td>
                              <td className="small-text-black">
                                Procrastination, unresolved problems,
                                relationship tension.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                It's impossible to be happy when things aren’t
                                going my way.
                              </td>
                              <td className="small-text-black">
                                Helplessness, hopelessness, failure to take
                                responsibility, despair.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                If there’s a chance something bad might happen,
                                I should stress.
                              </td>
                              <td className="small-text-black">
                                Anxiety, rumination, worry.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                There is a correct solution to every problem,
                                and it’s awful if I can't find it.
                              </td>
                              <td className="small-text-black">
                                Indecision, procrastination, anxiety.
                              </td>
                            </tr>
                            <tr>
                              <td className="small-text">
                                Things should always go the way that I want.
                              </td>
                              <td className="small-text-black">
                                Frustration, anger.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="tip-container">
                          <div className="tip-image-container">
                            <img
                              className="part6_bar_image"
                              src={tipBackground}
                              alt="tip-background"
                              width="923px"
                            />
                          </div>
                          <div className="idea">
                            <img
                              className="part6_bar_image"
                              src={idea}
                              alt="tip"
                            />
                          </div>
                          <div className="tip-content">
                            <span className="whiteText">
                              Did you notice a relationship between "
                              <span className="yellowText">should</span>"{" "}
                              statements and{" "}
                              <span className="yellowText">Anger?</span>
                            </span>
                          </div>
                        </div>
                        {/* (Ellis, A., 2001; Edelman, 2002) */}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous col-5 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-7 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CasualThought;
