import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const GetExperimentCompleted =  () => {
    
    const [experimentCompleted, setExperimentCompleted] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchExperimentCompleted = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `experiment_completed_page/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setExperimentCompleted(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchExperimentCompleted();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /experiment_completed_page/userId' + error);
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /experiment_completed_page/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchExperimentCompleted();
    }, []);
  
  return [experimentCompleted, errorMessage];
};

export {
    GetExperimentCompleted
}