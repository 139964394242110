import { GET_BADGE_SUCCESS, GET_BADGE_FAIL, SET_MESSAGE } from "./types";
import badge from '../services/badge-service'

const getBadgeInfo = () => (dispatch) => {
    return badge().then(
        (response) => {
            dispatch({
                type: GET_BADGE_SUCCESS,
                payload: response
            });
            dispatch({
                type: SET_MESSAGE,
                payload: response.message,
            })
            return Promise.resolve();
        },
        (error) => {
            console.log(error, 'Error while fetching User Badge info')
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            dispatch({ type: GET_BADGE_FAIL, });
            dispatch({ type: SET_MESSAGE, payload: message });
            return Promise.reject();
        }
    )
};

export default getBadgeInfo;