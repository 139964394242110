export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const AUDIO_SUCCESS = "AUDIO_SUCCESS";
export const AUDIO_FAIL = "AUDIO_FAIL";
export const GET_BADGE_SUCCESS = "GET_BADGE_SUCCESS"
export const GET_BADGE_FAIL = "GET_BADGE_FAIL"