import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup } from "react-bootstrap";
import {
  GetWhatWasLearned,
  GetWhatWasLearnedFlag,
} from "../../../../services/part4/what-was-learned";
import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4TwelfthPage = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [postErrorMessage, setErrorMessage] = useState("");

  const userInfo = localStorage.getItem("user"); // Logged in user info
  const user = JSON.parse(userInfo);

  const [whatWasLearned, errorMessage] = GetWhatWasLearned(); // Get Learned Data from api
  const [whatWasLearnedFlag, errorMessageFlag] = GetWhatWasLearnedFlag();

  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [currentRadioValue, setCurrentRadioValue] = useState();
  // error modal
  const [showError, setShowError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleRadioChange = (e) => {
    setCurrentRadioValue(e.target.value);
    setDisabled(false);
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        select_counter: currentRadioValue,
      });
      const response = await axios.post(
        BASE_URL + `yes_no_counter_create_part4/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (currentRadioValue.length !== 0 && currentRadioValue === "yes") {
        handleAudioStoreData();
        navigate("/part_4/avoid", { replace: true });
      } else {
        handleAudioStoreData();
        navigate("/part_4/continue", { replace: true });
        // navigate("/part_4/complete", { replace: true });
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please Part 4 create flag Api");
        ErrorLogging("HTTP POST /part4_flag_create/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/complete", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/behavioural_causal_thought_sample_answers`, {
      replace: true,
    });
  };

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      whatWasLearnedFlag !== undefined &&
      whatWasLearnedFlag.length !== 0 &&
      whatWasLearnedFlag.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>My Result | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-0">My Result</div>
                  <div className="card-body">
                    <p>
                      <span className="normal-text">
                        My causal thought was:
                      </span>{" "}
                      <span className="normal-text-black dark-purple-color">
                        {whatWasLearned.length !== 0 && (
                          <>{whatWasLearned.my_casual_thought_was}</>
                        )}
                      </span>
                    </p>
                    <p>
                      <span className="normal-text">
                        The task I completed was:
                      </span>{" "}
                      <span className="normal-text-black dark-purple-color">
                        {whatWasLearned.length !== 0 && (
                          <>{whatWasLearned.statement}</>
                        )}
                      </span>
                    </p>
                    <p className="">I predicted these three things:</p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : whatWasLearned !== undefined &&
                        whatWasLearned.length !== 0 ? (
                        <div>
                          {whatWasLearned?.pred_prob_data?.map(
                            (item, index) => {
                              const card = (
                                <li className="listing" key={index}>
                                  {item.statement}
                                </li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p className="">This is what happened:</p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : whatWasLearned !== undefined &&
                        whatWasLearned.length !== 0 ? (
                        <div>
                          {whatWasLearned?.This_is_what_happened?.map(
                            (item, index) => {
                              const card = (
                                <li className="listing" key={index}>
                                  {item.userresponce}
                                </li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p className="">
                      Do you still think you would avoid:
                      <span className="normal-text-black dark-purple-color">
                        {" "}
                        {whatWasLearned.length !== 0 && (
                          <>{whatWasLearned.the_task_i_was_compleated}</>
                        )}
                      </span>
                    </p>
                    <div className="radio-button"></div>
                    {errorMessageFlag ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessageFlag}
                          </div>
                        </div>
                      </div>
                    ) : whatWasLearnedFlag !== undefined &&
                      whatWasLearnedFlag.length !== 0 ? (
                      <div className="content-wrap whatLearned">
                        {whatWasLearnedFlag?.data?.map((item, index) => {
                          const card = (
                            <div
                              className="left-content checkbx-sty"
                              key={index}
                            >
                              <InputGroup.Checkbox
                                className="radio-size"
                                defaultChecked={item.responce}
                                disabled={item.disable_status}
                                type="radio"
                                value={item.value}
                                name="radio-item-3"
                                selected={
                                  Boolean(currentRadioValue) &&
                                  currentRadioValue === item.value
                                }
                                onChange={handleRadioChange}
                              />

                              {item.statement}
                            </div>
                          );
                          return card;
                        })}
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  <div>
                                        <FooterOfPrint/>
                                    </div>
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {whatWasLearnedFlag !== undefined &&
                  whatWasLearnedFlag.length !== 0 &&
                  whatWasLearnedFlag.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? "Please tell us if you would or wouldn't avoid this situation."
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{postErrorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4TwelfthPage;
