import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup } from "react-bootstrap";
import {
  GetPredictionQuestion,
  GetPredictionSituation,
} from "../../../../services/part4/predictions-situation-task";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4FifthPage = () => {
  const [predictionSituation, errorMessage] = GetPredictionSituation(); // Get Prediction Situations from api
  const [predictionQuestions, errorPredictionMessage] = GetPredictionQuestion(); // Get Prediction Questions from api

  /*******   States *******/

  const [state, setState] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorApiMessage, setErrorMessage] = useState("");
  const [isPlaying, setIsPlaying] = useState(true);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (state.some((item) => item.predictions_id === name)) {
      setState(state.filter((state) => state.predictions_id !== name));
    } else {
      setState([...state, { predictions_id: name }]);
    }
    if (state.length === 0) {
      setDisabled(true);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state,
      });
      const response = await axios.post(
        BASE_URL + `predictions_userresponce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.length !== 0) {
        navigate("/part_4/prediction_probabilities", { replace: true });
      } else {
        alert("Please select atleast one of the options.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 4 Prediction Userresponse Api");
        ErrorLogging(
          "HTTP POST Part 4 /predictions_userresponce/userId" + error
        );
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/prediction_probabilities", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/time_to_plan`, { replace: true });
  };
  /*******   Hooks  *******/
  useEffect(() => {
    if (state.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

  /************print function******/
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      predictionQuestions !== undefined &&
      predictionQuestions.length !== 0 &&
      predictionQuestions.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Predictions | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div id="main">
                  <div className="title page-title-style  mb-0">
                    Predictions
                  </div>
                  <div className="card-body">
                    <p className="component-heading-style ">RESULT SO FAR:</p>
                    <p className="normal-text-black">
                      <span className="normal-text">
                        Here is your situation:{" "}
                      </span>
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>
                            {predictionSituation?.situationtask_data?.situation}
                          </>
                        )}
                      </span>
                    </p>
                    <p className="normal-text-black">
                      <span className="normal-text">Here is your task: </span>{" "}
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>{predictionSituation?.situationtask_data?.task}</>
                        )}
                      </span>
                    </p>
                    <p className="">Avoid YOUR safety behaviours:</p>
                    <ul>
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : predictionSituation !== undefined &&
                        predictionSituation.length !== 0 ? (
                        <div>
                          {predictionSituation?.safty_behaviour_data?.map(
                            (item, index) => {
                              const card = (
                                <li className="listing" key={index}>
                                  {item.question}
                                </li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p className="">
                      Here are some predictions that you might make about this
                      task. Select the 3 most likely outcomes that you fear:
                    </p>
                    <div className="col-lg-6">
                      {errorPredictionMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorPredictionMessage}
                            </div>
                          </div>
                        </div>
                      ) : predictionQuestions !== undefined &&
                        predictionQuestions.length !== 0 ? (
                        <div className="content-wrap">
                          {predictionQuestions?.Predections?.map(
                            (item, index) => {
                              const card = (
                                <div
                                  className="left-content checkbx-sty"
                                  key={index}
                                >
                                  <InputGroup.Checkbox
                                    defaultChecked={item.responce}
                                    disabled={item.disable_status}
                                    aria-label="checkbox 1"
                                    value={item._id}
                                    name={item._id}
                                    selected={
                                      Boolean(
                                        state[item.predictions_question]
                                      ) &&
                                      state[item.predictions_question] ===
                                        item.predictions_question
                                    }
                                    onChange={handleChange}
                                  />
                                  {item.predictions_question}
                                </div>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                  <FooterOfPrint />
                </div>
                </div>

                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    {predictionQuestions !== undefined &&
                    predictionQuestions.length !== 0 &&
                    predictionQuestions.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? "Select the 3 most likely predictions."
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorApiMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4FifthPage;
