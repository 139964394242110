import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth-service";

export const register =
  (
    user_email,
    user_name,
    age,
    fname,
    Lname,
    password,
    cpassword,
    role_id,
    city,
    country,
    countryCode,
    dialCode,
    my_situation,
    my_situation_options,
    ageGreater18,
    audio_preference,
    superherosect,
    domain_name,
    guardian_first_name,
    guardian_last_name,
    guardian_email,
    guardian_mobile
  ) =>
  (dispatch) => {
    // console.log(countryCode, dialCode, "its check");

    return AuthService.register(
      user_email,
      user_name,
      age,
      fname,
      Lname,
      password,
      cpassword,
      role_id,
      city,
      country,
      countryCode,
      dialCode,
      my_situation,
      my_situation_options,
      ageGreater18,
      audio_preference,
      superherosect,
      domain_name,
      guardian_first_name,
      guardian_last_name,
      guardian_email,
      guardian_mobile
    ).then(
      (response) => {
        console.log(response, "its response");
        dispatch({
          type: REGISTER_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: REGISTER_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      if (data.status === true) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: data?.message,
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: data?.message,
        });
        return Promise.resolve();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data?.message) ||
        error?.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
