import '../../../../styles/adminlte.min.css';

import { Button, InputGroup, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from 'react';

import Footer from '../../../global/Footer';
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from '../../../global/Header';
import ReactTooltip from 'react-tooltip';
import Sidebar from '../../../global/Sidebar';
import { TailSpin } from 'react-loader-spinner';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import { useNavigate } from 'react-router-dom';

const ChallengeAnswersHandout = ({ title, content, responses, link, type, error }) => {
    let navigate = useNavigate();
      // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer">
                <div>
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row content-with-sidebar-row m-0">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div id="main" className="col-md-10  content-widthsidebar content-wrapper">
                        <div className='card'>
                            <div id="main">
                                <div className="card-body p-0 mb-0">
                                    <div className="title page-title-style  m-0">{title}</div>
                                    <div className="card-body">
                                        <p className="normal-text">{content}</p>
                                        {/* <div className="note-textheads"></div> */}
                                        {
                                            error ? (<div className="container mt-5">
                                                <div className='row justify-content-center'>
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {error}
                                                    </div>
                                                </div>
                                            </div>)
                                                :
                                                responses !== undefined && responses.length !== 0 ? (
                                                    <div className="content-wrap">
                                                        <div className="content-topheads-text">
                                                            <p className='component-sub-heading-style text-center'>{responses.statement}</p>
                                                            <div className='thinkingEx'>
                                                                {responses.user_responce.map((item, index) => {
                                                                    const card = (
                                                                        <div className='wrap mb-4' key={index}>
                                                                            <p className='component-heading-style'> {(index + 1) + "." + " " + item.question}</p>
                                                                            <div className='row'>
                                                                                <div className='col-lg-6'>
                                                                                    <table className="thinking_table left_table" border="0"><tbody>
                                                                                        <tr className="questions"><td>Your answer<br />
                                                                                        </td></tr>
                                                                                        <tr className="answers"><td>
                                                                                            {item.userRecord}
                                                                                        </td></tr>
                                                                                    </tbody></table>
                                                                                </div><div className='col-lg-6'>
                                                                                    <table className="thinking_table left_table" border="0"><tbody>
                                                                                        <tr className="questions"><td>Sample answer<br /></td></tr>
                                                                                        <tr className="answers"><td>
                                                                                            {item.sample_answear}
                                                                                        </td></tr>
                                                                                    </tbody></table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    return card;
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                    :
                                                    <div className="loader-icon">
                                                        <TailSpin color="#4f5962" height={50} width={50} />
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                    <FooterOfPrint />
                  </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );

};

export default ChallengeAnswersHandout;