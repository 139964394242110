import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part5Third = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  /*******   Helper Functions  *******/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/advantages_and_disadvantages_of_shoulds", {
      replace: true,
    });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_5/tyranny_of_the_shoulds`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>What do my SHOULDs do for me? | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div className="title page-title-style mb-0">
                  What do my SHOULDs do for me?
                </div>
                <div id="main">
                  <div className="card-body">
                    <p>
                      Just because you hear SHOULD statements in your head,
                      doesn’t mean they’re right. SHOULD statements are
                      irrational. The best way to treat SHOULD statements is to
                      weigh them up with a cost/benefit analysis. In other
                      words, what are your SHOULDs really doing for you? Are
                      they helpful or unhelpful? Are they worth keeping? Let’s
                      look at an example. Below is a cost/benefit analysis of
                      living with a perfectionistic SHOULD about stuttering:
                    </p>
                    <p className=" mb-4 mt-4 text-center component-sub-heading-style">
                      "I SHOULD BE FLUENT AT ALL TIMES"
                    </p>
                    <div className="row mb-5">
                      <div className="col-lg-6">
                        <table className="thinking_table left_table" border="0">
                          <tbody>
                            <tr className="questions">
                              <td>
                                Advantages
                                <br />
                              </td>
                            </tr>
                            <tr className="answers">
                              <td className="listing">
                                <p>
                                  When I'm fluent, I'll feel like I'm doing what
                                  I'm supposed to be doing.
                                </p>
                                <p>I'll work very hard to be fluent.</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <table className="thinking_table left_table" border="0">
                          <tbody>
                            <tr className="questions">
                              <td>Disadvantages</td>
                            </tr>
                            <tr className="answers">
                              <td>
                                <div>
                                  <p>
                                    If I'm not fluent I'll feel guilty and blame
                                    myself.
                                  </p>
                                  <p>
                                    I'll very often feel like a failure and be
                                    upset.
                                  </p>
                                  <p>
                                    I'll never be able to achieve my goal of
                                    ALWAYS being fluent, so I'll be disappointed
                                    lot of the time.
                                  </p>
                                  <p>
                                    I'll be irritated, sad and anxious most of
                                    the time when I have to speak.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p>
                      As you can see, there are lots of serious disadvantages to
                      this SHOULD. It is setting the person up for failure,
                      shame and frustration. Consider the following syllogism or
                      logical reasoning:
                    </p>
                    <p>
                      (A) Teenagers with a history of stuttering tend to be
                      dysfluent from time-to-time.
                    </p>
                    <p>(B) I’m a teenager with a history of stuttering.</p>
                    <p>(C) Therefore, I SHOULD stutter from time-to-time.</p>
                    <p>
                      If you were to do a cost/benefit analysis on this SHOULD
                      you would see its merits.
                    </p>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Third;
