import React from 'react';
import { Helmet } from 'react-helmet';
import ExcerciseAnswers from '../templates/Answers/ExerciseAnswers';
import { GetUserResponse } from '../../../../services/part1/get-user-response';

const ThinkingResponse1 = () => {
    const [userResponse, errorMessage] = GetUserResponse(1);

    /*******   Page Params  *******/
    const title = "'Thought' Exercise 1 - Review";
    const content = "<p class='normal-text'>Compare your answers to ours to see if you’re on the right track. This will help you understand what sorts of thoughts create emotions, or in this case what Goran could have told himself that would make him feel 4 different ways.</p>";
    const images = ['exercise1/45.jpg', 'exercise1/48.jpg', 'exercise1/46.jpg', 'exercise1/47.jpg']

    /*******   Render  *******/

    return (
        <div>
            <Helmet>
                <title>'Thought' Exercise 1 - Your Answers | ASRC iBroadway</title>
            </Helmet>
            <ExcerciseAnswers
                title={title}
                content={content}
                responses={userResponse}
                link='part_1/thinking_exercise_2'
                type={'ThinkingResponse1'}
                sitnum={1}
                error={errorMessage}
                previousLink='part_1/lets_review'
                images={images}
            />
        </div>
    );
};

export default ThinkingResponse1;