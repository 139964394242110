import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../env/Baseurl";
import ErrorLogging from "../error-logs";

const GetAllImageryResponse = () => {
  const [imagery, setImagery] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchImageryResponse = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const response = await axios.get(
        BASE_URL + `create_imagery_response/${user?.userInfo?._id}`,
        {
          "headers": {
            "Authorization": `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setImagery(response?.data?.message);
    } catch (error) {
      if (error.response.status === 401) {
        setErrorMessage("Something went worng, Please Try After Sometime!");
        // RefreshToken(user.userInfo._id);
        // fetchImageryResponse();
      } else if (
        error.response.status === 403 ||
        error.response.status === 500 ||
        error.response.status === 503
      ) {
        setErrorMessage("Internal Server Error, Please Try After Sometime!");
        ErrorLogging(
          "HTTP GET /behavioural_causal_thought_page/userId" + error
        );
      } else {
        setErrorMessage("There is some error, please try again later.");
        ErrorLogging("HTTP GET /get_all_tyranny_of_the_Shoulds" + error);
      }
    }
  };

  useEffect(() => {
    fetchImageryResponse();
  }, []);

  return [imagery, errorMessage];
};

export { GetAllImageryResponse };
