import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7fourthPage/part7fourthPage.css";

import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const Part7FourthPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  /*******  Page Params  *******/
  const title = "My 'Stress' Protection";
  let navigate = useNavigate();

  /*********functions********/
  const nextPage = () => {
    navigate(`/part_7/a_final_thought`, { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_7/postevent_rumination`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******  Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Ways to Protect Yourself | ASRC iBroadway</title>
      </Helmet>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style  m-0">{title}</div>
                  <div className="card-body">
                    <p>
                      Simply anticipating stressful situations, and (where
                      possible) planning to cope with them the best way
                      possible, can protect you against stress. Below are some
                      tips that may help:
                    </p>
                    <div className="main-container mb-5">
                      <div className="sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part7/part7fourthPage/282.jpg`}
                            alt="stress release protection"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="part7-main-container mb-4">
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/283.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/284.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/285.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/286.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/287.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/288.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/289.jpg`}
                          alt="Stress Release tips"
                        />
                      </div>
                      <div className="container">
                        <img
                          src={`${IMAGE_URL}images/part7/part7fourthPage/290.png`}
                          alt="Stress Release tips"
                        />
                      </div>
                    </div>
                    <p>
                      Remember, where possible, DO NOT avoid situations that
                      make you feel anxious. Avoidance may make you feel better
                      in the short term, but it makes anxiety stronger in the
                      future.
                    </p>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part7FourthPage;
