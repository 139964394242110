
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button";
import { DOMAIN_NAME } from "../../env/Baseurl";
import Modal from "react-bootstrap/Modal";
import TopSlider from "./TopSlider";
import downloadMyGraph from "../../actions/DownloadGraph";
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Header = ({ targeted_print_component_id, myprintFunction }) => {
  let id = targeted_print_component_id;

  const [active, setActive] = useState(false);
  const [topCallout, setTopCallout] = useState("");

  /*******  Helper Functions  *******/
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  /*******  Helper Functions  *******/

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  let superHero = user?.userInfo?.superherosect;

  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // for logging out the user
  const logOut = () => {
    localStorage.setItem("userState", "false");
    dispatch(logout());

    if (localStorage.getItem("userState") === "false") {
      navigate("/");
    }
  };

  const sidebarState = () => {
    setActive(!active);
    if (active === false) {
      document.body.classList.remove("sidebar-closed");
      document.body.classList.remove("sidebar-collapse");
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
      document.body.classList.add("sidebar-closed");
      document.body.classList.add("sidebar-collapse");
    }
  };

  

  const handleTopCallOut = () => {
    // if (location?.pathname?.includes("intro")) {
    //   console.log("location?.pathname ", location);

    //   // if (location?.pathname == "/intro/fne_questionnaire") {
    //   //   setTopCallout(`${DOMAIN_NAME}/images/topCallouts/20.png`);
    //   // } else {
    //   //   // setTopCallout("")
    //   // }
    // }
    if (location?.pathname.includes("part_1")) {
      if (location?.pathname == "part_1/anxiety_is_important_in_stuttering") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/21.png`);
      } else if (location?.pathname == "/part_1/thinking_exercise_3") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/22.png`);
      } else if (
        location?.pathname == "/part_1/thinking_exercise_2_sample_answers"
      ) {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/20.png`);
      } else if (location?.pathname == "/part_1/causal_thoughts") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/23.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_2")) {
      if (location?.pathname == "/part_2/challenge_1") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/108.png`);
      } else if (location?.pathname == "/part_2/challenge_2") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/109.png`);
      } else if (location?.pathname == "/part_2/challenge_5") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/110.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_3")) {
      if (location?.pathname == "/part_3/intro") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/138.png`);
      } else if (location?.pathname == "/part_3/lets_recap") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/139.png`);
      } else if (location?.pathname == "/part_3/bens_model_2") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/140.png`);
      } else if (
        location?.pathname == "/part_3/causal_thoughts_and_safety_behaviours"
      ) {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/141.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_4")) {
      if (location?.pathname == "/part_4/intro") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/182.jpg`);
      } else if (location?.pathname == "/part_4/why_it_really_doesnt_matter") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/183.png`);
      } else if (location?.pathname == "/part_4/why_it_really_doesnt_matter") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/183.png`);
      } else if (location?.pathname == "/part_4/the_experiment") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/184.png`);
      } else if (location?.pathname == "/part_4/behavioural_causal_thought") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/185.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_5")) {
      if (location?.pathname == "/part_5/essay") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/212.png`);
      } else if (
        location?.pathname == "/part_5/advantages_and_disadvantages_of_shoulds"
      ) {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/213.png`);
      } else if (location?.pathname == "/part_5/what_do_my_shoulds_do_for_me") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/214.png`);
      } else if (location?.pathname == "/part_5/should_sample_answers") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/215.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_6")) {
      if (location?.pathname == "/part_6/the_problem_of_self-conciousness") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/235.png`);
      } else if (location?.pathname == "/part_6/att") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/236.png`);
      } else if (location?.pathname == "/part_6/sar") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/237.png`);
      } else if (location?.pathname == "/part_6/mental_imagery") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/238.png`);
      } else if (location?.pathname == "/part_6/gaining_control") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/239.png`);
      } else {
        // setTopCallout("")
      }
    } else if (location?.pathname.includes("part_7")) {
      if (location?.pathname == "/part_7/preventing_relapse_2") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/278.jpg`);
      } else if (location?.pathname == "/part_7/ways_to_protect_yourself") {
        setTopCallout(`${DOMAIN_NAME}/images/topCallouts/279.png`);
      } else {
        // setTopCallout("")
      }
    }
  };

  useEffect(() => {
    handleTopCallOut();
  }, []);

  /*******  Render  *******/

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href="#"
            role="button"
            onClick={sidebarState}
          >
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        {/* <li className="nav-item">
              <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                <i className="fa fa-expand"></i></a>
            </li> */}
        {/* <ReactToPrint id={id} /> */}
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            role="button"
            onClick={() => {
              if (id === "bargraph") {
                downloadMyGraph();
              } else {
                // printFunction(id)
                myprintFunction();
              }
            }}
          >
            <i className="fa fa-regular fa-print"></i>
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            role="button"
            onClick={handleShow}
          >
            <i className="fa fa-power-off"></i>
          </a>
        </li>
      </ul>
      {topCallout && <TopSlider topCallOut={topCallout} />}
      <Modal show={show}>
        <Modal.Body>
          <p>
            Are you sure you want to log out? Remember to save your responses.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="modal-no-button"
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={logOut}
            className="user-reponse-submit btn btn-primary link-sty"
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default Header;
