import React, { useState } from "react";
import AuthService from "../../../services/auth-service";
import Modal from "react-bootstrap/Modal";
import "../../../styles/login.css";
import { Helmet } from "react-helmet";
import { DOMAIN_NAME, IMAGE_URL } from "../../../env/Baseurl";

const ResetPasswordScreen = () => {
  /*******   States  *******/
  const [email, setEmail] = useState({
    value: "",
    hasError: false,
    touched: false,
  });

  const [logineEmptyCheck, setloginEmptyCheck] = useState({
    email: "",
  });

  // success and error messages
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //   success and error message modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  /*******   HelperFunctions  *******/
  const changeHandler = (e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    let hasError = false;
    if (
      !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
        inputValue
      )
    ) {
      hasError = true;
    }
    setEmail((currentValue) => ({
      ...currentValue,
      value: e.target.value,
      hasError,
    }));
  };

  const blurHandler = () => {
    setEmail((currentValue) => ({
      ...currentValue,
      touched: true,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.value === "") {
      setloginEmptyCheck({ email: "Please fill out Email field." });
    }
    if (email.value !== "" && email.hasError === false) {
      AuthService.resetPassword(email.value)
        .then((res) => {
          if (res.status === true) {
            setSuccessMessage(res.message);
            handleShow();
          } else if (res.status === false) {
            setSuccessMessage(
              "This Email does not exist, Please Try with Registered Email!"
            );
            handleShow();
          }
        })
        .catch((error) => {
          setErrorMessage("Network Error, Please try again!");
          handleShow();
        });
    } else {
    }
  };

  // close modal on its own

  setTimeout(handleClose, 6000);

  /*******   Render  *******/

  return (
    <div className="d-flex container align-items-center login-container-wrap justify-content-center px-0">
      <Helmet>
        <title>Reset your Password| ASRC iBroadway</title>
      </Helmet>
      <div className="row align-items-center login-col-wrap justify-content-center">
        <div className="col-md-6 loginimagestyle">
          <div className="text-center">
            <img
              height="auto"
              width="450px"
              src={`${IMAGE_URL}images/login/Untitled-3.png`}
              alt="Login Group"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h3 className="text-center pagehead-texttitle">Reset Password</h3>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group forminut-passeye">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="input-label-style"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-icon-space"
                    id="exampleInputEmail1"
                    placeholder="Your Email Address..."
                    onChange={changeHandler}
                    onBlur={blurHandler}
                  />
                  <div className="passShow-eye">
                    <i className="fa fa-user"></i>
                  </div>

                  <div>
                    {email.value === "" && (
                      <small className="text-danger input-label-style error-message-style">
                        {" "}
                        {logineEmptyCheck.email}
                      </small>
                    )}
                  </div>
                  <div>
                    {email.touched && email.hasError && (
                      <small className="text-danger input-label-style error-message-style">
                        Please enter a valid email
                      </small>
                    )}
                  </div>
                  <div className="text " style={{ marginTop: "20px" }}>
                    <p
                      style={{ fontSize: "16px", fontWeight: "normal" }}
                      className="input-label-style"
                    >
                      Password reset instructions will be sent to your
                      registered email address.
                    </p>
                  </div>
                </div>
                <div className="form-group login-submit">
                  <button
                    type="submit"
                    className="btn blue-style-btns btn-sm btn-block"
                    style={{
                      fontSize: "20px",
                      lineHeight: "21px",
                      height: "53px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        {successMessage ? (
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "ComicNeue-Regular",
            }}
          >
            {successMessage}
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "ComicNeue-Regular",
            }}
          >
            {errorMessage}
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default ResetPasswordScreen;
