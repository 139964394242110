import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetWhatWasLearned } from "../../../../services/part4/what-was-learned";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";

const Handout4 = () => {
  const [whatWasLearned, errorMessage] = GetWhatWasLearned();
  // const [successMessage, setSuccessMessage] = useState("");
  // const [errorApiMessage, setErrorMessage] = useState("");
  const preTreatmentProgress = localStorage.setItem("preTreatmentProgress", 12);
  // const userInfo = localStorage.getItem("user");
  // const user = JSON.parse(userInfo);
  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Handout - Behavioural Experiment| ASRC iGlebe</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-10 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div className="title page-title-style m-0">
                  Handout - Behavioural Experiment
                </div>
                <div className="card-body">
                  <p>
                    <span className="">My causal thought was:</span>{" "}
                    {whatWasLearned.length !== 0 && (
                      <span className="normal-text-black">
                        {whatWasLearned?.my_casual_thought_was}
                      </span>
                    )}
                  </p>
                  <p>
                    <span className="">The task I completed was:</span>{" "}
                    {whatWasLearned.length !== 0 && (
                      <span className="normal-text-black">
                        {whatWasLearned?.statement}
                      </span>
                    )}
                  </p>
                  <p className="">I predicted these three things:</p>
                  <ul>
                    {whatWasLearned !== undefined &&
                    whatWasLearned.length !== 0 ? (
                      <div>
                        {whatWasLearned?.pred_prob_data?.map((item, index) => {
                          const card = (
                            <li className="listing" key={index}>
                              {item.statement}
                            </li>
                          );
                          return card;
                        })}
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </ul>
                  <p className="">This is what happened:</p>
                  <ul>
                    {whatWasLearned !== undefined &&
                    whatWasLearned.length !== 0 ? (
                      <div>
                        {whatWasLearned?.This_is_what_happened?.map(
                          (item, index) => {
                            const card = (
                              <li className="listing" key={index}>
                                {item.userresponce}
                              </li>
                            );
                            return card;
                          }
                        )}
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <FooterOfPrint />
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout4;
