import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const LetsReview = () => {
  /*******   Page Params  *******/

  const title = "Let's Review";

  const content =
    "<div><p>Now that you have created some thoughts that might have caused Goran's various emotions, let's review our sample answers for this task. That way, you can compare your answers to ours to see if you are on the right track.</p><p>This will help you learn about what sorts of thoughts create different emotions. Notice that our answers only include THOUGHTS. Don't forget that it is THOUGHTS that cause emotions and behaviours.</p><p>It's a good idea to print out or save the next page and keep it in a file.</p><div className='row border p-3'><div className='col-lg-4'><a href='/part_1/causal_thoughts' rel='prev' title='Go to previous page'><b>‹</b> Causal Thoughts</a> </div> <div className='col-lg-4'> <a href='/part_1/anxiety_is_important_in_stuttering' title='Go to parent page'>Up</a> </div><div className='col-lg-4'><a href='/part_1/thinking_exercise_3' rel='next' title='Go to next page'>'Thinking' Exercise 3 <b>›</b></a> </div> </div></div>";

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_1/thinking_exercise_1_sample_answers", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_1/thinking_exercise_1`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Let's Review | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div>
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <div className="title page-title-style mb-0">{title}</div>
                    <div className="card-body">
                      <p>
                        Now that you have created some thoughts that might have
                        caused Goran's various emotions, let's review our sample
                        answers for this task. That way, you can compare your
                        answers to ours to see if you are on the right track.
                      </p>
                      <p>
                        This will help you learn about what sorts of thoughts
                        create different emotions. Notice that our answers only
                        include THOUGHTS. Don't forget that it is THOUGHTS that
                        cause emotions and behaviours.
                      </p>
                      <p className="mb-0">
                        It's a good idea to print out or save the next page and
                        keep it in a file.
                      </p>
                    </div>
                    <div>
                      <FooterOfPrint />
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LetsReview;
