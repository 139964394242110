import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAllTyrannyShouldsQuestions } from "../../../../services/part5/tyranny-of-the-shoulds-questions";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part5Fourth = () => {
  const [tyrannyShouldsQuestions, errorMessage] =
    GetAllTyrannyShouldsQuestions(); // Get Tyranny Should Questions from Api
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [postErrorMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);
  const [state, setState] = useState([
    {
      question_id: "",
      disadvantage_userresponce: "",
      advantage_userresponce: "",
    },
    {
      question_id: "",
      disadvantage_userresponce: "",
      advantage_userresponce: "",
    },
    {
      question_id: "",
      disadvantage_userresponce: "",
      advantage_userresponce: "",
    },
  ]);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const isEmpty = state.filter(function (o) {
    var values = Object.values(o);
    var arr2 = [""]; //array of values you don't want.
    if (
      arr2.some(function (val) {
        return values.indexOf(val) >= 0;
      })
    )
      //checks if atleast one value of arr2 is in values.
      return false;
    else return true;
  });

  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

  const handleChange = (inputId, arrayIndex) => (e) => {
    const { value } = e.target;
    const { name } = e.target;
    const newState = state.map((obj, index) => {
      if (index === arrayIndex) {
        if (name === "advantage_userresponce") {
          return {
            ...obj,
            question_id: inputId,
            advantage_userresponce: value,
          };
        } else {
          return {
            ...obj,
            question_id: inputId,
            disadvantage_userresponce: value,
          };
        }
      }
      return obj;
    });

    setState(newState);

    if (value !== "" || value === "") {
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage: "Please fill all the responses continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL +
          `advantage_disadvantage_of_should_statement_usersubmit/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.length === 3) {
        handleAudioStoreData();
        navigate("/part_5/should_sample_answers", { replace: true });
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL +
          `advantage_disadvantage_of_should_statement_usersubmit/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/should_sample_answers", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_5/what_do_my_shoulds_do_for_me`, { replace: true });
  };
  // close modal on its own

  setTimeout(handleClose, 1000);

  /*******   Hooks  *******/

  useEffect(() => {
    const arr1 = Array.from(
      state.filter((e) =>
        specialCharactersRegex.test(e.disadvantage_userresponce)
      )
    );
    const arr2 = Array.from(
      state.filter((e) => specialCharactersRegex.test(e.advantage_userresponce))
    );

    if (
      tyrannyShouldsQuestions !== undefined &&
      tyrannyShouldsQuestions.length !== 0
    ) {
      if (isEmpty.length === 3 && arr1.length === 0 && arr2.length === 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [state]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render   *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            Advantages and Disadvantages of SHOULD Statements | ASRC iBroadway
          </title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div className="title page-title-style">
                  Advantages and Disadvantages of SHOULD Statements
                </div>
                <div id="main">
                  <div className="card-body">
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : tyrannyShouldsQuestions !== undefined &&
                      tyrannyShouldsQuestions.length !== 0 ? (
                      <div className="content-wrap">
                        <div>
                          {tyrannyShouldsQuestions.data.map((item, index) => {
                            const card = (
                              <div className="left-content" key={index}>
                                <div className="row ipForms">
                                  <div className="col-lg-12 col-sm-12 col-xs-12 order-md-1">
                                    <p className="questions"></p>
                                    <p className="component-heading-style mb-0">
                                      MY{" "}
                                      {{ 0: "1st", 1: "2nd", 2: "3rd" }[index]}{" "}
                                      SHOULD
                                    </p>
                                    <p className="text-center  my-4">
                                      <b className="topQuestion component-sub-heading-style">
                                        {item.question_id.question}
                                      </b>
                                    </p>
                                    <p className="mb-0">
                                      Below is your{" "}
                                      {{ 0: "1st", 1: "2nd", 2: "3rd" }[index]}{" "}
                                      selected should.
                                    </p>
                                    <p className="mb-3">
                                      List the advantages and disadvantages of
                                      thinking this thought:
                                    </p>
                                    {/* <p className="mt-3 mb-3">
                                      Add{" "}
                                      {{ 0: "1st", 1: "2nd", 2: "3rd" }[index]}{" "}
                                      SHOULD statement selected
                                    </p> */}
                                    <p className="mt-3 mb-3 component-sub-heading-style">
                                      Advantages:
                                    </p>
                                    <div className="textbox">
                                      <textarea
                                        className="form-control"
                                        readOnly={item.disable_status}
                                        id="ControlTextarea"
                                        rows="10"
                                        placeholder="Your Answer Here..."
                                        defaultValue={
                                          item.advantage_userresponce
                                        }
                                        name="advantage_userresponce"
                                        onChange={handleChange(
                                          item.question_id._id,
                                          index
                                        )}
                                      />
                                    </div>
                                    {textBoxValidation.id ===
                                      item.question_id._id && (
                                      <small className="text-danger">
                                        {textBoxValidation.errorMessage}
                                      </small>
                                    )}
                                    <p className="component-sub-heading-style mt-3 mb-3">
                                      Disadvantages:
                                    </p>
                                    <div className="textbox">
                                      <textarea
                                        className="form-control"
                                        readOnly={item.disable_status}
                                        id="ControlTextarea"
                                        rows="10"
                                        placeholder="Your Answer Here..."
                                        defaultValue={
                                          item.disadvantage_userresponce
                                        }
                                        name="disadvantage_userresponce"
                                        onChange={handleChange(
                                          item.question_id._id,
                                          index
                                        )}
                                      />
                                    </div>
                                    {textBoxValidation.id ===
                                      item.question_id._id && (
                                      <small className="text-danger">
                                        {textBoxValidation.errorMessage}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                            return card;
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>

                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    {tyrannyShouldsQuestions !== undefined &&
                    tyrannyShouldsQuestions.length !== 0 &&
                    tyrannyShouldsQuestions.global_disable_status === false ? (
                      <>
                        <Button
                          className="user-reponse-submit  btn btn-primary link-sty m-0 mr-2"
                          onClick={saveDraft}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {tyrannyShouldsQuestions !== undefined &&
                    tyrannyShouldsQuestions.length !== 0 &&
                    tyrannyShouldsQuestions.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? textBoxValidation.disabledMessage
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body>{successMessage}</Modal.Body>
            ) : (
              <Modal.Body>{errorMessage}</Modal.Body>
            )}
          </Modal>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{postErrorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Fourth;
