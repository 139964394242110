import React, { useState, useCallback, useEffect } from 'react';
import { PiSpeakerSimpleHighFill, PiSpeakerSimpleXFill } from "react-icons/pi";
import '../../styles/audioProgressBar/audioProgressBar.css'


const VolumeSlider = ({ volume, setVolume, volumeRef, audioRef }) => {
    const [muteVolume, setMuteVolume] = useState(false);

    const handleMute = () => {
        setMuteVolume((prev) => !prev)
        if (muteVolume)
            setVolume(100)
        else
            setVolume(0)
    }

    const handleVoume = (e) => {
        audioRef.current.volume = volumeRef.current.value / 100;
        setVolume(e.target.value)
        // console.log(volumeRef.current.value, "its volume value")
    }

    useEffect(() => {
        audioRef.current.muted = muteVolume;
    }, [muteVolume])

    return (
        <div className="controls-wrapper">
            <div className="volume">
                <div onClick={handleMute}>
                    {
                        muteVolume ? <PiSpeakerSimpleXFill style={{ fontSize: '30px' }} />
                            :
                            <PiSpeakerSimpleHighFill style={{ fontSize: '30px' }} />
                    }
                </div>
                <input
                    style={{
                        background: `linear-gradient(to right, var(--mahroon) ${volume}%, #ccc ${volume}%)`,
                    }}
                    type="range"
                    ref={volumeRef}
                    muted={muteVolume}
                    // min={0}
                    // max={100}
                    value={volume}
                    // onChange={(e) => setVolume(e.target.value)} 
                    onChange={handleVoume}

                />
            </div>
        </div>
    )
}

export default VolumeSlider;