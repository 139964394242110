import React, { useState } from "react";
import "../../styles/Slider/slider.css";
import close from "../../assets/GIf/close.svg";
// import { closeMotivatorsSlider } from "../../services/helpers";

const Slider = ({ motivatorsImage }) => {
  const [isClose, setIsClose] = useState(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /************ Function to close motivators slider **************/
  //   const closeMotivatores = () => {
  //     var mySays = document.getElementById("says");
  //     console.log(mySays);
  //     mySays.classList.add("says-hidden");
  //     setIsClose(true);
  //   };


  return (
    <>
      {motivatorsImage !== "" ? (
        <div className={`says ${isClose ? "says-hidden" : ""}`} id="says">
          <div className="topBanner">
            <h1>{{
              superStrong: 'SUPERSTRONG',
              superBrave: 'SUPERBRAVE',
              superCalm: 'SUPERCALM',
              superFearless: 'SUPERFEARLESS',
              superResilient: 'SUPERRESILIENT',
              superSmart: 'SUPERSMART',
            }[user?.userInfo?.superherosect]} Says</h1>
            <img
              id="close"
              onClick={() => setIsClose(true)}
              className="imageStyle"
              src={close}
              alt="close"
            />
          </div>

          <div className="imageDiv">
            <img className="imageStyle" src={motivatorsImage} alt="heroQuote" />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Slider;
