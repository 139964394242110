import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeExercise from '../templates/InputForms/ChallengeExercise';
import { GetChallengeQuestions } from '../../../../services/part2/get-challenge-forms';

const Challenge1 = () => {

    const [challengeQuestions, errorMessage] = GetChallengeQuestions('challange_show1');
    // Input Questions list from API

    /*******   Page Params  *******/
    const title = "Challenge My Thinking: Exercise 1";
    const content = "";
    const images = ["Answer_1.jpg",
        "Answer_1.jpg",
        "Answer_1.jpg",
        "Answer_1.jpg"]

    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge My Thinking: Exercise 1 | ASRC iBroadway</title>
            </Helmet>
            <ChallengeExercise
                title={title}
                content={content}
                questions={challengeQuestions}
                type={'create_chall1'}
                link='part_2/challenge_1_sample_answers'
                subtitle={'This is where the program starts getting more personal. Let’s look at one of the hassling thoughts you ticked on the opening questionnaire in this program.'}
                error={errorMessage}
                previousLink='part_2/challenge_example'
                thoughNumber="first"
                images={images}
            />
        </div>
    );
};

export default Challenge1;