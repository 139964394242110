import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CheckboxSituations = ({
  title,
  imageTitle,
  body,
  checkboxQuestion,
  link,
  checkboxeslist,
  subtitle,
  type,
  validationMessage,
  imageSrc,
  error,
  previousLink,
}) => {
  /*******   States *******/
  const [state, setState] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (state.some((item) => item._id === name)) {
      setState(state.filter((state) => state._id !== name));
    } else {
      setState([...state, { _id: name }]);
    }
    if (state.length === 0) {
      setDisabled(true);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); //  Logged in user info
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state,
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.length !== 0) {
        handleAudioStoreData();
        navigate(`${link}`, { replace: true });
      } else {
        alert("Please select atleast one of the options.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 3 Checkbox situations Api");
        ErrorLogging("HTTP POST Part  3 /_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`${link}`, { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/${previousLink}`, { replace: true });
  };

  /*******   Hooks  *******/

  useEffect(() => {
    if (state.length !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      checkboxeslist !== undefined &&
      checkboxeslist.length !== 0 &&
      checkboxeslist.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body  p-0 mb-0">
                  <div className="title page-title-style mb-0">{title}</div>
                  <div className="card-body">
                    {subtitle && (
                      <p className="component-heading-style margin-bottom-0">{subtitle}</p>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: body }}></div>
                    <div className="row">
                      <div className="col-lg-6 p-2 ">
                        <p className="normal-text-black">
                          {imageTitle}
                        </p>
                        <div className="img-how-to-sty">
                          <img className="checkboxImages" src={imageSrc} alt="Social Anxiety Model" />
                        </div>
                      </div>
                      <div className="col-lg-6 p-2">
                        <p className="normal-text-black">
                          {checkboxQuestion}
                        </p>
                        {error ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {error}
                              </div>
                            </div>
                          </div>
                        ) : checkboxeslist !== undefined &&
                          checkboxeslist.length !== 0 ? (
                          <div className="content-wrap">
                            {checkboxeslist.questionanxity.map(
                              (item, index) => {
                                const card = (
                                  <div
                                    className="left-content checkbx-sty"
                                    key={index}
                                  >
                                    <InputGroup.Checkbox
                                      defaultChecked={item.checkbox_status}
                                      disabled={item.disable_status}
                                      aria-label="checkbox 1"
                                      value={item.question}
                                      name={item._id}
                                      selected={
                                        Boolean(state[item.question]) &&
                                        state[item.question] === item.question
                                      }
                                      onChange={handleChange}
                                    />
                                    {item.question}
                                  </div>
                                );
                                return card;
                              }
                            )}
                          </div>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {checkboxeslist !== undefined &&
                    checkboxeslist.length !== 0 &&
                    checkboxeslist.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={disabled === true ? validationMessage : ""}
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckboxSituations;
