import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeAnswersHandout from '../templates/ChallengeAnswersHandout';
import { GetChallengeResponse } from '../../../../services/part2/get-challenge-response';


const Handout2 = () => {

    const [challengeResponse, errorMessage] = GetChallengeResponse('1');
    const title = "Handout - Challenging Thinking";
    const content = "Now compare your answers to our sample answers!";
    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Handout - Challenging Thinking | ASRC iBroadway</title>
            </Helmet>
            <ChallengeAnswersHandout
                title={title}
                content={content}
                responses={challengeResponse}
                link='part_2/challenge_2'
                error={errorMessage}
            />
        </div>
    );

};

export default Handout2;