import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { Helmet } from 'react-helmet';

const VideoPlaying = () => {
    return (
        <>
            <div className="dashboardContent">
                <Helmet>
                    <title>Video! | ASRC iBroadway</title>
                </Helmet>
                <div>
                    <Header targeted_print_component_id={"bargraph"} />
                </div>

                <div className="row col-md-12 m-0">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>

                    <div className='col-md-12 content-widthsidebar content-wrapper' >
                        {/* <div id="bargraph">
                        <div className="row header-titlewith-text m-0">
                            <div className="title col-md-6 p-0">
                                <p>Superhero Movie</p>
                            </div>
                            <div className="col-md-6 text-right p-0">
                                <span> Welcome</span> / {Title}
                            </div>
                        </div>
                        <div id="bargraph" className='marginBotoom mt-3 card mb-4'>
                            <div className="content-topheads-text tophead-container">
                                <div className='visual-container-complete-program'>
                                    <img src={celbration1} className='background-image-gif' alt="backgroud-visuals">
                                    </img>
                                    <div className="textOver-graphics textOver-graphics-complete-program">
                                        <p>
                                            {content}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-title p-3' style={{ borderBottom: '1px solid #8080802e' }} id="card1">Your results for the program are below:</div>
                            <div className="alignCenter p-3">
                                <div className='lastBar' >
                                    <Bar options={options} data={data} />
                                </div>
                            </div>

                            <div className='main-comicContainer'>
                                <div className='row comic-container'>
                                    <div class="video-container">
                                        <div className='image-contaier'>
                                            <img className='imageStyle' src={celbration1}  ></img>
                                        </div>
                                        <div className='info-container'>
                                            <h1 className='title'>
                                                Watch Your Free Superhero Movie
                                            </h1>
                                            <p className='text'>
                                                Lorem ipsum dolor sit amet consectetur. Sit mauris vitae imperdiet proin. Proin fames pulvinar imperdiet sit justo turpis. Dictum hac bibendum diam eget elementum a ac lobortis velit. Quis arcu enim tortor.
                                            </p>
                                            <div className='btn btn-sm btn-block button' onClick={() => playVideo(user?.userInfo?.superherosect)}>
                                                <img src={play} alt="play-button">
                                                </img><span>Watch Your Free Movie</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pdf-container'>
                                        <div className='image-contaier'>
                                            <img className='imageStyle' src={celbration1}  ></img>
                                        </div>
                                        <div className='info-container'>
                                            <h1 className='title pdf-title'>
                                                Download Your Free Comic Book
                                            </h1>
                                            <p className='text pdf-text'>
                                                Lorem ipsum dolor sit amet consectetur. Sit mauris vitae imperdiet proin. Proin fames pulvinar imperdiet sit justo turpis. Dictum hac bibendum diam eget elementum a ac lobortis velit. Quis arcu enim tortor.
                                            </p>
                                            <div onClick={downloadCopy} className='btn btn-sm btn-block button pdf-button'>
                                                <img src={download} alt="play-button">
                                                </img><span className='pdf-but-text'>Download Your Comic Book</span>
                                            </div>
                                        </div>
                                    </div>
                                    <video className='comic-video' controls onEnded={() => handleAudioEnd(user?.userInfo?.superherosect)}>
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div> */}


                        {/* <video src={video} width="100%" height="400" controls /> */}


                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    )


}
export default VideoPlaying;
