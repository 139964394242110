import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part4/part4firstpage/part4firstpage.css";
import "../../../../styles/accordian/accordian.css";

import {
  CounterInitialize,
  FlagInitialize,
} from "../../../../services/part4/counter-initialize";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const Part4FirstPage = () => {
  const moduleName = localStorage.setItem("moduleName", "Part 4");
  const part1Accessed = localStorage.setItem("part4Accessed", "true");
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  const title1 = "Module 4: My Experiments";
  const title2 = "Example of a Behavioural Experiment";

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/perfectionism", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/complete`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  useEffect(() => {
    CounterInitialize();
    FlagInitialize();
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> My Experiments | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div>
              {/* <div className="row header-titlewith-text m-0">
                                <div className="col-md-6 p-0 main-title-style">
                                    Module 4 - My Experiments
                                </div>
                                <div className="col-md-6 text-right p-0">
                                    <span> Welcome</span> / Module 4 - My Experiments
                                </div>
                            </div> */}
              <div className="card part4-first-Page">
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title1}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p className="component-sub-heading-style">
                              What are behavioural experiments?
                            </p>
                            <p>
                              Behavioural experiments are a way to test whether
                              the thoughts you have or predictions you make
                              about certain situations are accurate. They
                              involve:
                            </p>
                            <div className="main-container my-4">
                              <div className="part-4-container">
                                <div className="image-container">
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/181.png`}
                                    alt="behavioural experiment"
                                  />
                                </div>
                              </div>
                              <div className="part-4-container">
                                <div className="image-container">
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/182.png`}
                                    alt="behavioural experiment2"
                                  />
                                </div>
                              </div>
                              <div className="part-4-container">
                                <div className="image-container">
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/183.png`}
                                    alt="behavioural experiment3"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>Let’s look at an example on the next page!</p>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 1 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 1) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 1 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title2}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <div className="row example-container-reverse mt-0">
                              <div className="text-box">
                                <p className="component-heading-style">
                                  Tammy has a moderate stutter and she avoids
                                  going into shops by herself because she has
                                  the negative prediction shown in this image.
                                </p>
                              </div>
                              <div className="image-box">
                                <div className="main-container">
                                  <div className="sub-container center-align">
                                    <div className="image-container">
                                      <img
                                        src={`${IMAGE_URL}images/part4/part4firstPage/184.jpg`}
                                        alt="negative prediction"
                                        width=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row example-container mt-0">
                              <div className="image-box">
                                <div className="main-container">
                                  <div className="sub-container center-align">
                                    <div className="image-container">
                                      <img
                                        src={`${IMAGE_URL}images/part4/part4firstPage/185.png`}
                                        alt="negative prediction"
                                        width=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-box">
                                <p className="component-heading-style">
                                  {" "}
                                  We came up with the following behavioural
                                  experiment to test out whether or not Tammy’s
                                  prediction about the shop was accurate.{" "}
                                </p>
                              </div>
                            </div>
                            <div className="task-main-container">
                              <div className="task-container">
                                <div className="image-container">
                                  {" "}
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/186.png`}
                                    alt="task"
                                  />
                                </div>
                                <div className="image-container">
                                  {" "}
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/188.png`}
                                    alt="outcome"
                                  />
                                </div>
                              </div>
                              <div className="task-container">
                                <div className="image-container">
                                  {" "}
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/187.png`}
                                    alt="task description"
                                  />
                                </div>
                                <div className="image-container">
                                  {" "}
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/189.png`}
                                    alt="outcome description"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="main-container mb-4">
                              <div className=" sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/190.jpg`}
                                    alt="Tammy"
                                  />
                                </div>
                              </div>
                            </div>
                            <p>
                              The actual outcome was different from Tammy’s
                              prediction. Although Tammy did have some
                              difficulty with her speech, she was able to
                              communicate her request for her change to be given
                              a certain way. The shop owner may (or may not)
                              have noticed Tammy’s difficulties, but he did not
                              laugh at her as predicted. Instead he was very
                              nice.
                            </p>
                            <p>
                              What does this say about the accuracy of Tammy’s
                              prediction?
                            </p>
                            <p>
                              Answer: Humans (including Tammy) tend to badly
                              over-estimate the likelihood and severity of their
                              predicted outcome.
                            </p>
                            <div className="main-container my-4">
                              <div className=" sub-container center-align">
                                <div className="image-container">
                                  <img
                                    src={`${IMAGE_URL}images/part4/part4firstPage/191.png`}
                                    alt="answer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6  px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6  px-0">
                    <Button
                      disabled={buttonState}
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4FirstPage;
