import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { DOMAIN_NAME } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAnxietyModel } from "../../../../services/part3/get-society-anxiety-model";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const Handout3 = () => {
  const [anxietyModel, errorMessage] = GetAnxietyModel();

  const preTreatmentProgress = localStorage.setItem("preTreatmentProgress", 13);

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_4/intro", { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> Handout - My Anxiety Model| ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-10 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body mb-0 p-0">
                <div className="title page-title-style mb-3">
                  Handout - My Finished Model
                </div>
                <div className="outer-main-div">
                  <div className="content-wth-bg">
                    <div
                      style={{
                        backgroundImage: `url(${DOMAIN_NAME}/images/part3/mind-map.png)`,
                        backgroundRepeat: "no-repeat",
                        width: "1250px",
                        backgroundPositionX: "center",
                      }}
                      className="mainContent"
                    >
                      <div className="afterDiv">
                        <div className="first-content-ul pt-3">
                          <ul className="pt-3">
                            <li className="pt-3 comic-font">
                              <li
                                className="pt-1 text-bold"
                                style={{ textAlign: "left" }}
                              >
                                {anxietyModel.length !== 0 && (
                                  <>{anxietyModel?.your_situations?.data}</>
                                )}
                              </li>
                            </li>
                          </ul>
                        </div>
                        <br />
                        <br />
                        <div className="second-content-ul pt-3">
                          <ul className="pt-3">
                            <li className="pt-3">
                              <li
                                className="pt-3 comic-font text-bold"
                                style={{ textAlign: "left" }}
                              >
                                {anxietyModel.length !== 0 && (
                                  <>{anxietyModel?.your_casuals?.data}</>
                                )}
                              </li>
                            </li>
                          </ul>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div id="lowerRow" className="row m-3 p-3">
                          <div className="col-md-4 m-3 p-1" id="firstCol">
                            {/* first column */}
                            <ul>
                              {anxietyModel !== undefined &&
                              anxietyModel.length !== 0 ? (
                                <div
                                  className="first-column-content"
                                  style={{ width: "100px" }}
                                >
                                  {anxietyModel?.your_behiviour?.map(
                                    (item, index) => {
                                      const card = (
                                        <li
                                          className="comic-font text-bold"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      );
                                      return card;
                                    }
                                  )}
                                </div>
                              ) : (
                                <div className="loader-icon">
                                  <TailSpin
                                    color="#4f5962"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              )}
                            </ul>
                          </div>
                          <div className="col-md-4 m-3 p-1" id="secondCol">
                            {/* second column */}
                            <ul>
                              {anxietyModel !== undefined &&
                              anxietyModel.length !== 0 ? (
                                <div>
                                  {anxietyModel.your_image.map(
                                    (item, index) => {
                                      const card = (
                                        <li
                                          className="comic-font text-bold"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      );
                                      return card;
                                    }
                                  )}
                                </div>
                              ) : (
                                <div className="loader-icon">
                                  <TailSpin
                                    color="#4f5962"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              )}
                            </ul>
                          </div>
                          <div className="col-md-4 m-3 p-1" id="thirdCol">
                            {/* third colimn */}
                            <ul>
                              {anxietyModel !== undefined &&
                              anxietyModel.length !== 0 ? (
                                <div>
                                  {anxietyModel.your_physiological.map(
                                    (item, index) => {
                                      const card = (
                                        <li
                                          className="comic-font text-bold"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      );
                                      return card;
                                    }
                                  )}
                                </div>
                              ) : (
                                <div className="loader-icon">
                                  <TailSpin
                                    color="#4f5962"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FooterOfPrint />
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout3;
