const items1 = [
  {
    title: "How to use iBroadway",
    url: "/intro/how_to",
    index: 2,
  },
  {
    title: "UTBAS Questionnaire",
    url: "/intro/utbas_questionnaire",
    index: 3,
  },
  {
    title: "Avoidance Questionnaire",
    url: "/intro/avoidance_questionnaire",
    index: 4,
    subTitle: {
      url: "/intro/welcome_user",
      index: 5,
    },
  },

  {
    title: "SAS-A Questionnaire",
    url: "/intro/fne_questionnaire",
    index: 6,
  },
  {
    title: "CES-D Questionnaire",
    url: "/intro/dass_questionnaire",
    index: 7,
  },
];

const items2 = [
  {
    title: "What causes us to feel anxious?",
    url: "/part_1/waiting_for_the_bus",
    index: 9,
  },
  {
    title: "Thought Exercise 1",
    url: "/part_1/thinking_exercise_1",
    index: 10,
    subTitle: {
      url: "/part_1/lets_review",
      index: 11,
    },
    subTitle1: {
      url: "/part_1/thinking_exercise_1_sample_answers",
      index: 12,
    },
  },

  {
    title: "Thought Exercise 2",
    url: "/part_1/thinking_exercise_2",
    index: 13,
    subTitle: {
      url: "/part_1/thinking_exercise_2_sample_answers",
      index: 14,
    },
  },

  {
    title: "Causal Thoughts",
    url: "/part_1/what_is_a_causal_thought",
    index: 15,
    subTitle: {
      url: "/part_1/causal_thoughts",
      index: 16,
    },
  },

  {
    title: "Cognitive Errors",
    url: "/part_1/checklist_of_errors",
    index: 17,
  },
  {
    title: "Thought Exercise 3",
    url: "/part_1/thinking_exercise_3",
    index: 18,
    subTitle: {
      url: "/part_1/thinking_exercise_3_sample_answers",
      index: 19,
    },
  },

  {
    title: "Thought Exercise 4",
    url: "/part_1/thinking_exercise_4",
    index: 20,
    subTitle: {
      url: "/part_1/thinking_exercise_4_sample_answers",
      index: 21,
    },
    subTitle1: {
      url: "/part_1/complete",
      index: 22,
    },
  },
];

const items3 = [
  {
    title: "Challenge 1",
    url: "/part_2/challenge_1",
    index: 24,
    subTitle: {
      url: "/part_2/challenge_1_sample_answers",
      index: 25,
    },
  },

  {
    title: "Challenge 2",
    url: "/part_2/challenge_2",
    index: 26,
    subTitle: {
      url: "/part_2/challenge_2_sample_answers",
      index: 27,
    },
  },

  {
    title: "Challenge 3",
    url: "/part_2/challenge_3",
    index: 28,
    subTitle: {
      url: "/part_2/challenge_3_sample_answers",
      index: 29,
    },
  },

  {
    title: "Challenge 4",
    url: "/part_2/challenge_4",
    index: 30,
    subTitle: {
      url: "/part_2/challenge_4_sample_answers",
      index: 31,
    },
  },

  {
    title: "Challenge 5",
    url: "/part_2/challenge_5",
    index: 32,
    subTitle: {
      url: "/part_2/challenge_5_sample_answers",
      index: 33,
    },
    subTitle1: {
      url: "/part_2/complete",
      index: 33,
    },
  },
];

const items4 = [
  {
    title: "Avoidance and Safety Behaviours",
    url: "/part_3/avoidance_and_safety_behaviours",
    index: 35,
    subTitle: {
      url: "/part_3/another_example_of_safety_behaviours",
      index: 36,
    },
    subTitle1: {
      url: "/part_3/lets_recap",
      index: 37,
    },
  },

  {
    title: "Causal Thoughts and Safety Behaviours",
    url: "/part_3/causal_thoughts_and_safety_behaviours",
    index: 38,
    subTitle: {
      url: "/part_3/more_safety_behaviours",
      index: 39,
    },
  },

    {
    title: "A Model of Social Anxiety",
    url: "/part_3/bens_model",
    index: 40,
    subTitle: {
      url: "/part_3/bens_model_2",
      index: 41,
    },
    subTitle1: {
      url: "/part_3/safety_behaviours",
      index: 42,
    },
  },
  {
    title: "Physiological Anxiety Symptoms",
    url: "/part_3/physiological_anxiety_symptoms",
    index: 43,
  },
  {
    title: "Self-Focused Imagery",
    url: "/part_3/self-focussed_imagery",
    index: 44,
  },
  {
    title: "Your Model of Social Anxiety",
    url: "/part_3/your_model",
    index: 45,
    subTitle: {
      url: "/part_3/your_model_of_social_anxiety",
      index: 46,
    },

    subTitle1: {
      url: "/part_3/complete",
      index: 46,
    },
  },
];

const items5 = [
  {
    title: "Social Perfectionism",
    url: "/part_4/perfectionism",
    index: 48,
  },
  {
    title: "Approval From Others",
    url: "/part_4/why_it_really_doesnt_matter",
    index: 49,
  },
  {
    title: "Planning a Behavioural Experiment",
    url: "/part_4/time_to_plan",
    index: 50,
  },
  {
    title: "Predictions",
    url: "/part_4/predictions",
    index: 51,
    subTitle: {
      url: "/part_4/prediction_probabilities",
      index: 52,
    },
  },

  {
    title: "Experiment",
    url: "/part_4/the_experiment",
    index: 53,
    subTitle: {
      url: "/part_4/experiment_completed",
      index: 54,
    },
  },

  {
    title: "Negative Evaluations",
    url: "/part_4/perceived_negative_evaluations",
    index: 55,
  },
  {
    title: "Behavioural Causal Thought",
    url: "/part_4/behavioural_causal_thought",
    index: 56,
    subTitle: {
      url: "/part_4/behavioural_causal_thought_sample_answers",
      index: 57,
    },
    subTitle1: {
      url: "/part_4/what_was_learned",
      index: 58,
    },
    subTitle2: {
      url: "/part_4/avoid",
      index: 59,
    },
    subTitle3: {
      url: "/part_4/continue",
      index: 59,
    },
    subTitle4: {
      url: "/part_4/complete",
      index: 59,
    },
  },
];

const items6 = [
  {
    title: "Should Statements",
    url: "/part_5/should_statements",
    index: 61,
  },
  {
    title: "Tyranny of Shoulds",
    url: "/part_5/tyranny_of_the_shoulds",
    index: 62,
  },
  {
    title: "What do my SHOULDs do for me?",
    url: "/part_5/what_do_my_shoulds_do_for_me",
    index: 63,
  },
  {
    title: "Advantages and Disadvantages",
    url: "/part_5/advantages_and_disadvantages_of_shoulds",
    index: 64,
    subTitle: {
      url: "/part_5/should_sample_answers",
      index: 65,
    },
    subTitle1: {
      url: "/part_5/complete",
      index: 65,
    },
  },
];

const items7 = [
  {
    index: 67,
    url: "/part_6/child",
  },
  {
    title: "Attention Training",
    url: "/part_6/att",
    index: 68,
  },
  {
    title: "Situational Attentional Refocusing",
    url: "/part_6/sar",
    index: 69,
    subTitle: {
      url: "/part_6/sar_responses",
      index: 70,
    },
  },

  {
    title: "Mental Imagery",
    url: "/part_6/mental_imagery",
    index: 71,
  },
  {
    title: "Observer bias",
    url: "/part_6/more_observer_bias",
    index: 72,
    subTitle: {
      url: "/part_6/complete",
      index: 72,
    },
  },
];

const items8 = [
  {
    index: 74,
    url: "/part_7/child",
  },
  {
    title: "Post-Event Rumination",
    url: "/part_7/postevent_rumination",
    index: 75,
  },
  {
    title: "Ways to Protect Yourself",
    url: "/part_7/ways_to_protect_yourself",
    index: 76,
  },
  {
    title: "A Final Thought",
    url: "/part_7/a_final_thought",
    index: 77,
  },
  {
    title: "Printable Handouts",
    url: "/part_7/handouts",
    index: 78,
    subTitle: {
      url: "/part_7/complete1",
      index: 78,
    },
  },
];

const items9 = [
  {
    title: "Avoidance Questionnaire",
    url: "/part_7/avoidance_questionnaire",
    index: 80,
  },
  {
    title: "CES-D Questionnaire",
    url: "/part_7/dass_questionnaire",
    index: 81,
  },
  {
    title: "SAS-A Questionnaire",
    url: "/part_7/fne_questionnaire",
    index: 82,
  },
  {
    title: "Free eBook",
    url: "/part_7/complete",
    index: 83,
  },
];

export {
  items1,
  items2,
  items3,
  items4,
  items5,
  items6,
  items7,
  items8,
  items9,
};
