import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { logout } from "../../../../actions/auth";
import { BASE_URL, IMAGE_URL } from "../../../../env/Baseurl";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Table from "./Table";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";

const UsersInfoList = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(true);
  const [row_index, setRowIndex] = useState(0);
  const [user_delete_id, setUserDeleteId] = useState(0);
  const [roleId, setRoleId] = useState("user");
  const [status, setStatus] = useState("");

  // for loader
  const [loader, setLoader] = useState(false);

  // localStorage
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const setRole = (newRole) => {
    setRoleId(newRole);
  };

  const refreshData = () => {
    setUpdatedData(!updatedData);
  };

  // for modal
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  //

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.setItem("userState", "false");
    dispatch(logout());

    if (localStorage.getItem("userState") === "false") {
      navigate("/");
    }
  };

  const CheckboxhandleChange = (e) => {
    const { name, value } = e.target;

    if (state.some((item) => item._id === name)) {
      setState(state.filter((state) => state._id !== name));
    } else {
      setState([...state, { _id: name }]);
    }
  };

  const deleteUser = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: [
          {
            _id: user_delete_id,
          },
        ],
      });
      const response = await axios.post(
        BASE_URL + `delete_user_by_id/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      const dataCopy = [...data];
      // It should not matter what you name tableProps. It made the most sense to me.
      dataCopy.splice(row_index, 1);
      setData(dataCopy);
      setShow(false);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        deleteUser();
      } else {
        setErrorMessage("Please delete user Api");
        ErrorLogging("HTTP POST /delete_user_by_id/userId" + error);
      }
    }
  };

  const confirmDeleteUser = (deleteUserId) => {
    deleteUser(deleteUserId);
    setShow(false);
  };

  /* 
    - Columns is a simple array right now, but it will contain some logic later on. It is recommended by react-table to memoize the columns data
    - Here in this example, we have grouped our columns into two headers. react-table is flexible enough to create grouped table headers
  */
  const columns = useMemo(
    () => [
      {
        // first group
        // Header: "Users / Admins",
        Header: " ",

        // First group columns
        columns: [
          {
            Header: "Username",
            accessor: "username",
          },
          {
            Header: "Email",
            accessor: "Email",
          },
          {
            Header: "Starting Date",
            accessor: "starting_date",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "User Status",
            accessor: "user_status",
          },
          {
            id: "hidden",
            Header: "hidden",
            accessor: "mix_fields",
            show: false,
          },
        ],
      },
    ],
    [data]
  );

  // for user list

  const fetchUsersList = async () => {
    try {
      const params = JSON.stringify({
        role: roleId,
      });

      setLoader(true);
      const userInfo = localStorage.getItem("user");
      const user = JSON.parse(userInfo);

      const result = await axios.post(
        BASE_URL + `get_user_list/${user.userInfo._id}`,
        params,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setLoader(false);

      setData(result.data.userinfo ? result.data.userinfo : []);

      if (result.data.status === false) {
        localStorage.setItem("userState", "false");
        dispatch(logout());

        if (localStorage.getItem("userState") === "false") {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        fetchUsersList();
      } else {
        setErrorMessage("There is some error, please try again later.");
        ErrorLogging("HTTP GET /get_user_list" + error);
      }
    }
  };

  useEffect(() => {
    fetchUsersList();
  }, [roleId, updatedData]);

  return (
    <div>
      <>
        <Modal
          className="modelNew"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete the User.</Modal.Body>
          <Modal.Footer>
            {show === true ? (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={deleteUser}>
                  Confirm
                </Button>
              </>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Modal>
      </>
      <div className="adminPanledash">
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <div className="user-panel mt-2 pb-3 d-flex web-logo">
            <div className="info panelLogo-container">
              {/* <img src={`${IMAGE_URL}images/sidebar/top_sidebar.png`} alt="logo"></img> */}
              <img
                src={`${IMAGE_URL}images/sidebar/Rectangle.jpg`}
                alt="logo"
              ></img>
            </div>
            <p className="panel-logo-name">IBroadway</p>
          </div>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fa fa-expand"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="control-sidebar"
                data-slide="true"
                role="button"
                onClick={handleShow1}
              >
                <i className="fa fa-power-off"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="user-table">
        <Table
          columns={columns}
          data={data}
          refreshData={refreshData}
          setRole={setRole}
          loader={loader}
        />
      </div>
      <Modal show={show1}>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            No
          </Button>
          <Button variant="primary" onClick={logOut}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersInfoList;
