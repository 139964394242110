import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { DOMAIN_NAME, GifDomain_Name, IMAGE_URL } from "../../../../env/Baseurl";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import GifPlayer from "react-gif-player";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import LastModuleBadge from "../../../../components/global/LastModulebadge";
import Sidebar from "../../../global/Sidebar";
import axios from "axios";
import celebration from "../../../../assets/GIf/Celebration.gif";
import celebration1 from "../../../../assets/GIf/Badge_1.gif";
import getBadgeInfo from "../../../../actions/badgeInfo";
import { handleModuleComplete } from "../../../../services/helpers";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PartComplete = () => {
  /*******************States************************ */
  const [updateModule, setUpdateModule] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [gifStillImage1, setGifStillImage1] = useState("");
  const [gifImage1, setGifImage1] = useState("");
  const [gifStillImage2, setGifStillImage2] = useState("");
  const [gifImage2, setGifImage2] = useState("");
  /*********************hooks & localStorage*************** */
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);

  /******* Page Params *******/

  const title = "Module 1 Completed";
  const badgeContent = (
    <div>
      {" "}
      <p className="greetingMessage1">You are off to a really</p>
      <p className="greetingMessage2"> Great Start!!!!</p>
      <p className="greetingMessage1">
        {" "}
        Keep Going to claim the
        <span className="greetingMessage3"> Ultimate Reward </span>at the end of
        the Program{" "}
      </p>
    </div>
  );

  const content =
    "<div>Well done! You have finished the first component of the Online iGlebe Program. Now that you have completed these exercises you have hopefully come to understand the power that thoughts have over feelings. Now it's time to focus on the thoughts that trouble you. We are going to look closely at the negative thoughts that you selected in the opening questionnaire of this computer program.</div>";

  /******* Helper Functions *******/

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_2/challenge_example", { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/thinking_exercise_4_sample_answers`, { replace: true });
  };

  /********************************** module complete status *******************/

  const handleModule = async () => {
    const dataObject = {
      user_id: user?.userInfo?._id,
      module_name: "part1",
    };
    let response = await handleModuleComplete(dataObject);
    // console.log(response?.userInfo?.total_points, 'its api response')
    const updatePoins = {
      ...user,
      userInfo: {
        ...user.userInfo,
        total_points: response?.userInfo?.total_points,
      },
    };
    localStorage.setItem("user", JSON.stringify(updatePoins));
    // console.log(updatePoins, "new points")
    setUpdateModule(true);
  };

  /***********************UseEffect************************** */

  useEffect(() => {
    handleModule();
    setGifStillImage1(
      {
        superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/despairStill.png`,
        superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/despairStill.png`,
        superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/despairStill.png`,
        superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/despairStill.png`,
        superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/despairStill.png`,
        superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/despairStill.png`,
      }[user?.userInfo?.superherosect]
    );
    setGifStillImage2(
      {
        superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/triumphStill.png`,
        superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/triumphStill.png`,
        superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/triumphStill.png`,
        superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/triumphStill.png`,
        superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/triumphStill.png`,
        superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/triumphStill.png`,
      }[user?.userInfo?.superherosect]
    );
  }, []);

  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => { })
      .catch(() => { });
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  const handleGifImages = (gifUrl) => {
    if (
      {
        superStrong: `${GifDomain_Name}/superHeroVisualGifs1/superStrong/despair.gif`,
        superResilient: `${GifDomain_Name}/superHeroVisualGifs1/superResilient/despair.gif`,
        superCalm: `${GifDomain_Name}/superHeroVisualGifs1/superCalm/despair.gif`,
        superBrave: `${GifDomain_Name}/superHeroVisualGifs1/superBrave/despair.gif`,
        superFearless: `${GifDomain_Name}/superHeroVisualGifs1/superFearless/despair.gif`,
        superSmart: `${GifDomain_Name}/superHeroVisualGifs1/superSmart/despair.gif`,
      }[user?.userInfo?.superherosect].includes(gifUrl)
    ) {
      setGifStillImage1(
        {
          superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/despairStill.png`,
          superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/despairStill.png`,
          superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/despairStill.png`,
          superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/despairStill.png`,
          superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/despairStill.png`,
          superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/despairStill.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage1("");
    } else {
      setGifStillImage2(
        {
          superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/triumphStill.png`,
          superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/triumphStill.png`,
          superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/triumphStill.png`,
          superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/triumphStill.png`,
          superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/triumphStill.png`,
          superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/triumphStill.png`,
        }[user?.userInfo?.superherosect]
      );
      setGifImage2("");
    }
  };
  const handleImages = (imageUrl, index) => {
    if (
      {
        superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/despairStill.png`,
        superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/despairStill.png`,
        superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/despairStill.png`,
        superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/despairStill.png`,
        superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/despairStill.png`,
        superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/despairStill.png`,
      }[user?.userInfo?.superherosect].includes(imageUrl)
    ) {
      setGifImage1(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs1/superStrong/despair.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs1/superResilient/despair.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs1/superCalm/despair.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs1/superBrave/despair.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs1/superFearless/despair.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs1/superSmart/despair.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage1("");
      setGifImage2("");
      setGifStillImage2(
        {
          superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/triumphStill.png`,
          superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/triumphStill.png`,
          superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/triumphStill.png`,
          superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/triumphStill.png`,
          superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/triumphStill.png`,
          superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/triumphStill.png`,
        }[user?.userInfo?.superherosect]
      );
    } else {
      setGifImage2(
        {
          superStrong: `${GifDomain_Name}/superHeroVisualGifs1/superStrong/triumph.gif`,
          superResilient: `${GifDomain_Name}/superHeroVisualGifs1/superResilient/triumph.gif`,
          superCalm: `${GifDomain_Name}/superHeroVisualGifs1/superCalm/triumph.gif`,
          superBrave: `${GifDomain_Name}/superHeroVisualGifs1/superBrave/triumph.gif`,
          superFearless: `${GifDomain_Name}/superHeroVisualGifs1/superFearless/triumph.gif`,
          superSmart: `${GifDomain_Name}/superHeroVisualGifs1/superSmart/triumph.gif`,
        }[user?.userInfo?.superherosect]
      );
      setGifStillImage2("");
      setGifImage1("");
      setGifStillImage1(
        {
          superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/despairStill.png`,
          superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/despairStill.png`,
          superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/despairStill.png`,
          superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/despairStill.png`,
          superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/despairStill.png`,
          superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/despairStill.png`,
        }[user?.userInfo?.superherosect]
      );
    }
  };
  /******* Render *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Part 1 Complete! | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2 position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div
                    className="title page-title-style"
                    style={{ marginBottom: "0px" }}
                  >
                    {title}
                  </div>
                  <div className="card-body p-0 card-body-main-container">
                    <div className="main-visual-container main-visual-container-1">
                      <div className="visuals-container">
                        <img
                          src={celebration}
                          alt="backgroud-visuals"
                          className="part-complete-image"
                        ></img>
                        <div className="textOver-graphics">
                          <p className="text1">YOU DID IT!!!</p>
                          <p className="text2">MODULE 1 COMPLETE</p>
                        </div>
                        <div className="visual-container-footer">
                          <p className="visual-container-footer-lower-text">
                            Here’s how your Superhero{" "}
                            <span className="normal-text-black-complete">
                              {" "}
                              Super{user?.userInfo?.superherosect.slice(5)}
                            </span>{" "}
                            Triumphed over their problems and emerged victorious
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="outerMost-visual-container">
                      <div className="main-visual-container main-visual-container-2">
                        {/* <div className='d-flex justify-content-center' > */}
                        {gifImage1 && (
                          <img
                            src={gifImage1}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs1/superStrong/despair.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs1/superResilient/despair.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs1/superCalm/despair.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs1/superBrave/despair.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs1/superFearless/despair.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs1/superSmart/despair.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            className="visual-container-superhero"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {gifStillImage1 && (
                          <>
                            <img
                              src={gifStillImage1}
                              className="visual-container-superhero"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/despairStill.png`,
                                    superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/despairStill.png`,
                                    superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/despairStill.png`,
                                    superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/despairStill.png`,
                                    superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/despairStill.png`,
                                    superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/despairStill.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="main-visual-container main-visual-container-3">
                        {gifImage2 && (
                          <img
                            src={gifImage2}
                            onClick={() =>
                              handleGifImages(
                                {
                                  superStrong: `${GifDomain_Name}/superHeroVisualGifs1/superStrong/triumph.gif`,
                                  superResilient: `${GifDomain_Name}/superHeroVisualGifs1/superResilient/triumph.gif`,
                                  superCalm: `${GifDomain_Name}/superHeroVisualGifs1/superCalm/triumph.gif`,
                                  superBrave: `${GifDomain_Name}/superHeroVisualGifs1/superBrave/triumph.gif`,
                                  superFearless: `${GifDomain_Name}/superHeroVisualGifs1/superFearless/triumph.gif`,
                                  superSmart: `${GifDomain_Name}/superHeroVisualGifs1/superSmart/triumph.gif`,
                                }[user?.userInfo?.superherosect]
                              )
                            }
                            className="visual-container-superhero"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {gifStillImage2 && (
                          <>
                            <img
                              src={gifStillImage2}
                              className="visual-container-superhero"
                              onClick={() =>
                                handleImages(
                                  {
                                    superStrong: `${IMAGE_URL}images/part1/superHeroVisualGifs/superStrong/triumphStill.png`,
                                    superResilient: `${IMAGE_URL}images/part1/superHeroVisualGifs/superResilient/triumphStill.png`,
                                    superCalm: `${IMAGE_URL}images/part1/superHeroVisualGifs/superCalm/triumphStill.png`,
                                    superBrave: `${IMAGE_URL}images/part1/superHeroVisualGifs/superBrave/triumphStill.png`,
                                    superFearless: `${IMAGE_URL}images/part1/superHeroVisualGifs/superFearless/triumphStill.png`,
                                    superSmart: `${IMAGE_URL}images/part1/superHeroVisualGifs/superSmart/triumphStill.png`,
                                  }[user?.userInfo?.superherosect]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="main-comicContainer">
                      <LastModuleBadge
                        currentBadge={`${IMAGE_URL}images/Badges/badge1.png`}
                        nextBadge={`${IMAGE_URL}images/Badges/badge2.png`}
                        content={badgeContent}
                        progressBarData={12.5}
                        trophy={`${IMAGE_URL}images/badge_gif/Badge_1.gif`}
                        totalPoints={user?.userInfo?.total_points}
                      />
                    </div>
                    <div className="congratulation-box">
                      <div className="">
                        <p className="page-title-style">END MODULE 1</p>
                        <p>
                          Well done{" "}
                          <span className="normal-text-black">
                            {user.userInfo.Fname + " " + user.userInfo.Lname}
                          </span>{" "}
                          ! Your neural pathways for confidence will be lovin’
                          it.
                        </p>
                        <p>
                          Here’s some more useless stuff. Before we go to Module
                          2, check out this useless fact below:
                        </p>
                        <p className="component-sub-heading-style">
                          Two-way mirrors
                        </p>
                        <p className="">
                          Have you ever wondered how to tell the difference
                          between a real mirror and a two-way mirror? Place the
                          tip of your fingernail against the reflective surface
                          and if there is a GAP between your fingernail and the
                          image of the nail, then it is a GENUINE mirror.
                          However, if your fingernail DIRECTLY TOUCHES the image
                          of your nail, then it’s a two-way mirror. Have fun
                          trying this for yourself.
                        </p>
                        <div className="main-container mt-5">
                          <div className="container">
                            <div className="image-container plastic-brain">
                              <img
                                src={`${IMAGE_URL}images/part1/complete/98.jpg`}
                                alt="mirror1"
                              />
                            </div>
                          </div>
                          <div className="container">
                            <div className="image-container plastic-brain">
                              <img
                                src={`${IMAGE_URL}images/part1/complete/99.jpg`}
                                alt="mirror2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous col-5 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-7 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartComplete;
