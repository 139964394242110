import React from "react";
import loginImage1 from "../../assets/GIf/Untitled-3.png";

import { DOMAIN_NAME } from "../../env/Baseurl";

const UserAccountInfo = ({
  handleChange,
  emailChecker,
  emptyCheck,
  values,
}) => {
  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row m-0 align-items-center login-col-wrap justify-content-center">
        {/* <div className="col-md-6">
          <div className="text-center">
            <img
              height="auto"
              width="400px"
              src={loginImage1}
              alt="Login Group"
            />
          </div>
        </div> */}
        {/* <div className="col-12 col-md-8 col-lg-6"> */}
        <div className="col-md-12">
          <div>
            <h3 className="text-center pagehead-texttitle">
              Create New Account
            </h3>
            <form>
              <div className="card-body">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail10"
                    className="input-label-style"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    defaultValue={values.user_email}
                    placeholder="Enter a Valid Email Address"
                    onChange={handleChange("user_email")}
                    onKeyUp={() => emailChecker()}
                  />
                  <small className="text-danger input-label-style error-message-style">
                    {emptyCheck.user_email}
                  </small>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    defaultValue={values.user_name}
                    placeholder="Enter a Username (Special Characters are allowed)"
                    onChange={handleChange("user_name")}
                  />
                  {values.user_name === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.user_name}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccountInfo;
