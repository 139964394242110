import { AUDIO_SUCCESS } from "../actions/types";
import { BASE_URL } from "../env/Baseurl";
import axios from "axios";

/* function to close motivator slider */
const closeMotivatorsSlider = (elementId, classToAdd) => {
  var mySays = document.getElementById(elementId);
  console.log(mySays);
  mySays.classList.add(classToAdd);
  return true;
};

/* function to update the complete module points */
const handleModuleComplete = async (params) => {
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const paramsObject = JSON.stringify(params);
  try {
    const response = await axios.post(BASE_URL + `getpoint`, paramsObject, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        "content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error, "its error while updateing the module complete info");
  }
};

/* function to handle or update all point*/
const handlePoints = async () => {
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  return axios
    .get(BASE_URL + `getuserbyid/?user_id=${user.userInfo._id}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      console.log(response.data, "its data badge data");
      // return response.data;
      const myObject = {
        ...user,
        userInfo: {
          ...user.userInfo,
          total_points: response?.data?.userInfo?.total_points,
        },
      };
      localStorage.setItem("user", JSON.stringify(myObject));
    })
    .catch((error) => {
      console.log(error, "Failed to get Users point");
    });
};

/***************handleContinueButton********/

const checkForContinueButton = (currentAudio, audio) => {
  // let currentIndex = await currentIndex?.index;
  // if (currentIndex > currentPageIndex) {
  //   setButtonState(false);
  // } else {
  let audios = audio?.audioData?.length;
  if (audios > 0) {
    if (currentAudio?.audio === audios - 1) {
      return false;
    }
  } else if (audios === 0) {
    return false;
  } else {
    return true;
  }
  // console.log("hello");
  // }
};

export {
  closeMotivatorsSlider,
  handleModuleComplete,
  handlePoints,
  checkForContinueButton,
};
