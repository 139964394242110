import React from 'react';
import { Helmet } from 'react-helmet';
import ContentPage from '../templates/ContentPages';

const Hello = () => {

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    /*******   Page Params  *******/

    const title = `Hello ${user.userInfo.Fname}`;

    const content = "<div><p>Welcome to the first part of your social anxiety treatment program. iBroadway was developed by Professor Ross Menzies and Dr Anthony Gunn. This program is based on the standalone internet  Cognitive Behavioural Therapy (CBT) program for adults who stutter, iGlebe, originally developed by Professor Ross Menzies <a href='http://www.rossmenzies.com.au/' target='_blank' rel='noreferrer'>http://www.rossmenzies.com.au/</a> and Clinical Psychologist, Fjola Helgadottir  <a href='https://www.ai-therapy.com/' target='_blank' rel='noreferrer'>https://www.ai-therapy.com/</a>.</p></div> <div><p>The next part of the program focuses on your thinking, that is, how your thoughts control the way you feel and behave. This is what Cognitive Behaviour Therapy (CBT) is all about. This page is the first one that has sound clips, so turn your volume up if you haven't already heard us talking. However, we'll need you to answer two more questionnaires before you begin the treatment. These are important in assessing your psychological functioning.</p></div> <div><p>Please set aside another 15 minutes to work through these questionnaires. </p></div>";

    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Hello | ASRC iBroadway</title>
            </Helmet>
            <ContentPage
                title={title}
                content={content}
                link='intro/fne_questionnaire'
                previousLink='intro/avoidance_questionnaire'
            />
        </div>
    );
};

export default Hello;