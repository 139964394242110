import React from "react";
import { Helmet } from "react-helmet";
import InputForms from "../templates/InputForms/InputForms";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";

const ThinkingExercise3 = () => {
  const [inputQuestions, errorMessage] = GetInputQuestions(3); // Input Questions list from API

  /*******   Page Params  *******/
  const title = "'Thought' Exercise 3";

  // const content =
  //   "Please read the following example and write down the THOUGHTS you think Jackson would have had in the situation described below:";

  const situation =
    "<p class='normal-text-black'>Jackson had invited Yasmin to go to the movies with him. They had arranged to meet outside the movies at 6:00pm on Saturday night. Jackson arrives on time, but is left waiting for 40 minutes, and Yasmin never arrived or called, and her phone rang out when Jackson tried to call her.</p><p class='normal-text-black mb-0'> Write down the causal thoughts that would make Jackson feel:<p class='normal-text-black mb-0'><span class=''>NOTE</span> - Try and label the type of ‘thinking error’ in your answers where possible</p> ";

  const images = [
    'exercise3/87.jpg',
    'exercise3/89.jpg',
    'exercise3/88.jpg',
    'exercise3/90.jpg',
  ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>'Thought' Exercise 3 | ASRC iBroadway</title>
      </Helmet>
      <InputForms
        title={title}
        content=""
        questions={inputQuestions}
        link="part_1/thinking_exercise_3_sample_answers"
        type={"create_Excercise3"}
        images={images}
        situation={situation}
        sitnum={3}
        error={errorMessage}
        previousLink="part_1/checklist_of_errors"
      />
    </div>
  );
};

export default ThinkingExercise3;
