import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeAnswers from '../templates/Answers/ChallengeAnswers';
import { GetChallengeResponse } from '../../../../services/part2/get-challenge-response';


const ChallengeResponse3 = () => {

    const [challengeResponse, errorMessage] = GetChallengeResponse('3');         // User Response from API

    /*******   Page Params  *******/

    // const title = "Challenge 3 - Your Answers";
    const title = "Challenge My Thinking : Exercise 3 - Review";


    const content = "lorem ipsum";

    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge My Thinking: Exercise 3 - Review | ASRC iBroadway</title>
            </Helmet>
            <ChallengeAnswers
                title={title}
                content={content}
                responses={challengeResponse}
                link='part_2/challenge_4'
                error={errorMessage}
                previousLink='part_2/challenge_3'
                thoughtNumber="third"
            />
        </div>
    );
};

export default ChallengeResponse3;