import { GetPostTreatmentQuestionnaire } from "../../../../services/post-treatment/get-post-treatment-questionnaire";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import React from "react";
import { options } from "../../../../constants/pretreatment/UtbasQuestionnaire";

const PostUtbasQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("UTBAS"); // Get Questionnaire from API
  const moduleName = localStorage.setItem("moduleName", "Post-Treatment");
  const part1Accessed = localStorage.setItem("postTreatmentAccess", "true");
  /*******  Page Params  *******/

  const title = "Unhelpful Thoughts and Beliefs Questionnaire";

  const content =
    "<p class='mb-0'>To complete the program, please follow the instructions below.</p><p class='mb-0'>Below we have compiled a list of thoughts, beliefs and attitudes about stuttering that you may experience. Please indicate how frequently you have these thoughts.</p>";

  /*******  Render  *******/

  return (
    <div>
      <Helmet>
        <title>
          Unhelpful Thoughts and Beliefs Questionnaire | ASRC iBroadway
        </title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="part_7/avoidance_questionnaire"
        questions={questionnaire}
        options={options}
        type={"utbas"}
        error={errorMessage}
        // previousLink='part_7/handouts'
        previousLink="part_7/complete1"
      />
    </div>
  );
};

export default PostUtbasQuestionnaire;
