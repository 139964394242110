import React from "react";
import { Helmet } from "react-helmet";
import ChallengeExercise from "../templates/InputForms/ChallengeExercise";
import { GetChallengeQuestions } from "../../../../services/part2/get-challenge-forms";

const Challenge5 = () => {
  const [challengeQuestions, errorMessage] =
    GetChallengeQuestions("challange_show5"); // Input Questions list from API

  /*******   Page Params  *******/

  const title = "Challenge 5";

  const content = "Four done! Only one to go!";

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Challenge 5 | ASRC iBroadway</title>
      </Helmet>
      <ChallengeExercise
        title={title}
        content={content}
        questions={challengeQuestions}
        type={"create_chall5"}
        link="part_2/challenge_5_sample_answers"
        subtitle={"Four done! Only one to go!"}
        error={errorMessage}
        previousLink="part_2/challenge_4_sample_answers"
        thoughNumber="fifth"
      />
    </div>
  );
};

export default Challenge5;
