// const selectOptions1 = [
//     { value: '<span className="redText">Frustrated.</span> No, Karen is not frustrated, try another option.', label: 'Frustrated' },
//     { value: '<span className="redText">Sad.</span> No, Karen is not sad, try another option', label: 'Sad' },
//     { value: '<span className="redText">Angry.</span> No, Karen is not angry, try another option', label: 'Angry' },
//     { value: '<span className="redText">Happy.</span> Yes, you are right! She is happy as a result of her CAUSAL thoughts!', label: 'Happy' },
//   ];

const selectOptions1 = [
  { value: '<span className="redText">Anger.</span> No, Chad is not anger, try another option.', label: 'Anger' },
  { value: '<span className="redText">Anxious.</span> No, Chad is not anxious, try another option.', label: 'Anxious' },
  { value: '<span className="redText">Sad.</span> No, Chad is not sad, try another option.', label: 'Sad' },
  { value: '<span className="redText">Happy.</span> Yes, you are right! He is happy as a result of her CAUSAL thoughts!', label: 'Happy' },
];

// const selectOptions2 = [
//     { value: '<span className="redText">Frustrated.</span> Yes, you are right! Ben is frustrated as a result of his CAUSAL thoughts!', label: 'Frustrated' },
//     { value: '<span className="redText">Sad.</span> No, Ben is not sad, try another option', label: 'Sad' },
//     { value: '<span className="redText">Angry.</span> No, Ben is not angry, try another option', label: 'Angry' },
//     { value: '<span className="redText">Happy.</span> No, Ben is not happy, try another option', label: 'Happy' },
//   ];  

const selectOptions2 = [
  { value: '<span className="redText">Anger.</span> Yes, you are right! Karl is anger as a result of his CAUSAL thoughts!', label: 'Anger' },
  { value: '<span className="redText">Anxious.</span> No, Karl is not anxious, try another option.', label: 'Anxious' },
  { value: '<span className="redText">Sad.</span> No, Karl is not sad, try another option.', label: 'Sad' },
  { value: '<span className="redText">Happy.</span> No, Karl is not happy, try another option.', label: 'Happy' },
];


export {
  selectOptions1,
  selectOptions2
};