import React from "react";
import { Helmet } from "react-helmet";
import InputForms from "../templates/InputForms/InputForms";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";

const ThinkingExercise4 = () => {
  const [inputQuestions, errorMessage] = GetInputQuestions(4); // Input Questions list from API

  /*******   Page Params  *******/

  // const title = "'Thinking' Exercise 4";
  const title = "'Thought' Exercise 4";

  // const content = "<div>Please read the following example and write down the THOUGHTS you think Zoe would have had in the situation described below:</div>";
  const content =
    "You’re doing well. Stay with the exercises as you’re making new neural pathways in your brain to increase your social confidence.";

  const situation =
    "<p class='normal-text-black'>Zoe has experienced a mild stutter for many years. She was at the supermarket looking for pop-corn for a movie night with her friends. She tried to find the correct aisle, but couldn’t locate it. Zoe approached a supermarket employee, started smiling and prepared herself to ask where the pop-corn was. But as she walked up to the employee, he turned towards Zoe and started laughing.</p><p class='normal-text-black mb-0'>Write down the CAUSAL thoughts that would make Zoe feel:</p>";

  const images = [
    'exercise4/93.jpg',
    'exercise4/94.jpg',
    'exercise4/95.jpg',
    'exercise4/96.jpg',
  ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>'Thought' Exercise 4 | ASRC iBroadway</title>
      </Helmet>
      <InputForms
        title={title}
        content={content}
        questions={inputQuestions}
        link="part_1/thinking_exercise_4_sample_answers"
        type={"create_Excercise4"}
        images={images}
        situation={situation}
        sitnum={4}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_3_sample_answers"
      />
    </div>
  );
};

export default ThinkingExercise4;
