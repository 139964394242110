import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/dashboard-style.css";
import Header from "./Header";
import { color } from "@mui/system";
import StaticFooter from "./StaticFooter";
const PrivacyPolicy = () => {
  return (
    <div className="dashboardContent error-template">
      <Helmet>
        <title>Privacy Policy | ASRC iGlebe</title>
      </Helmet>
      <div>
        <Header />
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div class="col-lg-6 region region-content1">
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1" id="heading">
                Privacy Notice Internet treatment programs
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  When you access this site, information about you will be
                  collected by the University of Technology, Sydney (UTS). The
                  information collected is used to design an individualised
                  treatment for you and to provide research data for further
                  development and enhancement of the program. The following will
                  describe the type of information collected and how that
                  information will be used. This statement provides information
                  about the rights and responsibilities of UTS and your rights
                  relating to the collection of information about you.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Collection, use and storage of information</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  When you access this site and when you commence treatment,
                  information about you will be collected. This information
                  consists of responses that you provide to questions within the
                  site. The information you provide will be used by the program
                  to design an individual treatment to you.
                </p>
                <p>
                  Other information collected includes information about your
                  activity and engagement with the site. This information is
                  collected automatically and includes the dates and times when
                  you interact with the program, the pages you access and the
                  documents you download.
                </p>

                <p>
                  In addition to using the information collected for developing
                  a treatment program for you, the information collected about
                  you as described above may be used for quality improvement and
                  planning. The results of any such analysis of your information
                  may be presented in written reports to governing bodies of
                  UTS, grant administering institutions or scientific journals.
                  The information used for such functions will be de-identified.
                  That means no identifying information about you will be
                  accessed, used or reported for this purpose.
                </p>

                <p>
                  The information about you will be stored within secure
                  electronic systems in accordance with{" "}
                  <a
                    className="breakWord"
                    href="https://www.uts.edu.au/about/uts-governance/policies"
                  >
                    UTS policies
                  </a>{" "}
                  related to information technology management. UTS may use
                  contracted external companies to store information.
                  Information will be stored in Australia.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Disclosure</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  The information UTS collects about you will not be disclosed
                  to any third party unless you provide consent or as required
                  by law.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Access and Amendment of personal information</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  You may request access to inspect and amend information we
                  hold about you. All requests to access or amend personal
                  information must be lodged in writing by emailing:
                  <a href="#" className="breakWord"> asrc.internetprograms@uts.edu.au</a>. Requests
                  will be considered on a case-by-case basis and dealt with
                  within 30 working days.
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Deletion of personal information</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  Personal information will be retained to satisfy our legal
                  retention requirements. You may request your information be
                  deleted. The request will only be actioned once our retention
                  requirements have been satisfied. For all requests to delete
                  personal information held by UTS for the purpose of engaging
                  with this site must be lodged in writing by emailing{" "}
                  <a href="#" className="breakWord"> asrc.internetprograms@uts.edu.au.</a>
                </p>
              </div>
            </div>

            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block"
            >
              <div className="content welcomeContent1">
                <h3 id="para">Complaints</h3>
              </div>
            </div>
            <div
              id="block-satp-user-content"
              className="block block-system block-system-main-block mt-3"
            >
              <div className="content">
                <p>
                  If you believe your privacy has been breached, or if you have
                  concerns regarding the management of your personal
                  information, you can contact us in writing by emailing{" "}
                  <a href="#" className="breakWord">asrc.internetprograms@uts.edu.au.</a> Further
                  information can be found at{" "}
                  <a href="https://www.uts.edu.au/about/uts-governance/privacy-uts" className="breakWord">
                    {" "}
                    Privacy at UTS
                  </a>{" "}
                  and{" "}
                  <a href="https://www.uts.edu.au/about/uts-governance/privacy-uts/your-privacy-uts/privacy-complaints" className="breakWord">
                    {" "}
                    Complaints
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="fixedDiv col-md-12">
            <StaticFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
