import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const GetPredictionSituation =  () => {
    
    const [predictionSituation, setPredictionSituation] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchPredictionSituation = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `Predictions_situation_task/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setPredictionSituation(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchPredictionSituation();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /Predictions_situation_task/userId' + error);
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /Predictions_situation_task/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchPredictionSituation();
    }, []);
  
  return [predictionSituation, errorMessage];
};


const GetPredictionQuestion =  () => {
    
    const [predictionQuestions, setPredictionQuestion] = useState([]);
    const [errorPredictionMessage, setQuestionErrorMessage] = useState('');

    const fetchPredictionSituation = async () => {
        
        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `getall_predictions/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setPredictionQuestion(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchPredictionSituation();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setQuestionErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /getall_predictions/userId' + error);
            }
            else{
                setQuestionErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /getall_predictions/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchPredictionSituation();
    }, []);
  
  return [predictionQuestions, errorPredictionMessage];
};


const GetUserPredictionSituation =  () => {
    
    const [predictionUserSituation, setUserPredictionSituation] = useState([]);
    const [errorUserMessage, setUserErrorMessage] = useState('');

    const fetchUserPredictionSituation = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `getalluser_predictions_userresponce/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setUserPredictionSituation(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchUserPredictionSituation();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setUserErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /getalluser_predictions_userresponce/userId' + error);
            }
            else{
                setUserErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /getalluser_predictions_userresponce/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchUserPredictionSituation();
    }, []);
  
  return [predictionUserSituation, errorUserMessage];
};

export {
    GetPredictionSituation,
    GetPredictionQuestion,
    GetUserPredictionSituation
}