const selectOptions1 = [
  { value: "0 Well done! Yes, this is correct, try next example!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions2 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 Well done! Yes, this is correct, try next example!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option! ", label: 'All-or-nothing thinking' },
  { value: "3 No, that is not the correct cognitive error, try another option! ", label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option! ', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option! ", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];


const selectOptions3 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: "4 No, that is not the correct cognitive error, try another option!", label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: "6 No, that is not the correct cognitive error, try another option! ", label: 'Compliment’ rejector' },
  { value: '7 Well done! Yes, this is correct, try next example!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions4 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: "1 No, that is not the correct cognitive error, try another option!", label: 'Fortune telling' },
  { value: "2 Well done! Yes, this is correct, try next example!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions5 = [
  { value: "0 No, that is not the correct cognitive error, try another option! ", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option! ', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 Well done! Yes, this is correct, try next example!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions6 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 Well done! Yes, this is correct, try next example!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions7 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: "3 Well done! Yes, this is correct, try next example!", label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions8 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 Well done! Yes, this is correct, try next example!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

const selectOptions9 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 Well done! Yes, this is correct, try next example!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: "9 No, that is not the correct cognitive error, try another option!", label: 'Blame game' },
];

const selectOptions10 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 No, that is not the correct cognitive error, try another option!", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: '7 No, that is not the correct cognitive error, try another option!', label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option! ", label: 'Catastrophizing' },
  { value: '9 Well done! Yes, this is correct, try next example!', label: 'Blame game' },
];

const selectOptions11 = [
  { value: "0 No, that is not the correct cognitive error, try another option!", label: 'Mind reading.' },
  { value: '1 No, that is not the correct cognitive error, try another option!', label: 'Fortune telling' },
  { value: "2 Well done! Yes, this is correct.", label: 'All-or-nothing thinking' },
  { value: '3 No, that is not the correct cognitive error, try another option!', label: 'Emotional reasoning' },
  { value: '4 No, that is not the correct cognitive error, try another option!', label: 'SHOULD statements' },
  { value: "5 No, that is not the correct cognitive error, try another option!", label: 'Labelling' },
  { value: '6 No, that is not the correct cognitive error, try another option!', label: 'Compliment’ rejector' },
  { value: "7 No, that is not the correct cognitive error, try another option!", label: 'Pessimistic glasses' },
  { value: "8 No, that is not the correct cognitive error, try another option!", label: 'Catastrophizing' },
  { value: '9 No, that is not the correct cognitive error, try another option!', label: 'Blame game' },
];

export {
  selectOptions1,
  selectOptions2,
  selectOptions3,
  selectOptions4,
  selectOptions5,
  selectOptions6,
  selectOptions7,
  selectOptions8,
  selectOptions9,
  selectOptions10,
  selectOptions11
}; 