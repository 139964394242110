import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetContinueReponse } from "../../../../services/part4/get-continue-reponse";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4FourteenthPage = () => {
  const [continueReponse, errorMessage] = GetContinueReponse();
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   Helper Functions  *******/
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    if (continueReponse.obj.counter_no >= 3) {
      handleAudioStoreData();
      // navigate('/part_5/essay', { replace: true });
      navigate("/part_4/complete", { replace: true });
    } else {
      try {
        const response = await axios.post(
          BASE_URL +
            `page_partUpdate_setto_social_perfection/${user.userInfo._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "content-type": "application/json",
            },
          }
        );
        handleAudioStoreData();
        navigate("/part_4/perfectionism", { replace: true });
      } catch (error) {
        if (error.response.status === 401) {
          RefreshToken(user.userInfo._id);
          nextPage();
        }
      }
    }
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/avoid`, { replace: true });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };
  

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Well done! | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body  p-0 mb-0">
                  <div className="title page-title-style">Well done!</div>
                  <div className="card-body">
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : continueReponse !== undefined &&
                      continueReponse.length !== 0 ? (
                      <div>
                        <p className="">{continueReponse.obj.statement1}</p>
                        <p>{continueReponse.obj.statement2}</p>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4FourteenthPage;
