import React from 'react';
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const downloadMyGraph = async () => {
    let top = 20;
    let padding = 20;
    let doc = new jsPDF();

    let canvas = await document.getElementById("bargraph")
    console.log(canvas, "its canvas")
    // document.getElementById("printFooter").style.display = 'block'
    const imgData = await htmlToImage.toCanvas(canvas);
    // console.log(imgData, "its imdage ddata")
    let elHeight = canvas.offsetHeight;
    // console.log(elHeight, "its height of canvas")
    let elWidth = canvas.offsetWidth;

    const pageWidth = await doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding;
        elWidth = elWidth * ratio - padding;
    }

    const pageHeight = await doc.internal.pageSize.getHeight();
    // console.log(pageHeight, "its page doc heith ")

    if (top + elHeight > pageHeight) {
        // doc.addPage();
        top = 20;
    }

    // const canvasImage = canvas.toDataURL('image/jpeg', 1.0)

    // doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight,);
    doc.addImage(imgData, "PNG", 10, 10, elWidth, elHeight);
    // doc.autoPrint({ variant: 'non-conform' });
    doc.save("myChart.pdf");
}

export default downloadMyGraph;