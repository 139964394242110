import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';

import React, { useState } from 'react';

import { AUDIO_SUCCESS } from '../../../../actions/types';
import { Button } from "react-bootstrap";
import Footer from '../../../global/Footer';
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from '../../../global/Header';
import { Helmet } from 'react-helmet';
import Sidebar from '../../../global/Sidebar';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Part4NinthPage = () => {
    const [isPlaying, setIsPlaying] = useState(true);

    /*******   Helper Functions  *******/
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleAudioStoreData = () => {
        return dispatch({
            type: AUDIO_SUCCESS,
            payload: [],
        });
    }

    const nextPage = () => {
        handleAudioStoreData();
        navigate('/part_4/behavioural_causal_thought', { replace: true })
    };

    const previousPage = () => {
        handleAudioStoreData();
        navigate(`/part_4/experiment_completed`, { replace: true })
    };

      // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  
    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>My worries about others opinions | ASRC iBroadway</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row m-0">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body p-0 mb-0">
                                <div id="main">
                                    <div className="title page-title-style">
                                        My worries about others opinions
                                    </div>
                                    <div className="card-body">

                                        <p className='component-sub-heading-style '>
                                            What happens if I perceived negative evaluations from others while doing the experiment?
                                        </p>
                                        <p>
                                            Only your thoughts can make you feel upset as a result of a social rejection. Remember, causal thoughts CREATE the emotional disturbance following negative evaluation. For example, let’s imagine that you engage in a behavioural experiment and someone actually does laugh at you. This doesn’t mean that you have to feel upset. What’s the real COST here? If an ignorant person, who doesn’t understand stuttering, reacts like a tool, why should you care? Why give this person the POWER over your emotional life?

                                        </p>
                                    </div>
                                    <div>
                                        <FooterOfPrint/>
                                    </div>
                                </div>

                                <div className='row mx-0 bottom-button-container'>
                                    <div className='previous1 col-6 px-0'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty m-0"
                                            onClick={previousPage}

                                        >
                                            Previous
                                        </Button>
                                    </div>
                                    <div className='next col-6 px-0'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty m-0"
                                            onClick={nextPage}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4NinthPage;