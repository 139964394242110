import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/dashboard-style.css";
import Header from "../../components/global/Header";
import { formControlLabelClasses } from "@mui/material";
import { GetWelcomeUser } from "../../services/get-welcome-user";
import { DOMAIN_NAME, IMAGE_URL } from "../../env/Baseurl";
import { myprintFunction } from "../../actions/NormalPrintFunction";

const WelcomePage = () => {
  const [currentUser, errorMessage] = GetWelcomeUser();
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  return (
    <div className="dashboardContent error-template">
      <Helmet>
        <title>Welcome Back | ASRC iBroadway</title>
      </Helmet>
      <>
        <div id="printAble"></div>
        <div id="mainContainer" className="dashboardContent">
          <div>
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div id="main">
            {errorMessage ? (
              <div className="container mt-5">
                <div className="row justify-content-center m-0">
                  <div className="alert alert-danger text-center" role="alert">
                    {errorMessage}
                  </div>
                </div>
              </div>
            ) : currentUser !== undefined && currentUser.length !== 0 ? (
              <div className="row m-0 justify-content-center">
                {/* <div className="col-lg-3"></div> */}
                <div className="col-lg-6 region region-content welcome">
                  <div
                    id="block-satp-user-page-title"
                    className="block block-core block-page-title-block"
                  >
                    <div className="content">
                      <img
                        className="weclomePng"
                        src={`${IMAGE_URL}images/welcome/welcomePng.png`}
                      ></img>
                      <p className="title page-title component-sub-heading-style">
                        Welcome Back{" "}
                        {currentUser.length !== 0 && (
                          <>
                            {user?.userInfo?.Fname +
                              " " +
                              user?.userInfo?.Lname}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    id="block-satp-user-content"
                    className="block block-system block-system-main-block"
                  >
                    <p className="content welcomeContent normal-text">
                      {currentUser.length !== 0 && (
                        <>{currentUser.statement2}</>
                      )}
                    </p>
                  </div>
                  <div
                    id="block-satp-user-content"
                    className="block block-system block-system-main-block mt-3"
                  >
                    <div className="content" style={{ fontSize: "20px" }}>
                      <Link to={currentUser?.last_url}>
                        Please click here to continue the program!
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3"></div> */}
              </div>
            ) : (
              <div className="loader-icon">
                <TailSpin color="#4f5962" height={50} width={50} />
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default WelcomePage;
