import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import React from "react";
import { options } from "../../../../constants/pretreatment/UtbasQuestionnaire";

const UtbasQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetQuestionnaire("UTBAS"); // Questions list from API

  /*******   Page Params  *******/

  const title = "Unhelpful Thoughts and Beliefs Questionnaire";

  const content =
    "<p class='m-0'>The 1st step towards having a program designed uniquely for you is to give the program an idea about how you think. Relax, everyone has his or her own pattern of thinking, and the following questionnaire will help uncover your pattern of thinking about stuttering. Please indicate accurately on a range from 'Never or not at all' to 'Always or totally' how often you have the following thoughts. It is extremely important for your progress that you give honest responses.</p>";
  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>
          Unhelpful Thoughts and Beliefs Questionnaire | ASRC iBroadway
        </title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/avoidance_questionnaire"
        questions={questionnaire}
        options={options}
        type={"UTBAS"}
        error={errorMessage}
        previousLink="intro/how_to"
      />
    </div>
  );
};

export default UtbasQuestionnaire;
