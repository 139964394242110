import "../../../../../styles/adminlte.min.css";
import "../../../../../styles/part1/exerciseAnswer/exerciseAnswer.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../../env/Baseurl";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import Header from "../../../../global/Header";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import idea from "../../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../../assets/tip/Vector.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ExcerciseAnswers = ({
  title,
  content,
  responses,
  link,
  type,
  sitnum,
  error,
  previousLink,
  images,
}) => {
  /*******   States *******/

  const [disabled, setDisabled] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  const situation = ["Anxious", "Angry", "Sad", "Happy"];

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const postSubmission = () => {
    handleAudioStoreData();
    navigate(`/${link}`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/${previousLink}`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div
                    className="title page-title-style mb-0"
                    style={{ marginBottom: "0px" }}
                  >
                    {title}
                  </div>
                  <div className="card-body">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                    {error ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {error}
                          </div>
                        </div>
                      </div>
                    ) : responses !== undefined && responses.length !== 0 ? (
                      <div className="content-wrap">
                        <div className="content-topheads-text">
                          <div className="situation-box">
                            <p className="component-sub-heading-style ">
                              Situation: {sitnum}
                            </p>
                            <p className="normal-text-black mb-0">
                              {responses.Situation}
                            </p>
                          </div>
                          <div className="thinkingEx">
                            <div className="part1-main-container my-5">
                              {responses.Exerciseresults.map((item, index) => {
                                const renderSample =
                                  item.thinkingExcercise_id.sampleAnswear;
                                const card = (
                                  <div
                                    className="image-with-text thought-review"
                                    key={index}
                                  >
                                    <div className="review-content">
                                      <p className="component-heading-style text-center">
                                        {situation[index]}
                                      </p>
                                      <p className="component-sub-heading-style text-center">
                                        Your Answer
                                      </p>
                                      <div className="thought-review-response">
                                        <p className="small-text response">
                                          {item?.userResponce}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="example-box">
                                      <div className="container">
                                        <img
                                          src={`${IMAGE_URL}images/part1/${images[index]}`}
                                          alt="feelings"
                                        />
                                      </div>

                                      <p className="component-sub-heading-style text-center">
                                        Example Answers
                                      </p>
                                      {/* <p className='small-text'> */}
                                      <div
                                        className="small-text"
                                        dangerouslySetInnerHTML={{
                                          __html: renderSample,
                                        }}
                                      ></div>
                                    </div>
                                    {/* </p> */}
                                  </div>
                                );
                                return card;
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                    {sitnum == 1 && (
                      <>
                        <div className="exercise-tip-container">
                          <div className="tip-image-container exercise-answer">
                            <img
                              className="part6_bar_image"
                              src={tipBackground}
                              alt="tip-background"
                              width="923px"
                            />
                          </div>

                          <div className="idea">
                            <img
                              className="part6_bar_image"
                              src={idea}
                              alt="tip"
                            />
                          </div>
                          <div className="tip-content exercise-tip-content">
                            <span className="whiteText">
                              Notice that our answers only include{" "}
                              <span className="yellowText">THOUGHTS.</span>
                            </span>
                          </div>
                          <div className="why-image">
                            <img
                              src={`${IMAGE_URL}images/part1/exercise1/51.jpg`}
                              alt="why"
                            />
                          </div>
                        </div>
                        <div className="main-container my-5">
                          <div
                            className="sub-container center-align"
                            style={{ maxWidth: "unset" }}
                          >
                            <div className="image-container">
                              <img
                                src={`${IMAGE_URL}images/part1/exercise1/50.jpg`}
                                alt="thought"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {sitnum == 2 && (
                      <div className="tip-container">
                        <div className="tip-image-container">
                          <img
                            className="part6_bar_image"
                            src={tipBackground}
                            alt="tip-background"
                            width="923px"
                          />
                        </div>
                        <div className="idea">
                          <img
                            className="part6_bar_image"
                            src={idea}
                            alt="tip"
                          />
                        </div>
                        <div className="tip-content">
                          <span className="whiteText">
                            Remember, new neural pathways{" "}
                            <span className="yellowText">
                              = more confidence
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row  mx-0 bottom-button-container">
                <div className="previous col-5 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-7 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={postSubmission}
                    disabled={disabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcerciseAnswers;
