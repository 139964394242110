import { GetPostTreatmentQuestionnaire } from "../../../../services/post-treatment/get-post-treatment-questionnaire";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import React from "react";
import { options } from "../../../../constants/pretreatment/FneQuestionnaire";

const PostFneQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("SAS"); // Get Questionnaire from API

  /*******  Page Params  *******/
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const title = "Social Anxiety Scale";

  const content = `<div> <p class='mb-0'>Phew! Last questionnaire - well done <span class='normal-text-black'> ${user?.userInfo?.Fname}  ${user?.userInfo?.Lname}.</span> We all feel a bit down at times and this final questionnaire is going to help the program better understand how happy or sad you’ve been feeling lately.</p></div>`;

  /*******  Render  *******/

  return (
    <div>
      <Helmet>
        <title>Social Anxiety Scale | ASRC iBroadway</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="part_7/complete"
        questions={questionnaire}
        options={options}
        type={"bfne"}
        error={errorMessage}
        previousLink="part_7/dass_questionnaire"
      />
    </div>
  );
};

export default PostFneQuestionnaire;
