import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup } from "react-bootstrap";
import {
  GetPredictionSituation,
  GetUserPredictionSituation,
} from "../../../../services/part4/predictions-situation-task";
import React, { useState } from "react";

import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Part4SixthPage = () => {
  const [predictionSituation, errorPredictionMessage] =
    GetPredictionSituation(); // Get Prediction Situation from api
  const [predictionUserSituation, errorUserMessage] =
    GetUserPredictionSituation(); // Get User Prediction User from api

  /*******   States *******/
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState({ data: [] });
  const [disabled, setDisabled] = useState(true);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******  Constants  *******/
  const options = [
    "0",
    "10",
    "20",
    "30",
    "40",
    "50",
    "60",
    "70",
    "80",
    "90",
    "100",
  ];

  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  // const selectHandler = (name, value, ques, label) => {
  //     setOptions({ ...options, [name]: value });

  //     if (state.data.some(item => item.question === ques)) {                                // Checking Question ID in state on change

  //         let updatedData = state.data.map(item => {
  //             if (item.question === ques) {
  //                 return { ...item, "question": ques, "userResponce": label, "quesindex": name };      // Updating user selection in state
  //             }
  //             return item;
  //         });

  //         setState({ data: updatedData });
  //     } else {
  //         setState(state => ({
  //             data: [...state.data, { "question": ques, "userResponce": label, "quesindex": name }]             // Adding new question response in state
  //         }));
  //     }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name)
    if (state.data.some((item) => item.predictions_userresponce_id === name)) {
      let updatedData = state.data?.map((item) => {
        if (item.predictions_userresponce_id === name) {
          return {
            ...item,
            predictions_userresponce_id: name,
            rating_scale: value,
          };
        }
        return item;
      });
      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { predictions_userresponce_id: name, rating_scale: value },
        ],
      }));
    }
    if (state.data.length === 2) {
      setDisabled(false);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user info
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
      });
      const response = await axios.post(
        BASE_URL + `prediction_probabilitie_userresponce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.data.length === 3) {
        navigate("/part_4/the_experiment", { replace: true });
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(
          "Please check Part 4 Prediction Probabilitie Userresponse Api"
        );
        ErrorLogging(
          "HTTP POST Part 4 /prediction_probabilitie_userresponce/userId" +
            error
        );
        handle();
      }
    }
  };

  const nextPage = () => {
    navigate("/part_4/the_experiment", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_4/predictions`, { replace: true });
  };

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      predictionUserSituation !== undefined &&
      predictionUserSituation.length !== 0 &&
      predictionUserSituation.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Prediction Probabilities | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div id="main">
                  <div className="title page-title-style">
                    Prediction Probabilities
                  </div>
                  <div className="card-body">
                    <p className="normal-text-black">
                      <span className="normal-text">
                        Here is your situation:{" "}
                      </span>
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>
                            {predictionSituation?.situationtask_data?.situation}
                          </>
                        )}
                      </span>
                    </p>
                    <p className="normal-text-black">
                      <span className="normal-text">Here is your task: </span>{" "}
                      <span className="dark-purple-color">
                        {predictionSituation.length !== 0 && (
                          <>{predictionSituation?.situationtask_data?.task}</>
                        )}
                      </span>
                    </p>
                    <p className="">Avoid YOUR safety behaviours:</p>
                    <ul>
                      {errorPredictionMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorPredictionMessage}
                            </div>
                          </div>
                        </div>
                      ) : predictionSituation !== undefined &&
                        predictionSituation.length !== 0 ? (
                        <div>
                          {predictionSituation?.safty_behaviour_data?.map(
                            (item, index) => {
                              const card = (
                                <li className="listing" key={index}>
                                  {item.question}
                                </li>
                              );
                              return card;
                            }
                          )}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </ul>
                    <p className="">
                      Here are your 3 predictions (taken from the list above).
                      For each one, select a number between 0 and 100 to
                      indicate the likelihood of that outcome actually occurring
                      (where 0 means ‘certain it will NOT occur’, and 100 means
                      ‘certain it WILL occur’):
                    </p>

                    <div>
                      {errorUserMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorUserMessage}
                            </div>
                          </div>
                        </div>
                      ) : predictionUserSituation !== undefined &&
                        predictionUserSituation.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table questions-table predictions mb-0">
                            <thead>
                              <tr>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {predictionUserSituation?.predec_data?.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td className="px-0 dark-purple-color">
                                      {"'" + item.predictions_question + "'"}
                                    </td>

                                    {options?.map((option) => (
                                      <td
                                        className="options"
                                        key={`${item.id}_${option}`}
                                      >
                                        <InputGroup.Checkbox
                                          defaultChecked={
                                            item.responce === option
                                              ? true
                                              : false
                                          }
                                          disabled={item.disable_status}
                                          className="radio-size"
                                          type="radio"
                                          aria-label="radio 1"
                                          value={option}
                                          name={item.id}
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="name">{option}</label>
                                        <></>
                                      </td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                  <FooterOfPrint />
                </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    {predictionUserSituation !== undefined &&
                    predictionUserSituation.length !== 0 &&
                    predictionUserSituation.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? "Please select probabilities for all your predictions."
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}
          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4SixthPage;
