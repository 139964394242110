import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';


const GetInputQuestions =  (situation) => {
    
    const [inputQuestions, setInputQuestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchInputQuestions = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
            const params = JSON.stringify({
                "situation": situation,
            });    
            const response = await axios.post(BASE_URL + `getAll_thinkingExcercise_Bysituation/${user.userInfo._id}`, params, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setInputQuestions(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchInputQuestions();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
            else{
            setErrorMessage('There is some error, please try again later.');
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
        }     
    };

    useEffect(() => {
        fetchInputQuestions();
    }, []);
  
  return [inputQuestions, errorMessage];
};

export {
    GetInputQuestions,
}