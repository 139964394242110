import "../../styles/Slider/slider.css";
import "../../styles/Slider/slider.css";
import { Button, InputGroup } from "react-bootstrap";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
} from "../../constants/motivators/motivators";
import { useDispatch, useSelector } from "react-redux";
import AudioProgressBar from "../../components/global/AudioProgressBar";
import { BASE_URL, IMAGE_URL } from "../../env/Baseurl";
import { DOMAIN_NAME } from "../../env/Baseurl";
import ErrorLogging from "../../services/error-logs";
import { Link } from "react-router-dom";
import RefreshToken from "../../services/refresh-token";
import Slider from "../global/Slider";
import SuperHeroSlider from "../global/SuperHeroSlider";
import { TailSpin } from "react-loader-spinner";
import VolumeSlider from "./VolumeSlider";
import axios from "axios";
import blueSadImage from "../../assets/GIf/healthicons_crying.svg";
import getAudio from "../../actions/audio";
import normalPause from "../../assets/GIf/normalPause.svg";
import normalPlay from "../../assets/GIf/normalPlay.svg";
import sadLife from "../../assets/GIf/crying.svg";
import { useLocation } from "react-router-dom";

const Footer = ({ currentAudio, setCurrentAudio, isPlaying, setIsPlaying }) => {
  /*******   States *******/
  const [errorApiMessage, setErrorMessage] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [progressBarData, setProgressBarData] = useState({
    num: 0,
    progress: 0,
  });
  const [sadImage, setSadImage] = useState(sadLife);
  const [motivatorsImage, setMotivators] = useState("");
  const [openSuperHeroSlider, setOpenSuperHeroSlider] = useState(false);
  // const [currentIndex, errorMessage] = GetCurrentIndex();

  /*******   Redux *******/
  const { audio } = useSelector((state) => state.audio);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  /******* Local storage *******/
  const preTreatmentProgress = localStorage.getItem("preTreatmentProgress");
  const moduleParts = localStorage.getItem("moduleParts");
  const moduleName = localStorage.getItem("moduleName");
  const moduleProgress = localStorage.getItem("moduleProgress");

  /*******   Helper Constants *******/

  const location = useLocation();

  /********** User Information *************************/

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  let superHero = user.userInfo.superherosect;
  let audio_pre = user?.userInfo?.audio_preference;
  const [clinicianName, setClinicianName] = useState("");

  const getClinicianName = async () => {
    if (user?.userInfo?.audio_preference == "ross") {
      setClinicianName("Ross");
    } else if (user?.userInfo?.audio_preference == "margot") {
      setClinicianName("Margot");
    } else setClinicianName("Anthony");
  };

  const moduleProgressBar = () => ({
    width: `${progressBarData.progress}%`,
  });

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const userVisitedPage = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        lastPage_visited: location?.pathname,
      });
      const response = await axios.post(
        BASE_URL + `page_partUpdate/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      // console.log(response.data.page_url, "its page url");
      localStorage.setItem("last_visited_url", response.data.page_url);
    } catch (error) {
      if (error?.response?.status === 401) {
        RefreshToken(user.userInfo._id);
        userVisitedPage();
      } else {
        setErrorMessage("Please check Page PartUpdate Api");
        ErrorLogging("HTTP POST /page_partUpdate/userId" + error);
      }
    }
  };

  /***************** handle motivators images **************/

  const hadleMotivators = async () => {
    if (location?.pathname?.includes("intro")) {
      if (location?.pathname == "/intro/dass_questionnaire") {
        setMotivators(item1[superHero][0]);
        await localStorage.setItem("lastCallOut", item1[superHero][0]);
      } else {
        // setMotivators("")
        // await localStorage.setItem("lastCallOut", item1[superHero][0]);
      }
    } else if (location?.pathname.includes("part_1")) {
      if (location?.pathname == "/part_1/thinking_exercise_2") {
        setMotivators(item1[superHero][1]);
        localStorage.setItem("lastCallOut", item1[superHero][1]);
      } else if (location?.pathname == "/part_1/thinking_exercise_4") {
        setMotivators(item1[superHero][2]);
        localStorage.setItem("lastCallOut", item1[superHero][2]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_2")) {
      if (location?.pathname == "/part_2/challenge_2_sample_answers") {
        setMotivators(item2[superHero][0]);
        localStorage.setItem("lastCallOut", item2[superHero][0]);
      } else if (location?.pathname == "/part_2/challenge_4") {
        setMotivators(item2[superHero][1]);
        localStorage.setItem("lastCallOut", item2[superHero][1]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_3")) {
      if (
        location?.pathname == "/part_3/another_example_of_safety_behaviours"
      ) {
        setMotivators(item3[superHero][0]);
        localStorage.setItem("lastCallOut", item3[superHero][0]);
      } else if (location?.pathname == "/part_3/your_model_of_social_anxiety") {
        setMotivators(item3[superHero][1]);
        localStorage.setItem("lastCallOut", item3[superHero][1]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_4")) {
      if (location?.pathname == "/part_4/perceived_negative_evaluations") {
        setMotivators(item4[superHero][0]);
        localStorage.setItem("lastCallOut", item4[superHero][0]);
      } else if (
        location?.pathname ==
        "/part_4/behavioural_causal_thought_sample_answers"
      ) {
        setMotivators(item4[superHero][1]);
        localStorage.setItem("lastCallOut", item4[superHero][1]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_5")) {
      if (location?.pathname == "/part_5/should_statements") {
        setMotivators(item5[superHero][0]);
        localStorage.setItem("lastCallOut", item5[superHero][0]);
      } else if (location?.pathname == "/part_5/tyranny_of_the_shoulds") {
        setMotivators(item5[superHero][1]);
        localStorage.setItem("lastCallOut", item5[superHero][1]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_6")) {
      if (location?.pathname == "/part_6/sar_responses") {
        setMotivators(item6[superHero][0]);
        localStorage.setItem("lastCallOut", item6[superHero][0]);
      } else if (location?.pathname == "/part_6/more_observer_bias") {
        setMotivators(item6[superHero][1]);
        localStorage.setItem("lastCallOut", item6[superHero][1]);
      } else {
        // setMotivators("")
      }
    } else if (location?.pathname.includes("part_7")) {
      if (location?.pathname == "/part_7/preventing_relapse") {
        setMotivators(item7[superHero][0]);
        localStorage.setItem("lastCallOut", item7[superHero][0]);
      } else if (location?.pathname == "/part_7/a_final_thought") {
        setMotivators(item7[superHero][1]);
        localStorage.setItem("lastCallOut", item7[superHero][1]);
      }
      // else if (location?.pathname == '/part_7/dass_questionnaire') {
      //   setMotivators(item1[superHero][0])
      // }
      else {
        // setMotivators("")
      }
    }
  };

  /*******   Hooks  *******/

  useEffect(() => {
    dispatch(getAudio(location?.pathname, clinicianName))
      .then(() => {})
      .catch(() => {});
  }, [message]);

  useEffect(() => {
    userVisitedPage();
    getClinicianName();
  }, []);

  useEffect(() => {
    hadleMotivators();
  }, []);

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef();
  const progressBarRef = useRef(null);
  const volumeRef = useRef();
  const playAnimationRef = useRef();
  const [volume, setVolume] = useState(100);

  const handleAudioEnd = (audio_number) => {
    if (audio_number + 1 < audio?.audioData?.length) {
      setCurrentAudio({
        ...currentAudio,
        enabledAudio: audio_number + 1,
      });
    }
  };

  const repeat = useCallback(() => {
    if (audio?.audioData?.length > 0) {
      const currentTime = audioRef.current?.currentTime;
      setTimeProgress(currentTime);
      const progressElement = progressBarRef.current;
      if (progressElement) {
        progressElement.value = currentTime;
        progressElement.style.setProperty(
          "--range-progress",
          `${(progressElement.value / duration) * 100}%`
        );
        // console.log('run');
        playAnimationRef.current = requestAnimationFrame(repeat);
      }
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      if (isPlaying) {
        let res = audioElement.play();
        if (res !== undefined) {
          res
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              setIsPlaying(false);
              audioElement.pause();
            });
        }
        // playAnimationRef.current = requestAnimationFrame(repeat);
      } else {
        audioElement.pause();
        // cancelAnimationFrame(playAnimationRef.current);
      }
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [isPlaying, currentAudio?.audio, repeat]);

  /*******   Render  *******/

  return (
    <>
      <div>
        <div className="row footer m-0">
          {audio !== undefined && audio.length !== 0 ? (
            <>
              <div
                className={`${
                  audio?.audioData?.length > 0 ? "col-md-3" : "col-md-4"
                }`}
              >
                <div className="ft-headstext">Your Progress</div>
                <div className="row subtext-blocksec">
                  <div className="col-md-6">
                    <span> {audio.progressbarData?.part} </span>
                    <div className="progress" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            (audio.progressbarData?.module * 100) / 7 + "%",
                          backgroundColor: "#3f0866",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span>{audio.progressbarData?.page_type}</span>
                    <div className="progress" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            (audio.progressbarData?.page_no * 100) /
                              audio.progressbarData?.total_page_no +
                            "%",
                          backgroundColor: "#3f0866",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {audio?.audioData?.length > 0 && (
                <div className="col-md-5 col-sm-6 col-5 audio-part">
                  {audio?.audioData?.length > 0 && (
                    <>
                      <div className="clinician-image">
                        <img
                          src={
                            location?.pathname == "/intro/welcome"
                              ? `${IMAGE_URL}images/clinicianImages/ross.png`
                              : {
                                  ross: `${IMAGE_URL}images/clinicianImages/ross.png`,
                                  margot: `${IMAGE_URL}images/clinicianImages/margot.png`,
                                  tony: `${IMAGE_URL}images/clinicianImages/Anthony.png`,
                                }[user?.userInfo?.audio_preference]
                          }
                          alt="Clinician"
                        />
                      </div>
                      <div className="audio-player-container">
                        {audio?.audioData?.length > 1 && (
                          <div
                            className="ft-headstext radio-options"
                            style={{ display: "flex", marginLeft: "9px" }}
                          >
                            {audio?.audioData?.map((item, index) => (
                              <div key={item?.audio_id}>
                                <InputGroup.Checkbox
                                  // defaultChecked={index === 0
                                  // }
                                  checked={currentAudio?.audio === index}
                                  disabled={index > currentAudio?.enabledAudio}
                                  className="radio-size"
                                  type="radio"
                                  aria-label="radio 1"
                                  value={item?.audio_id}
                                  name="audio"
                                  onChange={() => {
                                    setIsPlaying(true);
                                    setCurrentAudio({
                                      ...currentAudio,
                                      audio: index,
                                    });
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="audio-player">
                          <div
                            className="footer-play-button"
                            onClickCapture={() => setIsPlaying((prev) => !prev)}
                          >
                            <img
                              className="selectedPause"
                              src={
                                isPlaying ? `${normalPause}` : `${normalPlay}`
                              }
                              alt="casual-park"
                            />
                          </div>
                          <AudioProgressBar
                            audioRef={audioRef}
                            progressBarRef={progressBarRef}
                            timeProgress={timeProgress}
                            duration={duration}
                          />
                          <VolumeSlider
                            volume={volume}
                            setVolume={setVolume}
                            volumeRef={volumeRef}
                            audioRef={audioRef}
                          />
                          <audio
                            id="clinician-audio"
                            ref={audioRef}
                            autoPlay={true}
                            onLoadedMetadata={onLoadedMetadata}
                            onEnded={() => {
                              setIsPlaying(false);
                              if (audio?.audioData.length > 1) {
                                handleAudioEnd(currentAudio?.audio);
                              } else {
                              }
                            }}
                            src={
                              audio?.audioData.length > 1
                                ? audio?.audioData[`${currentAudio?.audio}`]
                                    ?.audio_path
                                : audio?.audioData[0]?.audio_path
                            }
                            type="audio/mpeg"
                          ></audio>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="col-md-8 col-sm-12 col-12 loader-icon">
              <TailSpin color="#4f5962" height={50} width={50} />
            </div>
          )}
          <div
            className={`${
              audio?.audioData?.length > 0
                ? "col-7 col-sm-6 col-md-4 col-xl-3"
                : "col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 "
            }`}
          >
            <div
              className={`row py-2 footer-superhero-feeling ${
                audio?.audioData?.length > 0
                  ? ""
                  : "justify-content-sm-between justify-content-between justify-content-md-end"
              }`}
            >
              <div
                className={`footerSuperHero col-md-4 col-4 ${
                  audio?.audioData?.length > 0 ? "" : "d-sm-flex d-flex"
                }`}
                onClick={() => setOpenSuperHeroSlider(true)}
              >
                <img
                  src={
                    {
                      superStrong: `${IMAGE_URL}images/superheroImages/superStrong.png`,
                      superBrave: `${IMAGE_URL}images/superheroImages/SuperBrave.png`,
                      superCalm: `${IMAGE_URL}images/superheroImages/SuperCalm.png`,
                      superFearless: `${IMAGE_URL}images/superheroImages/SuperFearless.png`,
                      superResilient: `${IMAGE_URL}images/superheroImages/SuperSilient.png`,
                      superSmart: `${IMAGE_URL}images/superheroImages/SuperSmart.png`,
                    }[user?.userInfo?.superherosect]
                  }
                  alt="Super-hero"
                />
              </div>
              <div
                className="sadLife col-md-8 col-8"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <Link to="/feeling_sad">
                  {" "}
                  <img
                    className="imageStyle"
                    src={isHovering ? blueSadImage : sadImage}
                    alt="heroQuote"
                  />
                  <span id="feeling-sad">Feeling Sad?</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {motivatorsImage && <Slider motivatorsImage={motivatorsImage} />}
        <SuperHeroSlider
          setOpenSuperHeroSlider={setOpenSuperHeroSlider}
          openSuperHeroSlider={openSuperHeroSlider}
        />
      </div>
    </>
  );
};

export default memo(Footer);
