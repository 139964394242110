import { GetPostTreatmentQuestionnaire } from "../../../../services/post-treatment/get-post-treatment-questionnaire";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import React from "react";
import { options } from "../../../../constants/pretreatment/DassQuestionnaire";

const PostDassQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("DASS"); // Get Questionnaire from API

  /*******  Page Params  *******/

  const title = "Mood Scale";

  const content =
    "<div><p>Please read each statement and select a number (0, 1, 2 or 3) which indicates how much the statement applied to you over the past week. There are no right or wrong answers. Do not spend too much time on any statement.</p> <p class='listing'>0 - Rarely or none of the time.</p> <p class='listing'>1 - Some or a little of the time.</p> <p class='listing'>2 - Occasionally or a moderate amount of time.</p><p class='listing mb-0'>3 - Most or all of the time.</p></div>";

  /*******  Render  *******/

  return (
    <div>
      <Helmet>
        <title>Mood Scale | ASRC iBroadway</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="part_7/fne_questionnaire"
        questions={questionnaire}
        options={options}
        type={"dass"}
        error={errorMessage}
        previousLink="part_7/avoidance_questionnaire"
      />
    </div>
  );
};

export default PostDassQuestionnaire;
