import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import {
  selectOptions1,
  selectOptions2,
} from "../../../../constants/part1/WhatsCasualThought";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BASE_URL, IMAGE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetCasualThoughtResponse } from "../../../../services/part1/get-casual-thought-response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Select from "react-select";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { selectStyle } from "../../../../constants/global/StyleObject";
import { useNavigate } from "react-router-dom";

const WhatsCasualThought = () => {
  const [casualThoughtResponse, responseErrorMessage] =
    GetCasualThoughtResponse();

  /*******   States *******/

  const [state, setState] = useState({ data: [] });
  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState({
    one: "",
    two: "",
  });
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  /*******   Page Params  *******/

  const title1 = "What are 'Causal' Thoughts?";
  const title2 = "Spotting Causal Thoughts";

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const selectHandler = (name, value, ques, label) => {
    setOptions({ ...options, [name]: value });

    if (state.data.some((item) => item.question === ques)) {
      // Checking Question ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.question === ques) {
          return {
            ...item,
            question: ques,
            userResponce: label,
            quesindex: name,
          }; // Updating user selection in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { question: ques, userResponce: label, quesindex: name },
        ], // Adding new question response in state
      }));
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
      });
      const response = await axios.post(
        BASE_URL + `casual_thoughts_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Casual Thoughts Api");
        ErrorLogging("HTTP POST /casual_thoughts_create/userId" + error);
      }
    }
    handleAudioStoreData();
    navigate("/part_1/causal_thoughts", { replace: true });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_1/causal_thoughts", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_1/thinking_exercise_2_sample_answers`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Hooks  *******/

  useEffect(() => {
    let isEmpty = Object.values(options).every((x) => x !== ""); // Checking if there is empty response
    setDisabled(!isEmpty);
  }, [options]);

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>What is a 'Causal' Thought? | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title1}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <div className="welcome-content">
                              <div className="content">
                                <p>
                                  A causal thought is a thought, attitude or
                                  belief that ‘causes’ an emotion or feeling.
                                  You have already written many causal thoughts
                                  in this program, for example, the sentences
                                  you made up to explain Goran’s and Mia’s
                                  feelings in the previous tasks.
                                </p>
                                <p>Causal thoughts make people:</p>
                                <ul className="listing">
                                  <li>
                                    Dread social situations and often avoid them
                                    altogether.
                                  </li>
                                  <li>
                                    Interpret other people’s behaviour as signs
                                    of negative evaluation.
                                  </li>
                                  <li>
                                    Feed their own social anxiety that keeps
                                    social confidence low and negative beliefs
                                    unchanged.
                                  </li>
                                </ul>
                                <p>
                                  Let’s see how causal thoughts impact people in
                                  everyday life. Check out the pic below:
                                </p>
                              </div>
                              <div className="main-container my-5">
                                <div
                                  className=" sub-container center-align"
                                  style={{ maxWidth: "unset" }}
                                >
                                  <div className="image-container">
                                    <img
                                      src={`${IMAGE_URL}images/part1/Chad_Karl.jpg`}
                                      alt="chad & karl"
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="text-center">
                                What causal thoughts could have caused Chad and
                                Karl to feel this way?
                              </p>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={currentAudio?.enabledAudio < 1 ? true : false}
                      onClick={() => {
                        if (currentAudio?.enabledAudio < 1) return false;
                        else {
                          setIsPlaying(true);
                          setCurrentAudio({ ...currentAudio, audio: 1 });
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-heading"
                      >
                        <Typography>
                          <div className="page-title-style m-0">{title2}</div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div className="card-body p-0">
                            <p>
                              Chad and Karl are at the skate park. They have
                              different thoughts that cause them to feel the way
                              they do.
                            </p>
                            {responseErrorMessage ? (
                              <div className="container mt-5">
                                <div className="row justify-content-center">
                                  <div
                                    className="alert alert-danger text-center"
                                    role="alert"
                                  >
                                    {responseErrorMessage}
                                  </div>
                                </div>
                              </div>
                            ) : casualThoughtResponse !== undefined &&
                              casualThoughtResponse.length !== 0 ? (
                              <div className="row m-0">
                                <div className="main-container">
                                  <div className="container">
                                    <p className="component-heading-style text-center">
                                      How do you think Chad feels due to his
                                      causal thought?
                                    </p>
                                    <div className="image-container">
                                      <img
                                        src={`${IMAGE_URL}images/part1/Chad.jpg`}
                                        alt="image"
                                      />
                                    </div>
                                    <p className="mt-3">
                                      “It’s great to be doing something I like
                                      instead of school.”
                                    </p>
                                    <Select
                                      // style={selectStyle}
                                      styles={selectStyle}
                                      placeholder={
                                        casualThoughtResponse.global_disable_status ===
                                        false
                                          ? "Choose a feeling for Chad"
                                          : casualThoughtResponse.array[0]
                                              .userResponce
                                      }
                                      isDisabled={
                                        casualThoughtResponse.global_disable_status ===
                                        false
                                          ? false
                                          : true
                                      }
                                      options={selectOptions1}
                                      onChange={(e) => {
                                        selectHandler(
                                          "one",
                                          e.value,
                                          "How do you think Chad feels due to his causal thought?",
                                          e.label
                                        );
                                      }}
                                    />
                                    <p className="selected-txt-sty">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: options.one,
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                  <div className="container">
                                    <p className="component-heading-style text-center">
                                      How do you think Karl feels due to his
                                      causal thought?
                                    </p>
                                    <div className="image-container">
                                      <img
                                        src={`${IMAGE_URL}images/part1/Karl.jpg`}
                                        alt="image"
                                      />
                                    </div>
                                    <p className="mt-3">
                                      “It’s packed out. There shouldn’t be so
                                      many beginners here.”
                                    </p>
                                    <Select
                                      styles={selectStyle}
                                      placeholder={
                                        casualThoughtResponse.global_disable_status ===
                                        false
                                          ? "Choose a feeling for Karl"
                                          : casualThoughtResponse.array[1]
                                              .userResponce
                                      }
                                      isDisabled={
                                        casualThoughtResponse.global_disable_status ===
                                        false
                                          ? false
                                          : true
                                      }
                                      options={selectOptions2}
                                      onChange={(e) => {
                                        selectHandler(
                                          "two",
                                          e.value,
                                          "How do you think Karl feels due to his causal thought?",
                                          e.label
                                        );
                                      }}
                                    />
                                    <p className="selected-txt-sty">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: options.two,
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="loader-icon">
                                <TailSpin
                                  color="#4f5962"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            )}
                            <div>
                              <p className="component-heading-style">
                                The causal thoughts that lower social confidence
                                the most can be divided into 3 types.
                              </p>
                              <div
                                style={{
                                  background: "#7640cc1a",
                                  borderRadius: "10px",
                                }}
                              >
                                <p
                                  className="mb-0"
                                  style={{
                                    background: "#6a3851",
                                    color: "#fff",
                                    padding: "10px 20px",
                                  }}
                                >
                                  See if you can relate to any of these:
                                </p>
                                <div
                                  className="row m-0 m-0"
                                  style={{ padding: "20px" }}
                                >
                                  <div className="col-lg-4 p-0 pr-lg-3">
                                    <p className="normal-text-black">
                                      1. Perfectionist standards for social
                                      situations.
                                    </p>
                                    <ul className="listing">
                                      <li>
                                        I must always sound intelligent and
                                        fluent
                                      </li>
                                      <li>
                                        I must not show any signs of weakness
                                      </li>
                                      <li>
                                        I must not interrupt or slow down other
                                        people’s speech
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-lg-4 p-0 pr-lg-3">
                                    <p className="normal-text-black">
                                      2. Negative beliefs about what will happen
                                      if you act a certain way in public.
                                    </p>
                                    <ul className="listing">
                                      <li>
                                        If I stutter, people won’t like me
                                      </li>
                                      <li>
                                        If I disagree with someone, they’ll
                                        reject me
                                      </li>
                                      <li>
                                        If I’m quiet, people will think I’m
                                        weird
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-lg-4">
                                    <p className="normal-text-black">
                                      3. Concrete negative beliefs about one’s
                                      self.
                                    </p>
                                    <ul className="listing">
                                      <li>I’m unlikable</li>
                                      <li>I’m boring</li>
                                      <li>I’m stupid</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {casualThoughtResponse !== undefined &&
                  casualThoughtResponse.length !== 0 &&
                  casualThoughtResponse.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? "Please select options from all dropdown to continue."
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={buttonState}
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsCasualThought;
