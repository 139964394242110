import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part3/avoidenceSafety/avoidenceSafety.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AvoidanceSafetyBehaviours = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  }

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_3/another_example_of_safety_behaviours", { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_3/intro`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> Why Avoidance Doesn't work | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body avoidance_Safety p-0 mb-0">
                  <div className="title page-title-style mb-0">
                    Why Avoidance Doesn't work
                  </div>
                  <div className="card-body">
                    <p>
                      Avoidance makes you feel great straight away because the
                      anxiety stops, but this feeling wears off and you end up
                      feeling worse.
                    </p>
                    <div className="myRow">
                      <div className="myColumn1">
                        <div className="imageContainer">
                          <img
                            src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/138.jpg`}
                            alt="hand"
                          />
                        </div>
                      </div>
                      <div className="myColumn2">
                        <div>
                          <p className="head">
                            Avoidance is like quicksand. The more you struggle
                            to escape, the worse things get.
                          </p>
                          <div className="myRow_1">
                            <div className="myColumn1">
                              <p className="main-title-style ">
                                The problem is that{" "}
                                <span className="main-title-style-yellow">
                                  avoidance makes anxiety stronger
                                </span>
                                , so then a person learns to{" "}
                                <span className="main-title-style-yellow">
                                  use avoidance
                                </span>{" "}
                                in all other areas of their life
                                to deal with the anxiety,{" "}
                                <span className="main-title-style-yellow">
                                  but this just keeps making it worse,
                                  encouraging more avoidance.
                                </span>
                              </p>
                            </div>
                            <div className="myColumn2">
                              <div className="imageContainer">
                                <img
                                  src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/137.jpg`}
                                  alt="hoading"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="component-heading-style">
                      Avoidance makes anxiety worse because it:
                    </p>
                    <div className="main-container">
                      <div className="container">
                        <p className="">
                          1. Reinforces the belief that the situation is really
                          dangerous.
                        </p>
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/139.jpg`}
                            alt="image"
                          />
                          {/* <p className="listing mt-3">
                            Many people think this is how improvement over time
                            for social anxiety shout look like.
                          </p> */}
                        </div>
                      </div>
                      <div className="container">
                        <p className="">
                          2. Prevents this belief from being tested.
                        </p>
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/140.jpg`}
                            alt="image"
                          />
                          {/* <p className="listing mt-3">
                            This is what improvement over time for social
                            anxiety really looks like.
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <p className="component-heading-style">
                      Problems with avoidance:
                    </p>
                    <div className="main-container problem-avoidance">
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/141.jpg`}
                            alt="image"
                          />
                          <p className="listing mt-3">
                            You can’t always avoid situations.
                          </p>
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part3/avoidenceSafetyBehaviour/142.jpg`}
                            alt="image"
                          />
                          <p className="listing mt-3">
                            It restricts your lifestyle, so you can’t do things
                            you want.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p>
                      Avoidance doesn’t stop there. It can make you avoid a
                      social situation while still facing the situation.
                      Confused? All will be revealed over the page.
                    </p>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying}
              setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AvoidanceSafetyBehaviours;
