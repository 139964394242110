import "../../../styles/login.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BASE_URL, IMAGE_URL } from "../../../env/Baseurl";
import { CLEAR_MESSAGE } from "../../../actions/types";
import { DOMAIN_NAME } from "../../../env/Baseurl";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TermsAndConditions from "../../registration/TermsAndConditions";
import UserAccountInfo from "../../registration/UserAccountInfo";
import UserClinicianInfo from "../../registration/UserClinicianInfo";
import UserGuardian from "../../registration/UserGuardin";
import UserLocationInfo from "../../registration/UserLocationInfo";
import UserNameInfo from "../../registration/UserNameInfo";
import UserPasswordInfo from "../../registration/UserPasswordInfo";
import UserSituationInfo from "../../registration/UserSituationInfo";
import UserSuperHeroInfo from "../../registration/UserSuperHeroInfo";
import axios from "axios";
import { register } from "../../../actions/auth";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const RegisterScreen = () => {
  /*******   States  *******/
  const [emptyCheck, setEmptyCheck] = useState({
    user_email: "",
    user_name: "",
    age: "",
    password: "",
    confirm_password: "",
    user_situation: "",
    applied_situation: "",
    first_name: "",
    last_name: "",
    country: "",
    terms: "",
    audio_preference: "",
    superherosect: "",
    guardian_first_name: "",
    guardian_last_name: "",
    guardian_email: "",
    guardian_mobile: "",
  });
  const [values, setValues] = useState({
    user_email: "",
    user_name: "",
    age: "",
    password: "",
    confirm_password: "",
    user_situation: "",
    applied_situation: "",
    first_name: "",
    last_name: "",
    city: "",
    country: "",
    countryCode: "",
    dialCode: "",
    audio_preference: "",
    superherosect: "",
    guardian_first_name: "",
    guardian_last_name: "",
    guardian_email: "",
    guardian_mobile: "",
  });
  const [step, setStep] = useState(1);
  const [emailCheck, setEmailCheck] = useState();
  const [checked, setChecked] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [nameValidation, setNameValidation] = useState({
    firstNameValidation: false,
    lastNameValidation: false,
  });

  /**********check number and sumbol in string*********/

  function containsNumbersAndSymbols(inputString) {
    const regex = /[0-9!@#$%^&*()_+={}\[\]:;<>,.?~\\|]/;
    return regex.test(inputString);
  }

  /*******   Redux  *******/
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  /*******   HelperFunctions  *******/
  const domainName = DOMAIN_NAME;

  let navigate = useNavigate();

  const emailChecker = async () => {
    try {
      const params = JSON.stringify({
        email:
          values?.user_situation === "I am an adolescent who stutters"
            ? values?.guardian_email
            : values.user_email,
      });
      const response = await axios.post(BASE_URL + "checkEmail", params, {
        headers: {
          "content-type": "application/json",
        },
      });
      setEmailCheck(response.data.status);
    } catch (error) {
      setEmailCheck("Internal server error. Please try again later.");
    }
  };
  const handleMobileFormat = () => {
    const regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return regex.test(values?.guardian_mobile);
  };

  const nextStep = (e) => {
    if (step === 1) {
      if (values.user_email === "" || values.user_name === "") {
        {
          values.user_email === ""
            ? setEmptyCheck({
                user_email: "Please fill out Email field to continue.",
              })
            : setEmptyCheck({
                user_name: "Please fill out Username field to continue.",
              });
        }
      } else if (validator.isEmail(values.user_email) === false) {
        setEmptyCheck({ user_email: "Please check the email format." });
      } else if (emailCheck === false) {
        setEmptyCheck({
          user_email:
            "This email already exists in our system, Please use another one",
        });
      } else {
        setEmptyCheck({ user_email: "" });
        setStep(step + 1);
      }
    }
    if (step === 2) {
      if (values.password === "" || values.confirm_password === "") {
        {
          values.password === ""
            ? setEmptyCheck({
                password: "Please fill out Password field to continue.",
              })
            : setEmptyCheck({
                confirm_password:
                  "Please fill out Confirm Password field to continue.",
              });
        }
      } else if (values.password !== values.confirm_password) {
        setEmptyCheck({
          confirm_password: "Password and Confirm Password do not match.",
        });
      } else if (values.password === values.confirm_password) {
        setEmptyCheck({ confirm_password: "Password match." });
        setStep(step + 1);
      }
    }
    if (step === 3) {
      if (
        values.user_situation === "" ||
        (values.user_situation === "I am an adolescent who stutters" &&
          values.age === "") ||
        values.applied_situation === ""
      ) {
        if (values.user_situation === "") {
          setEmptyCheck({
            user_situation: "Please select one of the options to continue.",
          });
        } else if (
          values.user_situation === "I am an adolescent who stutters" &&
          values.applied_situation === ""
        ) {
          setEmptyCheck({
            applied_situation: "Please select one of the options to continue.",
          });
        } else if (
          values.user_situation === "I am an adolescent who stutters" &&
          values.applied_situation !== "" &&
          values.age === ""
        ) {
          setEmptyCheck({
            age: "Please select one of the options to continue.",
          });
        } else {
          setValues({ ...values, ["applied_situation"]: "none" });
        }
      } else {
        setStep(step + 1);
      }
    }
    if (step === 4) {
      if (values.first_name === "" || values.last_name === "") {
        {
          values.first_name === ""
            ? setEmptyCheck({
                first_name: "Please fill out First Name field to continue.",
              })
            : setEmptyCheck({
                last_name: "Please fill out Last Name field to continue.",
              });
        }
      } else if (
        nameValidation.firstNameValidation === false ||
        nameValidation.lastNameValidation == false
      ) {
        nameValidation.firstNameValidation === false
          ? setEmptyCheck({ first_name: "Numbers and symbols are not allowed" })
          : setEmptyCheck({ last_name: "Numbers and symbols are not allowed" });
      } else {
        setStep(step + 1);
      }
    }
    if (step === 5) {
      if (values.country === "") {
        setEmptyCheck({
          country: "Please fill out Country field to continue.",
        });
      } else {
        if (values?.user_situation !== "I am an adolescent who stutters")
          setStep(step + 2);
        else setStep(step + 1);
      }
    }
    if (step === 6) {
      if (values.guardian_first_name === "") {
        setEmptyCheck({
          guardian_first_name: "Please fill out First Name field to continue.",
        });
      } else if (values.guardian_last_name === "") {
        setEmptyCheck({
          guardian_last_name: "Please fill out Last Name field to continue.",
        });
      } else if (values.guardian_email === "") {
        setEmptyCheck({
          guardian_email: "Please fill out Email field to continue.",
        });
      } else if (validator.isEmail(values.guardian_email) === false) {
        setEmptyCheck({ guardian_email: "Please check the email format." });
      } else if (emailCheck === false) {
        setEmptyCheck({
          guardian_email:
            "This email already exists in our system, Please use another one",
        });
      } else if (values.guardian_mobile === "") {
        setEmptyCheck({
          guardian_mobile: "Please fill out Mobile Number field to continue.",
        });
      } else if (!handleMobileFormat()) {
        setEmptyCheck({ guardian_mobile: "Please check the mobile format." });
      } else {
        setStep(step + 1);
      }
    }
    if (step === 7) {
      if (checked === false) {
        setEmptyCheck({
          terms: "Please accept terms and conditions to proceed.",
        });
      } else setStep(step + 1);
    }
    if (step === 8) {
      if (values.audio_preference === "") {
        setEmptyCheck({
          audio_preference: "Please select one of these Psychologists.",
        });
      } else {
        setStep(step + 1);
      }
    }
    if (step === 9) {
      if (values.superherosect === "") {
        setEmptyCheck({
          superherosect: "Please select one of the SuperHeroes.",
        });
      } else {
        e.preventDefault();
        dispatch(
          register(
            values.user_email,
            values.user_name,
            values.age,
            values.password,
            values.confirm_password,
            values.user_situation,
            values.applied_situation,
            values.first_name,
            values.last_name,
            values.city,
            values.country,
            values.countryCode,
            values.dialCode,
            values.audio_preference,
            values.superherosect,
            domainName,
            values?.user_situation === "I am an adolescent who stutters"
              ? values?.guardian_first_name
              : "",
            values?.user_situation === "I am an adolescent who stutters"
              ? values?.guardian_last_name
              : "",
            values?.user_situation === "I am an adolescent who stutters"
              ? values?.guardian_email
              : "",
            values?.user_situation === "I am an adolescent who stutters"
              ? values?.guardian_mobile
              : "",
            values?.user_situation === "I am an adolescent who stutters"
              ? values?.age
              : ""
          )
        )
          .then(() => {
            setSuccessful(false);
          })
          .catch(() => {
            setSuccessful(false);
          });
        // alert("registered successfullly");
      }
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (
        values?.user_situation !== "I am an adolescent who stutters" &&
        step === 7
      )
        setStep(step - 2);
      else setStep(step - 1);
    }
  };

  /********** handle name********** */
  const handleYourName = (key, e) => {
    var response = containsNumbersAndSymbols(e.target.value);
    if (response) {
      if (key === "first_name") {
        setEmptyCheck({
          first_name: "Numbers and symbols are not allowed",
          last_name: "",
        });
        setNameValidation({ ...nameValidation, firstNameValidation: false });
      }
      if (key === "last_name") {
        setEmptyCheck({
          last_name: "Numbers and symbols are not allowed",
          first_name: "",
        });
        setNameValidation({ ...nameValidation, lastNameValidation: false });
      }
    } else {
      if (key === "first_name") {
        setEmptyCheck({ first_name: "" });
        setNameValidation({ ...nameValidation, firstNameValidation: true });
      } else {
        setEmptyCheck({
          last_name: "",
        });
        setNameValidation({ ...nameValidation, lastNameValidation: true });
      }
    }
    setValues({ ...values, [key]: e.target.value });
  };

  // useEffect(() => {
  //   // console.log(nameValidation)
  //   console.log(values)
  // }, [nameValidation])

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  //Function to handle clinician slection in clinician section*****************************
  const selectionClinician = (clinicainName) => {
    setValues({ ...values, ["audio_preference"]: clinicainName });
  };

  const handleSuperHeroSelection = (superHeroname) => {
    setValues((values) => {
      return { ...values, ["superherosect"]: superHeroname };
    });
  };

  const locationHandleChange = (name) => (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");

    setValues({ ...values, [name]: result });
  };

  const selectChange = (name, value) => (e) => {
    setValues({ ...values, [name]: value.value });
  };

  const num =
    values?.user_situation === "I am an adolescent who stutters"
      ? step * 11.11
      : step * 12.5;

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: "#FF2305",
    height: "7px",
  });

  /*******   Hooks  *******/
  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGE,
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        message ===
        "Successfully Registered. You will be redirected to dashboard."
      ) {
        navigate("/intro/welcome");
      }
    }, 1000);
  }, [message]);

  /*******   Render  *******/
  return (
    <div
      className={
        step === 6 || step === 7
          ? "superhero-Cinicain-wrap"
          : "d-flex align-items-center  justify-content-center login-container-wrap"
      }
    >
      {/* registrationCotainer */}
      <Helmet>
        <title>Create new account | iBroadway</title>
      </Helmet>
      <div
        className={
          step === 8
            ? "registrationCotainer"
            : step === 9
            ? "container-fluid"
            : "container"
        }
      >
        <div
          className={step === 8 || step === 9 ? "" : "row loginImage-container"}
        >
          {step != 8 && step != 9 && (
            <div className="col-md-6">
              <div className="text-center">
                <img
                  height="auto"
                  width="450px"
                  src={`${IMAGE_URL}images/login/Untitled-3.png`}
                  alt="Login Group"
                />
              </div>
            </div>
          )}
          <div
            className={
              step === 8 || step === 9
                ? ""
                : "col-md-6 d-flex flex-column justify-content-center"
            }
          >
            {
              {
                1: (
                  <UserAccountInfo
                    handleChange={handleChange}
                    emailChecker={emailChecker}
                    emptyCheck={emptyCheck}
                    values={values}
                  />
                ),

                2: (
                  <UserPasswordInfo
                    handleChange={handleChange}
                    emptyCheck={emptyCheck}
                    values={values}
                  />
                ),
                3: (
                  <UserSituationInfo
                    handleChange={handleChange}
                    selectChange={selectChange}
                    emptyCheck={emptyCheck}
                    values={values}
                    setValues={setValues}
                  />
                ),
                4: (
                  <UserNameInfo
                    handleYourName={handleYourName}
                    emptyCheck={emptyCheck}
                    values={values}
                  />
                ),
                5: (
                  <UserLocationInfo
                    locationHandleChange={locationHandleChange}
                    emptyCheck={emptyCheck}
                    values={values}
                    setValues={setValues}
                  />
                ),
                6: (
                  <UserGuardian
                    handleYourName={handleYourName}
                    emptyCheck={emptyCheck}
                    values={values}
                    setValues={setValues}
                    emailChecker={emailChecker}
                  />
                ),
                7: (
                  <TermsAndConditions
                    setChecked={setChecked}
                    checked={checked}
                    emptyCheck={emptyCheck}
                    values={values}
                  />
                ),
                8: (
                  <UserClinicianInfo
                    selectionClinician={selectionClinician}
                    emptyCheck={emptyCheck}
                    values={values}
                  />
                ),
                9: (
                  <UserSuperHeroInfo
                    values={values}
                    emptyCheck={emptyCheck}
                    handleSuperHeroSelection={handleSuperHeroSelection}
                  />
                ),
              }[step]
            }
            <div
              className={
                step === 8 || step === 9
                  ? "register-footer-btn regbtn-sections text-center"
                  : "register-footer-btn regbtn-sections text-center buttonContainer-medium"
              }
            >
              <div className="row m-0 justify-content-center registraion-button-container">
                {/* <div className="col-md-6"></div> */}
                <div
                  className={
                    step === 8
                      ? "reg-btn-block"
                      : step === 9
                      ? "reg-btn-block-superhero"
                      : "col-md-12"
                  }
                >
                  <div
                    className={
                      step === 8
                        ? "regbtn-block padding-in-button "
                        : step === 9
                        ? " regbtn-block padding-in-button remove-btnPadding"
                        : "regbtn-block"
                    }
                  >
                    {step > 1 ? (
                      <button
                        onClick={prevStep}
                        className="btn  prevbtn btn-block  btn-sm"
                        style={{
                          border: "1px solid #DDD",
                          backgroundColor: "#FFF",
                          fontSize: "20px",
                          lineHeight: "21px",
                          height: "53px",
                          width: "134px",
                          color: "#000000",
                        }}
                      >
                        Go Back
                      </button>
                    ) : null}
                    <button
                      onClick={nextStep}
                      className={
                        step > 1
                          ? " btn nextbtn btn-block btn-sm border-0 nextbtn-styleForWidth"
                          : "btn nextbtn btn-block btn-sm border-0"
                      }
                      style={{
                        height: "53px",
                        fontSize: "20px",
                        lineHeight: "21px",
                      }}
                    >
                      {step === 9 ? "Submit" : "Continue"}
                    </button>
                  </div>
                  <div className="card-body progress-barblock mt-3">
                    <div className="progress" style={{ height: "7px" }}>
                      <div
                        className="progress-bar"
                        style={changePasswordColor()}
                      ></div>
                    </div>
                  </div>
                  <div
                    className=" form-group login-sign-up text-center signuptext align-item-top d-flex flex-row justify-content-center"
                    style={{ gap: "6px", marginTop: "13px" }}
                  >
                    <Link to="/">Login</Link>
                    <span className="btn-seprator">|</span>
                    <Link to="/reset-password" className="input-label-style">
                      Forgot Password
                    </Link>
                  </div>
                  {message && (
                    <div className="form-group px-3 pt-3">
                      <div
                        className={
                          message ===
                          "Successfully Registered. You will be redirected to dashboard."
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
