import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const ContentPage = ({ title, content, link, previousLink }) => {
  /*******  Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    navigate(`/${previousLink}`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******  Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style margin-bottom-0">
                    {title}
                  </div>
                  <div className="card-body">
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentPage;
