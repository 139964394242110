import axios from "axios";
import { BASE_URL } from '../env/Baseurl';

const RefreshToken = async (loggedInUserId) => {


    const user = JSON.parse(localStorage.getItem('user'));
    
        try{
            const params = JSON.stringify({
                "_id": loggedInUserId,
            });    
            const response = await axios.post(BASE_URL + "refresh_token", params, {
                "headers": {
                "content-type": "application/json",
                },   
            })
            const refresh_token = { ...user, token : response.data.token }
            localStorage.setItem("user", JSON.stringify(refresh_token));
        } catch(error){
            
        }     
    
  };

export default RefreshToken;