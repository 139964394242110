import React, { useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../../../env/Baseurl';
import ChallengeAnswers from '../templates/Answers/ChallengeAnswers';
import { GetChallengeResponse } from '../../../../services/part2/get-challenge-response';
import ModuleCompletionEmails from '../../../../services/module-completion-emails';

const ChallengeResponse5 = () => {

    const userInfo = localStorage.getItem('user');                               // Logged in user params
    const user = JSON.parse(userInfo);

    const [challengeResponse, errorMessage] = GetChallengeResponse('5');         // User Response from API

    /*******   Page Params  *******/

    const title = "Challenge My Thinking : Exercise 5 - Review";

    const content = "lorem ipsum";

    /*******   Hooks  *******/

    useEffect(() => {

        ModuleCompletionEmails('sendmailPart2');

    }, []);

    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge 5 - Your Answers | ASRC iBroadway</title>
            </Helmet>
            <ChallengeAnswers
                title={title}
                content={content}
                responses={challengeResponse}
                // link='part_3/intro'
                link='part_2/complete'
                error={errorMessage}
                previousLink='part_2/challenge_5'
                thoughtNumber="fifth"
            />
        </div>
    );
};

export default ChallengeResponse5;