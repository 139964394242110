import { useEffect, useState } from 'react';
import axios from "axios";

import { BASE_URL } from '../env/Baseurl';

const CheckConnection = () => {

    const [checkApiConnection, setCheckApiConnection] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);


    const fetchCheckConnection = async () => {
        try {
            const response = await axios.get(BASE_URL + 'start_status', {
            })
            setCheckApiConnection(response);
        } catch (error) {
            setErrorMessage('There is some error, please try again later.');
        }
    };

    useEffect(() => {
        fetchCheckConnection();
    }, []);

    return [checkApiConnection, errorMessage];
};

export {
    CheckConnection,
}