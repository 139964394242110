import "../../../../../styles/adminlte.min.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../../actions/types";
import { BASE_URL, IMAGE_URL } from "../../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../../env/Baseurl";
import ErrorLogging from "../../../../../services/error-logs";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import Header from "../../../../global/Header";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../../services/refresh-token";
import Sidebar from "../../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const InputForms = ({
  title,
  content,
  questions,
  link,
  type,
  images,
  situation,
  sitnum,
  error,
  previousLink,
  tip,
  situation_image,
}) => {
  /*******   States *******/
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [isPlaying, setIsPlaying] = useState(true);

  // save as draft modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/; // Regex to check for only special characters in textbox

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;

    if (state.data.some((item) => item.thinkingExcercise_id === inputId)) {
      // Checking Input ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.thinkingExcercise_id === inputId) {
          return {
            ...item,
            thinkingExcercise_id: inputId,
            userResponce: value,
          }; // Updating user response in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { thinkingExcercise_id: inputId, userResponce: value },
        ], // Adding new user response in state
      }));
    }

    if (value !== "" || value === "") {
      // Setting Tooltip message for empty response or regex not matched
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage:
              "Please submit all the challenge responses to continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);

      if (state.data.length >= questions.count) {
        handleAudioStoreData();
        navigate(`/${link}`, { replace: true });
      } else {
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 1 Input forms Api");
        ErrorLogging("HTTP POST Part 1 /_type/userId" + error);
        handle();
      }
    }
  };

  // SAVE AS DRAFT

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Please check Part 1 Input forms Api");
        ErrorLogging("HTTP POST Part 1 /_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/${previousLink}`, { replace: true });
  };
  // close modal on its own

  setTimeout(handleClose, 1000);

  /*******   Hooks  *******/

  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.userResponce === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.userResponce))
    );

    if (
      state.data.length >= questions?.count &&
      arr.length === 0 &&
      arr1.length === 0
    )
      setDisabled(false);
    else setDisabled(true);
  }, [state.data]);

  useEffect(() => {
    if (questions?.count === 0) {
      setDisabled(false);
    }
  }, [questions]);

  // // Print Function

  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      questions !== undefined &&
      questions.length !== 0 &&
      questions.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0  thinking-exercise">
                  <div className="title page-title-style m-0">{title}</div>
                  <div className="content-topheads-text">
                    <div className="card-body">
                      {content && <p>{content} </p>}
                      <div className="situation-box">
                        <p className="component-sub-heading-style">
                          Situation: {sitnum}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{ __html: situation }}
                        ></div>
                      </div>
                      {situation_image && (
                        <div className="main-container my-5">
                          <div className=" sub-container center-align">
                            <div className="image-container">
                              <img
                                src={`${IMAGE_URL}images/part1/${situation_image}`}
                                alt="hannah"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {error ? (
                    <div className="container mt-5">
                      <div className="row justify-content-center">
                        <div
                          className="alert alert-danger text-center"
                          role="alert"
                        >
                          {error}
                        </div>
                      </div>
                    </div>
                  ) : questions !== undefined && questions.length !== 0 ? (
                    <div className="content-wrap">
                      <div className="content-topheads-text">
                        <div className="left-content">
                          <div className="row m-0 px-3">
                            {questions?.data?.map((item, index) => {
                              const card = (
                                <div
                                  className="col-xl-6 mb-5"
                                  width="40%"
                                  key={index}
                                >
                                  <div className="question-container">
                                    <p className="questions component-heading-style">
                                      {index + 1 + "." + " " + item?.question}
                                    </p>
                                  </div>
                                  <div className="main-container my-5">
                                    <div className=" sub-container center-align">
                                      <div className="image-container">
                                        <img
                                          src={`${IMAGE_URL}images/part1/${images[index]}`}
                                          alt="Ben"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="textbox">
                                    <textarea
                                      readOnly={item.disable_status}
                                      className="form-control"
                                      id="ControlTextarea"
                                      rows="2"
                                      placeholder="Your Answer Here..."
                                      defaultValue={item.responce}
                                      onChange={handleChange(item._id)}
                                    />
                                  </div>
                                  {textBoxValidation.id === item._id && (
                                    <small className="text-danger">
                                      {textBoxValidation.errorMessage}
                                    </small>
                                  )}
                                </div>
                              );
                              return card;
                            })}
                            <div
                              dangerouslySetInnerHTML={{ __html: tip }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="loader-icon">
                      <TailSpin color="#4f5962" height={50} width={50} />
                    </div>
                  )}
                  <div>
                                    <FooterOfPrint />
                                </div>
                </div>
              </div>

              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {questions !== undefined &&
                  questions.length !== 0 &&
                  questions.global_disable_status === false ? (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0 mr-1"
                        onClick={saveDraft}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {questions !== undefined &&
                  questions.length !== 0 &&
                  questions.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? textBoxValidation.disabledMessage
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* save as draft modal */}

          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body> {successMessage}</Modal.Body>
            ) : (
              <Modal.Body> {errorMessage}</Modal.Body>
            )}
          </Modal>

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InputForms;
