import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeExercise from '../templates/InputForms/ChallengeExercise';
import { GetChallengeQuestions } from '../../../../services/part2/get-challenge-forms';

const Challenge3 = () => {

    const [challengeQuestions, errorMessage] = GetChallengeQuestions('challange_show3');          // Input Questions list from API

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);


    /*******   Page Params  *******/

    // const title = "Challenge 3";
    const title = "Challenge My Thinking - Exercise 3";


    // const content = "Two down, three to go. You are almost half way there!";
    const content = `Two down, two to go.You're doing well ${user?.userInfo?.Fname}`;


    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge My Thinking - Exercise 3 | ASRC iBroadway</title>
            </Helmet>
            <ChallengeExercise
                title={title}
                content={content}
                questions={challengeQuestions}
                type={'create_chall3'}
                link='part_2/challenge_3_sample_answers'
                // subtitle={`Two down, two to go. You're doing well ${user?.userInfo?.Fname}`}
                subtitle=""
                error={errorMessage}
                previousLink='part_2/challenge_2_sample_answers'
                thoughNumber="third"
            />
        </div>
    );
};

export default Challenge3;