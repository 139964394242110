import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import audio1 from "../../../../Audio/attentionHandout1.mp3";
import audio2 from "../../../../Audio/rescript.mp3";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";

const Handout6 = () => {
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Handout - Attention Training and Image Re-scripting</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-10 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body mb-0 p-0">
                <div className="title page-title-style mb-0">
                  Handout - Attention Training and Image Re-scripting
                </div>
                <div className="card-body">
                  <p>
                    You can download the attentional exercise here again.
                    Continuing to use these exercises will train your brain to
                    be better at focusing your attention on the things that are
                    more helpful in life.
                  </p>
                  <p>
                    <a
                      href={
                        {
                          ross: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZoneHomedownload.mp3",
                          margot:
                            "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZoneHomedownloadmargot.mp3",
                          tony: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/ZonehomedownloadAnthony.mp3",
                        }[`${user?.userInfo?.audio_preference}`]
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Attentional exercise.
                    </a>
                  </p>
                  <p>
                    You can also download the re-scripting exercise. You can
                    continue to use this for new images that may pop into your
                    head and need to be UPDATED.
                  </p>
                  <p>
                    <a
                      href={
                        {
                          ross: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/rescriptingdownloadgoeswithUF100.mp3",
                          margot:
                            "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/rescriptindownloadgoeswithUF100margot.mp3",
                          tony: "https://ibroadwayfe.ebizonstaging.com/public/audio/clinician/default/rescriptingUF100mod6Anthony.mp3",
                        }[`${user?.userInfo?.audio_preference}`]
                      }
                      rel="noreferrer"
                    >
                      Re-scripting exercise.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <FooterOfPrint />
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout6;
