import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAvoidanceUtbasQuestionnaire } from "../../../../services/part3/get-avoidance-utbas-questionnaire";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const YourModel = () => {
  const [avoidanceQuestionnaire, avoidanceErrorMessage] =
    GetAvoidanceUtbasQuestionnaire("Avoidance"); // Get Avoidance Questionnaire from api
  console.log(avoidanceQuestionnaire, "its acoidenace quesion");
  const [utbasQuestionnaire, utbasErrorMessage] =
    GetAvoidanceUtbasQuestionnaire("UTBAS"); // Get UTBAS Questionnaire from api

  /*******   States *******/

  const [avoidanceState, setAvoidanceState] = useState({});
  const [utbasState, setutbasState] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [showMore, setShowMore] = useState({
    avoidance: false,
    utbas: false,
  });

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  const numberOfAvoidance = showMore.avoidance
    ? avoidanceQuestionnaire.questionnairs.length
    : 10;
  const numberOfUtbas = showMore.utbas
    ? utbasQuestionnaire.questionnairs.length
    : 10;

  /*******   Helper Functions  *******/
  let navigate = useNavigate();

  const handleChangeAvoidance = (e) => {
    const { name, value } = e.target;
    setAvoidanceState({ question_id: value });
  };

  const handleChangeUtbas = (e) => {
    const { name, value } = e.target;
    setutbasState({ question_id: value });
  };

  const handleAvoidanceShowMore = () => {
    setShowMore({
      avoidance: true,
    });
  };

  const handleUtbasShowMore = () => {
    setShowMore({
      utbas: true,
    });
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        question_id: avoidanceState.question_id,
      });
      const response = await axios.post(
        BASE_URL + `create_situation/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }

    try {
      const params = JSON.stringify({
        question_id: utbasState.question_id,
      });
      const response = await axios.post(
        BASE_URL + `create_casual/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 3 Create Casual Api");
        ErrorLogging("HTTP POST Part 3 /create_casual/userId" + error);
        handle();
      }
    }

    if (disabled === false) {
      navigate("/part_3/your_model_of_social_anxiety", { replace: true });
    }
  };

  const previousPage = () => {
    navigate(`/part_3/self-focussed_imagery`, { replace: true });
  };

  /*******   Hooks  *******/

  useEffect(() => {
    if (
      Object.keys(avoidanceState).length === 0 ||
      Object.keys(utbasState).length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [avoidanceState, utbasState]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>My Situation and My Thoughts | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-3">
                    My Situation
                  </div>
                  <div className="card-body">
                    <div>
                      <div id="edit-feared-situation-text">
                        <p className="firstSec">
                          Here is a list of the top 10 situations you find
                          hardest to face, which were taken from the answers you
                          provided in the questionnaires at the start of the
                          program. Please select 1:
                        </p>
                      </div>
                      <div id="edit-feared-situation">
                        {avoidanceErrorMessage ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {avoidanceErrorMessage}
                              </div>
                            </div>
                          </div>
                        ) : avoidanceQuestionnaire !== undefined &&
                          avoidanceQuestionnaire.length !== 0 ? (
                          <>
                            <div className="content-wrap">
                              {avoidanceQuestionnaire.questionnairs
                                .slice(0, numberOfAvoidance)
                                .map((item, index) => {
                                  const card = (
                                    <div
                                      className="left-content checkbx-sty"
                                      key={index}
                                    >
                                      <InputGroup.Checkbox
                                        defaultChecked={item.responce}
                                        disabled={item.disable_status}
                                        className="radio-size"
                                        type="radio"
                                        name="avoidance"
                                        value={item._id}
                                        onChange={handleChangeAvoidance}
                                      />
                                      {item.question}
                                    </div>
                                  );
                                  return card;
                                })}
                            </div>
                            {/* <Button
                              className="loadmoreBtn"
                              onClick={handleAvoidanceShowMore}
                            >
                              {showMore.avoidance ? (
                                ""
                              ) : (
                                <>Show more Feared Situations</>
                              )}
                            </Button> */}
                          </>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 mb-0">
                    <div className="title page-title-style mb-0">
                      My Thoughts
                    </div>
                    <div className="card-body">
                      <div>
                        <div id="edit-reason-for-avoidance-text">
                          <p className="secondSec">
                            Here is a list of the top 10 reasons you gave for
                            avoiding social situations. Which one of the
                            following is the most likely reason for you avoiding
                            this situation? If none of the answers fit, try
                            selecting a different situation above. Please select
                            1:
                          </p>
                        </div>
                        <div id="edit-reason-for-avoidance">
                          {utbasErrorMessage ? (
                            <div className="container mt-5">
                              <div className="row justify-content-center">
                                <div
                                  className="alert alert-danger text-center"
                                  role="alert"
                                >
                                  {utbasErrorMessage}
                                </div>
                              </div>
                            </div>
                          ) : utbasQuestionnaire !== undefined &&
                            utbasQuestionnaire.length !== 0 ? (
                            <>
                              <div className="content-wrap">
                                {utbasQuestionnaire.questionnairs
                                  .slice(0, numberOfUtbas)
                                  .map((item, index) => {
                                    const card = (
                                      <div
                                        className="left-content checkbx-sty"
                                        key={index}
                                      >
                                        <InputGroup.Checkbox
                                          defaultChecked={item.responce}
                                          disabled={item.disable_status}
                                          className="radio-size"
                                          type="radio"
                                          value={item._id}
                                          name="utbas"
                                          onChange={handleChangeUtbas}
                                        />
                                        {item.question}
                                      </div>
                                    );
                                    return card;
                                  })}
                              </div>
                              {/* <Button
                                className="loadmoreBtn"
                                onClick={handleUtbasShowMore}
                              >
                                {showMore.utbas ? (
                                  ""
                                ) : (
                                  <>Show more Reasons for Avoidance</>
                                )}
                              </Button> */}
                            </>
                          ) : (
                            <div className="loader-icon">
                              <TailSpin
                                color="#4f5962"
                                height={50}
                                width={50}
                              />
                            </div>
                          )}
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <span
                data-tip={
                  disabled === true
                    ? "Please select a situation that you avoid."
                    : ""
                }
                data-for="toolTip"
              >
                <div className="row mx-0 bottom-button-container  ">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={postSubmission}
                      disabled={disabled}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </span>
              <ReactTooltip id="toolTip" />
            </div>
          </div>
          {/* error message modal */}
          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default YourModel;
