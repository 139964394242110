import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useNavigate } from "react-router-dom";

const Part6SixthPage = () => {
  /*******   states  *******/
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  const title1 = "The Difference Between Lapse & Relapse";
  const title2 = "REPLAYING MY PAST";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*******   Helper Functions  *******/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_6/complete", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/mental_imagery`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Changing my faulty images | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      s
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title1}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>
                            These false memories, taken from the view of an
                            “external camera”, infect and distort a person’s
                            life. They interfere with a person’s ability to
                            process information that conflicts with their
                            negative self-beliefs. And it’s these negative
                            self-beliefs that are shaped by faulty images and
                            memories. It gets worse.
                          </p>
                          <div className="main-container my-5">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part6/page6sixthPage/268.jpg`}
                                  alt="hannah"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            People troubled by social anxiety use these
                            distorted images and memories as evidence when
                            interacting with people that they’re making a
                            negative impression. Talk about your past wrecking
                            your future.
                          </p>
                          <p>
                            Answer = Rescripting. Humans have the ability to
                            rescript or change the faulty images and memories
                            that are haunting them. This can be a powerful way
                            of preventing future social encounters from becoming
                            contaminated by faulty memories of the past.
                          </p>
                          <p>
                            Rescripting exercise – try rescripting a negative
                            image/memory that you regularly experience. It’s
                            best if you choose a distressing memory involving
                            stuttering. Press the button below to start the
                            rescripting exercise.
                          </p>
                          <h2 className="attText">
                            <a
                              href={
                                {
                                  ross: `${DOMAIN_NAME}public/audio/clinician/default/rescriptingdownloadgoeswithUF100.mp3`,
                                  margot: `${DOMAIN_NAME}public/audio/clinician/default/rescriptindownloadgoeswithUF100margot.mp3`,
                                  tony: `${DOMAIN_NAME}public/audio/clinician/default/rescriptingUF100mod6Anthony.mp3`,
                                }[user?.userInfo?.audio_preference]
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <u>Click here to download the exercise (MP3)</u>
                            </a>
                          </h2>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={currentAudio?.enabledAudio < 1 ? true : false}
                    onClick={() => {
                      if (currentAudio?.enabledAudio < 1) return false;
                      else {
                        setIsPlaying(true);
                        setCurrentAudio({ ...currentAudio, audio: 1 });
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title2}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <div className="row example-container-reverse mt-0 zone-home-technique mb-4">
                            <div className="text-box">
                              <p>
                                Social anxiety makes people keep replaying an
                                image or memory from a past embarrassing social
                                encounter, which is called rumination. People
                                can spend hours or days going over and over an
                                embarrassing social event, ruminating on a small
                                perceived error in their speech or behaviour.
                                They ‘replay’ the film in their mind, and the
                                more they replay them, the more distressing the
                                film in their mind becomes. What’s worse, as
                                you’ve learned in previous pages, these images
                                and memories are likely to be faulty.
                              </p>
                            </div>
                            <div className="image-box">
                              <div className="main-container">
                                <div className="sub-container center-align">
                                  <div className="image-container">
                                    <img
                                      src={`${IMAGE_URL}images/part6/page6sixthPage/269.jpg`}
                                      alt="replaying past"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="normal-text-black">
                            Rumination gets it’s strength by keeping your mind
                            in the past. The moment you place your mind in the
                            present moment, rumination loses. Try giving the
                            task below a go:
                          </p>
                          {/* <div className="main-container my-5">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part6/page6sixthPage/269.jpg`}
                                  alt="hannah"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            Social anxiety makes people keep replaying an image or
                            memory from a past embarrassing social encounter, which is
                            called rumination. People can spend hours or days going
                            over and over an embarrassing social event, ruminating on
                            a small perceived error in their speech or behaviour. They
                            ‘replay’ the film in their mind, and the more they replay
                            them, the more distressing the film in their mind becomes.
                            What’s worse, as you’ve learned in previous pages, these
                            images and memories are likely to be faulty.
                          </p>
                          <p>
                            Rumination gets it’s strength by keeping your mind in the
                            past. The moment you place your mind in the present
                            moment, rumination loses. Try giving the task below a go:
                          </p> */}
                          <div className="main-container my-5">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part6/page6sixthPage/270.png`}
                                  alt="task"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            We also suggest going back over the essay you wrote
                            on “Why it’s important to let go of worrying what
                            others think of me”. Keep up your thought
                            challenging exercises too as they are the key to
                            increasing social confidence.
                          </p>
                          <div className="tip-container">
                            <div className="tip-image-container">
                              <img
                                className="part6_bar_image"
                                src={tipBackground}
                                alt="tip-background"
                                width="923px"
                              />
                            </div>
                            <div className="idea">
                              <img
                                className="part6_bar_image"
                                src={idea}
                                alt="tip"
                              />
                            </div>
                            <div className="tip-content">
                              <span className="whiteText">
                                Always ask yourself, “If I don’t have evidence
                                for a negative causal thought, why believe it?”
                              </span>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row  mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    disabled={
                      audio?.audioData?.length > 0 ? buttonState : false
                    }
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part6SixthPage;
