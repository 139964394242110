import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { GetWhatWasLearned } from "../../../../services/part4/what-was-learned";
import axios from "axios";
import { BASE_URL } from "../../../../env/Baseurl";
import RefreshToken from "../../../../services/refresh-token";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { AUDIO_SUCCESS } from "../../../../actions/types";

const Part4ThirteenthPage = () => {
  const [whatWasLearned, errorMessage] = GetWhatWasLearned();
  const [isPlaying, setIsPlaying] = useState(true);
  // Get Learned Data from api

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const response = await axios.post(
        BASE_URL +
        `page_partUpdate_setto_social_perfection/${user.userInfo._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      handleAudioStoreData();
      navigate("/part_4/perfectionism", { replace: true });
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        nextPage();
      }
    }
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/what_was_learned`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            Let's have another go at the experiment! | ASRC iBroadway
          </title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style">
                    Let's have another go at the experiment!
                  </div>
                  <div className="card-body">
                    <p className="">
                      You have said that you would still avoid{" "}
                      <i className="normal-text-black font-italic dark-purple-color">
                        {whatWasLearned.length !== 0 && (
                          <>{whatWasLearned.the_task_i_was_compleated}</>
                        )}
                      </i>{" "}
                      It usually takes more than one go to master a behavioural
                      experiment. We recommend that you redo your behavioural
                      experiment.
                    </p>
                    <p>
                      You should continue to repeat the experiment until you are
                      no longer anxious about the situation. Don't forget, you
                      will need to drop your safety behaviours when you re-enter
                      the situation.
                    </p>
                    <p>
                      First, however, we think you might benefit by reading the
                      pages on perfectionism and 'approval addiction' again.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row  mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4ThirteenthPage;
