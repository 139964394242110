import React, { useState, useEffect } from "react";
import "../../styles/Slider/slider.css";
import close from "../../assets/GIf/close.svg";

const TopSlider = ({ topCallOut }) => {
  const [isClose, setIsClose] = useState(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  return (
    <>
      {topCallOut !== "" ? (
        <div
          className={`topcallout ${isClose ? "topcallout-hidden" : ""}`}
          id="says"
        >
          <div className="topBanner">
            <h1>{{
              superStrong: 'SUPERSTRONG',
              superBrave: 'SUPERBRAVE',
              superCalm: 'SUPERCALM',
              superFearless: 'SUPERFEARLESS',
              superResilient: 'SUPERRESILIENT',
              superSmart: 'SUPERSMART',
            }[user?.userInfo?.superherosect]} Says</h1>
            <img
              id="close"
              onClick={() => setIsClose(true)}
              className="imageStyle"
              src={close}
              alt="close"
            />
          </div>
          <div className="imageDiv">
            <img className="imageStyle" src={topCallOut} alt="Quote" />
          </div>
        </div>
      ) : null}
    </>
  );
};
export default TopSlider;
