import axios from "axios";

import {
    BASE_URL
} from '../env/Baseurl';

const audio = (pagePath, audio_pre) => {
    // console.log(pagePath, audio_pre, "its name of clinincian")
    // console.log(pagePath, "its page path")
    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    const params = JSON.stringify({
        "page_name": pagePath,
        "auido_preference": audio_pre,
    });


    return axios.post(BASE_URL + `getAudio_By_pageName/${user.userInfo._id}`, params, {
        "headers": {
            "content-type": "application/json",
            "Authorization": `Bearer ${user.token}`,
        },
    })
        .then((response) => {
            // console.log(response.data, "its data audio")
            return response.data;
        });
};

export default audio;