import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAllTyrannyStatementResponse } from "../../../../services/part5/tyranny-statements-response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part5Fifth = () => {
  const [tyrannyShouldsResponse, errorMessage] =
    GetAllTyrannyStatementResponse(); // Get Tyranny Should Response frpm api
  const [isPlaying, setIsPlaying] = useState(true);
  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_5/complete", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_5/advantages_and_disadvantages_of_shoulds`, {
      replace: true,
    });
  };

  /*******   Hooks  *******/
  useEffect(() => {
    ModuleCompletionEmails("sendmailPart5");
  }, []);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Reviewing My Shoulds | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div className="title page-title-style mb-0">
                  Reviewing My Shoulds
                </div>
                <div id="main">
                  <div className="card-body">
                    <p className="">
                      Now compare your answers to our sample answers!
                    </p>
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : tyrannyShouldsResponse !== undefined &&
                      tyrannyShouldsResponse.length !== 0 ? (
                      <div className="content-wrap">
                        <div className="content-topheads-text">
                          <div className="thinkingEx">
                            {tyrannyShouldsResponse.responce.map(
                              (item, index) => {
                                const card = (
                                  <div className="sampleAnswers" key={index}>
                                    <p className="my-4 component-sub-heading-style  text-center">
                                      {item.question_id.question}
                                    </p>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="text-center">
                                              <td className="component-sub-heading-style text-center">
                                                Your answer
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="questions">
                                              <td>
                                                Advantages
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                {item.advantage_userresponce}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions mt-3 mb-3">
                                              <td>
                                                Disadvantages
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                {item.disadvantage_userresponce}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td className="component-sub-heading-style text-center">
                                                Sample answer
                                              </td>
                                            </tr>
                                            <tr className="questions">
                                              <td>Advantages</td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                <div>
                                                  {item.question_id.advantage}
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>Disadvantages</td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                <div>
                                                  {
                                                    item.question_id
                                                      .disadvantage
                                                  }
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                                return card;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>

                <div className="row  mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={nextPage}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Fifth;
