import CheckboxSituations from "../templates/CheckboxSituations";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import { GetCheckboxes } from "../../../../services/part3/get-checkboxes";
import { Helmet } from "react-helmet";
import React from "react";

const SafetyBehaviours = () => {
  const [checkboxes, errorMessage] = GetCheckboxes("behaviour");
  // Checkboxes list from API
  /*******   Page Params  *******/
  const title = "Making My Model";
  const imageTitle = `Here is Ben’s own model with his 'safety behaviours' highlighted BLUE`;
  const subtitle = "Safety behaviours";
  const body =
    "<p>In order to make your own model like Ben’s, you’re going to need to choose some items from the following lists. It worth doing as your model will look pretty cool when it’s finished. Choose answers that honestly relate to your life.</p>";
  const checkboxQuestion =
    "Pick as many as you can of the safety behaviours you use when feeling anxious in social situations:";
  const link = "/part_3/physiological_anxiety_symptoms";
  const validationMessage = "Please select at least one Safety Behaviour.";
  const imageSrc = `${IMAGE_URL}images/part3/graphs/Social2.jpg`;

  /*******   Render  *******/
  return (
    <>
      <Helmet>
        <title> Making My Model | ASRC iBroadway</title>
      </Helmet>
      <CheckboxSituations
        title={title}
        imageTitle={imageTitle}
        subtitle={subtitle}
        body={body}
        checkboxQuestion={checkboxQuestion}
        link={link}
        checkboxeslist={checkboxes}
        type={"create_behiviour"}
        validationMessage={validationMessage}
        imageSrc={imageSrc}
        error={errorMessage}
        previousLink="part_3/bens_model_2"
      />
    </>
  );
};

export default SafetyBehaviours;
