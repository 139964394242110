import React from "react";
import '../../styles/lastModuleBadgeStyle/lastModuleBadgeStyle.css'
import ProgressBar from "@ramonak/react-progress-bar";

const LastModuleBadge = ({ currentBadge, nextBadge, content, progressBarData, trophy, totalPoints }) => {
    return (
        <div className="row badgeContainer" >
            <div className="row visualContainer">
                <div className="logo">
                    <img src={currentBadge}></img>
                </div>
                <div className="trophy">
                    <img src={trophy}></img>
                    {/* <video width="610" height="302" autoplay controls loop >
                        <source src={trophy} type="video/mp4" />
                    </video> */}
                </div>
                <div className="text">
                    {content}
                </div>
            </div>
            <div className={nextBadge ? "progressBar row" : "progressBar row progressBarMarginTop"} >
                <div className={nextBadge ? "col-12  col-lg-10 progerssPoints" : "col-12 progressPoints"}>
                    <ProgressBar completed={progressBarData} bgColor='#060' isLabelVisible={false} />
                    <p> You Earned<span className="component-sub-heading-style"> - {totalPoints} Points</span></p>
                </div>
                {nextBadge &&
                    <div className="col-12 col-lg-2 logo">
                        <img src={nextBadge}></img>
                        <p><span > Next Badge</span></p>
                    </div>
                }
            </div>
        </div>
    )
}

export default LastModuleBadge;