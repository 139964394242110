import "../../styles/dashboard-style.css";
import React from "react";
import moment from "moment";

const FooterOfPrint = () => {
  const styles = {
    footerContainer: {
      display: "none",
      position: "fixed",
      bottom: "-17px",
      minWidth: "100%",
      zIndex: 9999999,
    },
  };

  return (
    <>
      <div id="printFooter" style={styles.footerContainer}>
        <div
          style={{
            width: "100%",
            marginBottom: "2px",
            borderTop: "1px solid #ccc",
          }}
        ></div>
        <p className="print-footer-style">
          Copyright @ {moment().format("YYYY")} iBroadway Program Australian
          Stuttering Research Centre {moment().format("DD/MM/YY")}
        </p>
      </div>
    </>
  );
};

export default FooterOfPrint;
