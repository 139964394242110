import React, { useEffect, useState } from "react";
import Select from "react-select";

import { DOMAIN_NAME } from "../../env/Baseurl";
import { red } from "@mui/material/colors";
import { selectStyle } from "../../constants/global/StyleObject";

/*******   Constants  *******/

const options = [
  {
    value: "I am an adolescent who stutters",
    label: "I am an adolescent who stutters",
  },
  {
    value:
      " I am a health professional involved in the treatment of people who stutter",
    label:
      " I am a health professional involved in the treatment of people who stutter",
  },
  {
    value: "I am a family member or friend of a person who stutters",
    label: "I am a family member or friend of a person who stutters",
  },
  // { value: 'I am a research participant', label: 'I am a research participant' }
];

const options2 = [
  {
    value: "I am actively interested in completing the program",
    label: "I am actively interested in completing the program",
  },
  {
    value: "I want to find out more about iBroadway",
    label: "I want to find out more about iBroadway",
  },
  {
    value: "I am a research participant",
    label: "I am a research participant",
  },
];

const options3 = [
  {
    value: 11,
    label: 11,
  },
  {
    value: 12,
    label: 12,
  },
  {
    value: 13,
    label: 13,
  },
  {
    value: 14,
    label: 14,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 16,
    label: 16,
  },
  {
    value: 17,
    label: 17,
  },
];

const UserSituationInfo = ({
  handleChange,
  selectChange,
  emptyCheck,
  values,
  setValues,
}) => {
  // console.log(values);

  //  styling*******************************
  const style = {
    placeHolderStyle: {
      fontSize: "14px",
      color: "#929BA2",
    },
  };

  /*******   States  *******/
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionSecond, setSelectedOptionSecond] = useState(null);
  const [selectedOptionThird, setSelectedOptionThird] = useState(null);

  // useEffect(() => {
  //     console.log("Its paricipatant value ", values.participant_options)
  // }, [participantOptionsValue])

  /*******   HelperFunctions  *******/
  const selectHandler = (e) => {
    // console.log(e.value);
    setSelectedOption(e.value);
    if (e.value === "I am an adolescent who stutters") {
      setValues({
        ...values,
        ["user_situation"]: e.value,
        ["applied_situation"]: "",
      });
    } else {
      setValues({
        ...values,
        ["user_situation"]: e.value,
        ["applied_situation"]: "none",
      });
    }
  };

  const selectHandler2 = (e) => {
    setSelectedOptionSecond(e.value);
    setValues({ ...values, ["applied_situation"]: e.value });
  };

  const selectHandler3 = (e) => {
    setSelectedOptionThird(e.value);
    setValues({ ...values, ["age"]: e.value });
  };
  //   const selectStyle = {
  //     control: (base, state) => ({
  //       ...base,
  //       border: state.isFocused ? "1px solid #6A3851 !important" : 0,
  //       // This line disable the blue border
  //       boxShadow: "none",
  //     }),
  //   };

  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row align-items-center login-col-wrap m-0 justify-content-center">
        {/* <div className="col-md-6">
                    <div className="text-center">
                        <img
                            height="auto"
                            width="450px"
                            src={`${IMAGE_URL}images/login/login8464.png`}
                            alt="Login Group"
                        />
                    </div>
                </div> */}
        <div className="col-md-12">
          <div>
            <h3 className="text-center pagehead-texttitle">Your Situation</h3>
            <form>
              <div className="card-body usersituation-body">
                {/* <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                        Research Participant
                                    </label>
                                    <Select
                                        placeholder="Choose an Option"
                                        defaultValue=
                                        {values.participant_options === "" ?
                                            participantOptionsValue
                                            :
                                            { label: values.participant_options, value: values.participant_options }

                                        }
                                        options={researchParticipantOptions}
                                        onChange={e => { selectHandlerParticipantOptions(e) }}
                                    />
                                    {values.participant_options === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.participant_options}
                                        </small>
                                    )}
                                </div> */}
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    My Situation
                  </label>
                  <Select
                    className="input-label-style select-input-box"
                    styles={selectStyle}
                    // placeholder="Choose an Option"
                    placeholder={
                      <div style={style.placeHolderStyle}>Choose an Option</div>
                    }
                    defaultValue={
                      values.user_situation === ""
                        ? selectedOption
                        : {
                            label: values.user_situation,
                            value: values.user_situation,
                          }
                    }
                    options={options}
                    onChange={(e) => {
                      selectHandler(e);
                    }}
                    classNamePrefix="select"
                    // isSearchable={false}
                  />
                  {values.user_situation === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.user_situation}
                    </small>
                  )}
                </div>
                {selectedOption === "I am an adolescent who stutters" ||
                values.user_situation === "I am an adolescent who stutters" ? (
                  <div className="input-label-style">
                    <div className="form-group">
                      <p>
                        We are interested to know a bit more about you. You will
                        have full access to the iBroadway program regardless of
                        your response to the question below
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Which one of the following statement better applies to
                        you?
                      </label>
                      <Select
                        className="input-label-style select-input-box"
                        styles={selectStyle}
                        placeholder="Choose an Option"
                        defaultValue={
                          values.applied_situation === ""
                            ? selectedOptionSecond
                            : {
                                label: values.applied_situation,
                                value: values.applied_situation,
                              }
                        }
                        options={options2}
                        onChange={(e) => {
                          selectHandler2(e);
                        }}
                        // isSearchable={false}
                      />
                      {values.applied_situation === "" && (
                        <small className="text-danger input-label-style error-message-style">
                          {emptyCheck.applied_situation}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        How old are you?
                      </label>
                      <Select
                        className="input-label-style select-input-box"
                        styles={selectStyle}
                        placeholder="Choose your Age"
                        defaultValue={
                          values.age === ""
                            ? selectedOptionThird
                            : {
                                label: values.age,
                                value: values.age,
                              }
                        }
                        options={options3}
                        onChange={(e) => {
                          selectHandler3(e);
                        }}
                        // isSearchable={false}
                      />
                      {values.age === "" && (
                        <small className="text-danger input-label-style error-message-style">
                          {emptyCheck.age}
                        </small>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSituationInfo;
