import React from 'react';
import { Helmet } from 'react-helmet';
import ExcerciseAnswersHandout from '../templates/ExcerciseAnswersHandout';
import { GetUserResponse } from '../../../../services/part1/get-user-response';

const Handout1 = () => {

    const [userResponse, errorMessage] = GetUserResponse(1);

    const title = "Handout - Thinking Exercises";

    const content = "Please compare your answers to the sample answers given for thoughts that Goran may have had that led him to feel anxious, angry, sad, or happy.";

    return (
        <div>
            <Helmet>
                <title>Handout - Thinking Exercises | ASRC iBroadway</title>
            </Helmet>
            <ExcerciseAnswersHandout
                title={title}
                content={content}
                responses={userResponse}
                link='part_1/thinking_exercise_2'
                type={'ThinkingResponse1'}
                sitnum={1}
                error={errorMessage}
            />
        </div>
    );

};

export default Handout1;
