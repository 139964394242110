import ContentPage from "../templates/ContentPages";
import { Helmet } from "react-helmet";
import React from "react";

const Part7SecondPage = () => {
  /*******  Page Params  *******/

  const title = "Relapse Prevention";

  const content =
    "<div><p class='component-sub-heading-style'>When will I be more vulnerable to having a RELAPSE, and how can I protect myself?</p><p>People tend to be more vulnerable to having a relapse when they are under increased stress or when something distressing occurs in their life. 'Stressful' situations can be either major life events (such as losing a job) or a minor thing (such as sleeping poorly or working too hard). These 'minor' things can become a problem if they persist over a period of time (for example if you sleep poorly for a period of a few weeks).</p><p>You should also watch out for other types of anxiety (such as exam anxiety). We know that if you become anxious about something completely unrelated to social performance, it will put you at a risk of having a lapse in your social anxiety.</p><p>Because we know that these types of situations are more likely to lead to a lapse, it makes sense to anticipate them, where possible, and to plan the best way to cope with them.</p></div>";

  /*******  Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Relapse Prevention | ASRC iBroadway</title>
      </Helmet>
      <ContentPage
        title={title}
        content={content}
        link="part_7/postevent_rumination"
        previousLink="part_7/preventing_relapse"
      />
    </div>
  );
};

export default Part7SecondPage;
