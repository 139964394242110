import React, { useDebugValue, useEffect, useState } from "react";

import { DOMAIN_NAME } from "../../env/Baseurl";
import Select from "react-select";
import { countries_list } from "../../constants/countrieList/CountriesList";
import { selectStyle } from "../../constants/global/StyleObject";

const UserLocationInfo = ({
  locationHandleChange,
  emptyCheck,
  values,
  setValues,
}) => {
  //  styling*******************************
  const style = {
    placeHolderStyle: {
      fontSize: "14px",
      color: "#929BA2",
    },
  };

  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");

  // Country list object*********************

  const selectHandleCountry = (e) => {
    setCountry(e.value);
    setCountryCode(e.code);
    setDialCode(e.dial_code);
    setValues({
      ...values,
      ["country"]: e.value,
      ["countryCode"]: e.code,
      ["dialCode"]: e.dial_code,
    });
  };


  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row align-items-center login-col-wrap m-0 justify-content-center">
        <div className="col-md-12 ">
          <div>
            <h3 className="text-center pagehead-texttitle">Your Location</h3>
            <form>
              <div className="card-body">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Country
                  </label>
                  {/* <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter Country Name"
                                        onChange={locationHandleChange("country")}
                                        value={values.country}
                                    /> */}
                  <Select
                    className="input-label-style select-input-box"
                    styles={selectStyle}
                    // placeholder="Choose Your Country"
                    placeholder={
                      <div style={style.placeHolderStyle}>
                        Choose Your Country
                      </div>
                    }
                    defaultValue={
                      values.country === ""
                        ? country
                        : { label: values.country, value: values.country }
                    }
                    options={countries_list}
                    onChange={(e) => {
                      selectHandleCountry(e);
                    }}
                    // isSearchable={false}
                  />
                  {values.country === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.country}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="input-label-style"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter City Name(Optional)"
                    onChange={locationHandleChange("city")}
                    value={values.city}
                  />
                  {/* {values.city === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.city}
                                        </small>
                                    )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLocationInfo;
