import React, { useState } from "react";

import { DOMAIN_NAME } from "../../env/Baseurl";

const UserGuardian = ({ handleYourName, emptyCheck, values, emailChecker }) => {
  /*******   Render  *******/
  return (
    <div className="userInfoContainer">
      <div className="row align-items-center login-col-wrap justify-content-center m-0">
        <div className="col-md-12">
          <div>
            <h3 className="text-center pagehead-texttitle">
              PARENT, CARER OR GUARDIAN
            </h3>
            <form>
              <div className="card-body">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="input-label-style"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    defaultValue={values.guardian_first_name}
                    placeholder="Enter your First Name"
                    onChange={(e) => handleYourName("guardian_first_name", e)}
                    // onChange={(e) => handleInput(e, "first_name")}
                  />
                  {values.guardian_first_name === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.guardian_first_name}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    defaultValue={values.guardian_last_name}
                    placeholder="Enter your Last Name"
                    onChange={(e) => handleYourName("guardian_last_name", e)}
                    // onChange={(e) => handleInput(e, "last_name")}
                  />
                  {values.guardian_last_name === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.guardian_last_name}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    defaultValue={values.guardian_email}
                    placeholder="Enter your Email"
                    onChange={(e) => handleYourName("guardian_email", e)}
                    onKeyUp={() => emailChecker()}
                  />
                  <small className="text-danger input-label-style error-message-style">
                    {emptyCheck.guardian_email}
                  </small>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="input-label-style"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    defaultValue={values.guardian_mobile}
                    placeholder="Enter your Mobile Number"
                    onChange={(e) => handleYourName("guardian_mobile", e)}
                  />
                  <small className="text-danger input-label-style error-message-style">
                    {emptyCheck.guardian_mobile}
                  </small>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGuardian;
