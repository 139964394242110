import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7thirdpage/part7thirdpage.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const Part7ThirdPage = () => {
  /*******  states *******/
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  /*******   params *******/
  const title1 = "The Difference Between Lapse & Relapse";
  const title2 = " Things that can cause lapses";
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);
  /**********functions*******/

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_7/ways_to_protect_yourself`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_7/preventing_relapse_2`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******  Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>The Difference Between Lapse & Relapse | ASRC iBroadway</title>
      </Helmet>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      s
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title1}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>
                            A LAPSE is a minor setback. Lapses can be
                            frustrating, but very normal. A RELAPSE is where a
                            person goes right back to the start again.
                          </p>
                          <div className="part7-third-main-container my-5">
                            <div className="image-third-container">
                              <img
                                src={`${IMAGE_URL}images/part7/part7thirdPage/282.jpg`}
                                alt="situations"
                              ></img>
                              <p>James was out of shape of wanted to get fit</p>
                            </div>
                            <div className="image-third-container">
                              <img
                                src={`${IMAGE_URL}images/part7/part7thirdPage/283.jpg`}
                                alt="situations"
                              ></img>
                              <p>So he started running in the mornings.</p>
                            </div>
                            <div className="image-third-container">
                              <img
                                src={`${IMAGE_URL}images/part7/part7thirdPage/278.jpg`}
                                alt="situations"
                              ></img>
                              <p>
                                {" "}
                                He'd been running each morning for a week, but
                                on the 8th day James couldn't be bothered and
                                stayed in bed.
                              </p>
                            </div>
                            <div className="image-third-container">
                              <img
                                src={`${IMAGE_URL}images/part7/part7thirdPage/283.jpg`}
                                alt="situations"
                              ></img>
                              <p>
                                {" "}
                                But James ran again the next day. This is a
                                lapse.
                              </p>
                            </div>
                            <div className="image-third-container">
                              <img
                                src={`${IMAGE_URL}images/part7/part7thirdPage/284.jpg`}
                                alt="situations"
                              ></img>
                              <p>
                                {" "}
                                Now imagine that because of the 8th day, James
                                believed he'd ruined his chances of being fit
                                and didn't run again for 6 months. This would be
                                a relapse back to poor fitness.
                              </p>
                            </div>
                          </div>

                          <p>
                            These lapses or minor setbacks aren’t taking you
                            back to the start. When people over-react by
                            treating a small setback as a disaster, it can
                            increase anxiety and lower social confidence, and
                            risk them giving up altogether.
                          </p>
                          <p>
                            Lapses with social anxiety are normal. Only relapses
                            take you back to the start.
                          </p>
                          <p>
                            Knowing what can cause these lapses is vital, as
                            explained on the next page.
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={currentAudio?.enabledAudio < 1 ? true : false}
                    onClick={() => {
                      if (currentAudio?.enabledAudio < 1) return false;
                      else {
                        setIsPlaying(true);
                        setCurrentAudio({ ...currentAudio, audio: 1 });
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title2}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>
                            Life stress impacts us all differently. People tend
                            to be more vulnerable to having a lapse when they’re
                            under increased stress or when something distressing
                            occurs in their life. Stressful situations can be
                            either major or minor life events.
                          </p>
                          <div className="main-container my-4">
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part7/part7thirdPage/280.png`}
                                  alt="Major"
                                />
                              </div>
                            </div>
                            <div className="container">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part7/part7thirdPage/281.png`}
                                  alt="minor"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            On the next page we’ll look at how to protect
                            against stress.
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    disabled={
                      audio?.audioData?.length > 0 ? buttonState : false
                    }
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part7ThirdPage;
