import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const GetBehaviouralCausalThought =  () => {
    
    const [behaviouralCausalThought, setBehaviouralCausalThought] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');


    const fetchBehaviouralCausalThought = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `behavioural_causal_thought_page/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setBehaviouralCausalThought(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchBehaviouralCausalThought();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /behavioural_causal_thought_page/userId' + error);
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /behavioural_causal_thought_page/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchBehaviouralCausalThought();
    }, []);
  
  return [behaviouralCausalThought, errorMessage];
};


const GetBehaviouralThoughtAnswers =  () => {
    
    const [behaviouralCausalAnswers, setBehaviouralCausalAnswers] = useState([]);
    const [errorAnswerMessage, setAnswerErrorMessage] = useState('');

    const fetchBehaviouralThoughtAnswers = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `behavioural_causal_thought_Sample_Answers/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
            setBehaviouralCausalAnswers(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchBehaviouralThoughtAnswers();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setAnswerErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /behavioural_causal_thought_Sample_Answers/userId' + error);
            }
            else{
                setAnswerErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /behavioural_causal_thought_Sample_Answers/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchBehaviouralThoughtAnswers();
    }, []);
  
  return [behaviouralCausalAnswers, errorAnswerMessage];
};

export {
    GetBehaviouralCausalThought,
    GetBehaviouralThoughtAnswers
}