import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetBehaviouralThoughtAnswers } from "../../../../services/part4/behavioural-causal-thought";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4EleventhPage = () => {
  const [behaviouralCausalAnswers, errorAnswerMessage] =
    GetBehaviouralThoughtAnswers(); // Get Behavioural Answers info from api
  const [isPlaying, setIsPlaying] = useState(true);

  /*******   States *******/

  const [disabled, setDisabled] = useState(false);
  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const postSubmission = () => {
    handleAudioStoreData();
    navigate("/part_4/what_was_learned", { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/behavioural_causal_thought`, { replace: true });
  };

    // //Function to print
    const myprintFunction = () => {
      var realData = document.getElementById("main").innerHTML;
      var secondData = document.getElementById("printAble").innerHTML;
      document.getElementById("printAble").innerHTML = realData;
      document.getElementById("mainContainer").style.display = "none";
      document.getElementById("printFooter").style.display = "block";
      window.print();
      // document.getElementById('main').innerHTML = realData;
      document.getElementById("printAble").innerHTML = secondData;
      // document.getElementById("printFooter").style.display = "block";
      // document.getElementById("main").style.display = "block";
      document.getElementById("mainContainer").style.display = "block";
    };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Targeting my Casual Thought - Review | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-wrapper content-widthsidebar ">
            <div className="card">
              <div className="card-body  p-0 mb-0">
                <div id="main">
                  {/* <div className="row header-titlewith-text m-0">
                  <div className="title col-md-6 p-0">
                    Targeting my Casual Thought - Review
                  </div>
                  <div className="col-md-6 text-right p-0">
                    <span> Welcome</span> / Behavioural Causal Thought Sample
                    Answers
                  </div>
                </div> */}
                  {/* <div className="content-topheads-text">
                    <div className="note-textheads">
                      Now compare your answers to our sample answers!
                    </div>
                  </div> */}
                  <div className="title page-title-style mb-0">
                    Targeting my Casual Thought - Review
                  </div>
                  <div className="card-body">
                    <p className="">
                      Now compare your answers to our sample answers!
                    </p>
                    {errorAnswerMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorAnswerMessage}
                          </div>
                        </div>
                      </div>
                    ) : behaviouralCausalAnswers !== undefined &&
                      behaviouralCausalAnswers.length !== 0 ? (
                      <div className="content-wrap">
                        <div className="content-topheads-text">
                          <p className="res-user-st component-sub-heading-style py-3">
                            {behaviouralCausalAnswers.utbus_data.statement}
                          </p>
                          <div className="thinkingEx">
                            {behaviouralCausalAnswers.user_responce.map(
                              (item, index) => {
                                const card = (
                                  <div
                                    className="wrap normal-text mb-5"
                                    key={index}
                                  >
                                    <p className="normal-text mb-4">
                                      {" "}
                                      {index + 1 + "." + " " + item.question}
                                    </p>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>
                                                Your answers
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>{item.userRecord}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>
                                                Sample responses
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>{item.sample_answear}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                                return card;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                    <div>
                                    <FooterOfPrint/>
                                </div>
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="previous1 col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={postSubmission}
                      disabled={disabled}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4EleventhPage;
