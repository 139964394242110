import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import React from "react";
import { options } from "../../../../constants/pretreatment/FneQuestionnaire";

const FneQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetQuestionnaire("SAS"); // Questions list from API
  // console.log(questionnaire, 'its das questionareis')

  /*******   Page Params  *******/

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const title = "Social Anxiety Scale";

  const content = `<p class="mb-0">Great ${user?.userInfo?.Fname}  ${user?.userInfo?.Lname}! 2 down, 2 to go. This 3rd questionnaire is going to give the program information about how you respond in social situations and what you may find challenging about them.</p>`;

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>Social Anxiety Scale | ASRC iBroadway</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/dass_questionnaire"
        questions={questionnaire}
        options={options}
        type={"bfne"}
        error={errorMessage}
        previousLink="intro/welcome_user"
      />
    </div>
  );
};

export default FneQuestionnaire;
