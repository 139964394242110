import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ExcerciseAnswers from "../templates/Answers/ExerciseAnswers";
import { GetUserResponse } from "../../../../services/part1/get-user-response";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";

const ThinkingResponse4 = () => {
  const userInfo = localStorage.getItem("user"); // Logged in user params
  const user = JSON.parse(userInfo);

  const [userResponse, errorMessage] = GetUserResponse(4);

  /*******   Page Params  *******/

  const title = "'Thought' Exercise 4 - Your Answers";

  const content =
    "<p>Please compare the causal thoughts you wrote down with our example thoughts about what Zoe could have told herself that would make her feel each of the 4 emotions:</p>";

  const images = [
    'exercise4/93.jpg',
    'exercise4/94.jpg',
    'exercise4/95.jpg',
    'exercise4/96.jpg',
  ];

  /*******   Hooks  *******/

  useEffect(() => {
    ModuleCompletionEmails("sendmailPart1"); // Sending module completion email
  }, []);

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>'Thought' Exercise 4 - Your Answers | ASRC iBroadway</title>
      </Helmet>
      <ExcerciseAnswers
        title={title}
        content={content}
        responses={userResponse}
        link="part_1/complete"
        type={"ThinkingResponse1"}
        sitnum={4}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_4"
        images={images}
      />
    </div>
  );
};

export default ThinkingResponse4;
