import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetSraResponse } from "../../../../services/part6/get-sra-response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part6FourthPage = () => {
  const [sraResponse, errorMessage] = GetSraResponse(); // get SraResponse from API
  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorApiMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  /*******  Helper Functions and variables  *******/
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;

    if (state.data.some((item) => item.question_id === inputId)) {
      let updatedData = state.data.map((item) => {
        if (item.question_id === inputId) {
          return { ...item, question_id: inputId, user_responce: value };
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [...state.data, { question_id: inputId, user_responce: value }],
      }));
    }

    if (value !== "" || value === "") {
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage:
              "Please submit all the challenge responses to continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `sra_fill_responce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (sraResponse.sra_responce.length === state.data.length) {
        handleAudioStoreData();
        navigate("/part_6/mental_imagery", { replace: true });
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `sra_fill_responce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_6/mental_imagery", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_6/sar`, { replace: true });
  };

  // close modal on its own

  setTimeout(handleClose, 1000);

  /*******  Hooks   *******/

  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.user_responce === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.user_responce))
    );

    if (state.data.length === 3 && arr.length === 0 && arr1.length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state.data]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******  Render   *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> My View On Roam & Zone | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-0">
                    My View On Roam & Zone
                  </div>
                  <div className="card-body">
                    <p className="mb-0">
                      Welcome back{" "}
                      <span className="normal-text-black">
                        {" "}
                        {user.userInfo.Fname + " " + user.userInfo.Lname}.{" "}
                      </span>
                      We want to know how your experience using ZONE & ROAM
                      went.
                    </p>
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : sraResponse !== undefined &&
                      sraResponse.length !== 0 ? (
                      <div className="content-wrap">
                        <div className="content-topheads-text">
                          {sraResponse?.sra_responce?.map((item, index) => {
                            const card = (
                              <div className="left-content" key={index}>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <h3 className=" component-sub-heading-style text-center mt-4">
                                      {item.question_id.question}
                                    </h3>
                                    <p className="text-center">
                                      Tell us what happened when you tried the
                                      ROAM & ZONE technique in this situation?
                                    </p>
                                    <div className="textbox">
                                      <textarea
                                        readOnly={item.disable_status}
                                        className="form-control"
                                        id="ControlTextarea"
                                        rows="10"
                                        defaultValue={item.responce}
                                        placeholder="Your Answer Here..."
                                        onChange={handleChange(
                                          item.question_id._id
                                        )}
                                      />
                                    </div>
                                    {textBoxValidation.id ===
                                      item.question_id._id && (
                                      <small className="text-danger">
                                        {textBoxValidation.errorMessage}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                            return card;
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {sraResponse !== undefined &&
                  sraResponse.length !== 0 &&
                  sraResponse.global_disable_status === false ? (
                    <>
                      <Button
                        className="user-reponse-submit  btn btn-primary link-sty m-0 mr-2"
                        onClick={saveDraft}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {sraResponse !== undefined &&
                  sraResponse.length !== 0 &&
                  sraResponse.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? textBoxValidation.disabledMessage
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit  btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit  btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body>{successMessage}</Modal.Body>
            ) : (
              <Modal.Body>{errorMessage}</Modal.Body>
            )}
          </Modal>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorApiMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part6FourthPage;
