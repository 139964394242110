import {
    AUDIO_FAIL,
    AUDIO_SUCCESS
  } from "../actions/types";
 
  const audio = {};
  const initialState = audio;
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case AUDIO_SUCCESS:
        return {
          ...state,
          audio: payload,
        };
      case AUDIO_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }