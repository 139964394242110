import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

import "../../styles/dashboard-style.css";
import Header from "./Header";
import { GetCurrentIndex } from "../../services/sidebar/get-current-index";
import { formControlLabelClasses } from "@mui/material";
import { DOMAIN_NAME, IMAGE_URL } from "../../env/Baseurl";

const UnauthorizedPage = () => {
  const [currentIndex, errorMessage] = GetCurrentIndex();
  return (
    <div className="dashboardContent error-template">
      <div>
        <Header />
      </div>
      <div>
        {errorMessage ? (
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="alert alert-danger text-center" role="alert">
                {errorMessage}
              </div>
            </div>
          </div>
        ) : currentIndex !== undefined && currentIndex.length !== 0 ? (
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 region region-content">
              <div
                id="block-satp-user-page-title"
                className="block block-core block-page-title-block"
              >
                <div className="content">
                  <img
                    src={`${IMAGE_URL}images/error/wentWrong.png`}
                    alt="something Went Wrong"
                  ></img>
                  <h1 className="title page-title">Somethings not Right!!</h1>
                </div>
              </div>
              <div
                id="block-satp-user-content"
                className="block block-system block-system-main-block"
              >
                <div className="content">
                  Seems you have hit the wrong url. Please click below to
                  continue the program
                </div>
              </div>
              <div
                id="block-satp-user-content"
                className="block block-system block-system-main-block mt-3"
              >
                <div className="content">
                  <Link to={currentIndex?.lastPage}>Continue the Program!</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        ) : (
          <div className="loader-icon">
            <TailSpin color="#4f5962" height={50} width={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UnauthorizedPage;
