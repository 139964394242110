import React from 'react';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import { options } from '../../../../constants/pretreatment/AvoidanceQuestionnaire';
import { GetPostTreatmentQuestionnaire } from '../../../../services/post-treatment/get-post-treatment-questionnaire';

const PostAvoidanceQuestionnaire = () => {

    const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("Avoidance");        // Get Questionnaire from API

    /*******  Page Params  *******/

    const title = "Avoidance Questionnaire";

    // const content = "<div><p className='redText'>Please indicate accurately on a range from Never to Always how often you avoid the following situations.</p></div>";
    const content = "<div><p>The following questionnaire is also a very crucial component of this program. So, if you want maximum benefit from our treatment package, you need to think carefully about your answers to the questions below. Please indicate accurately on a range from 'Does not apply to me' to ‘All the time’ how often you avoid the following situations. It is extremely important for your progress that you give honest responses.</p></div>";


    /*******  Render  *******/

    return (
        <div>
            <Helmet>
                <title>Avoidance Questionnaire | ASRC iBroadway</title>
            </Helmet>
            <Questionnaire
                title={title}
                content={content}
                link='part_7/dass_questionnaire'
                questions={questionnaire}
                options={options}
                type={'avoidance'}
                error={errorMessage}
                previousLink='part_7/utbas_questionnaire'
            />
        </div>
    );

};

export default PostAvoidanceQuestionnaire;