import React from 'react';
import { Helmet } from 'react-helmet';
import InputForms from '../templates/InputForms/InputForms';
import { GetInputQuestions } from '../../../../services/part1/get-input-forms';

const ThinkingExercise2 = () => {

    const [inputQuestions, errorMessage] = GetInputQuestions(2);     // Input Questions list from API

    /*******   Page Params  *******/

    const title = "'Thought' Exercise 2";

    // const content = "<div>Please read the following example and write down the THOUGHTS you think Jane would have had in the situation described below:</div>";
    const content = "You’re doing well. Have a go at this next exercise now.";


    const situation = "<div><p class='normal-text-black'>Mia, who has a moderate stutter, walked into McDonalds to order lunch. When she got to the counter, she stuttered several times while ordering. The person behind the counter asked her to repeat her order. She managed to get her food, then left.</p><p class='normal-text-black mb-0'> Write down a thought that could make Mia feel:</p></div>";

    const situation_image = 'exercise2/53.jpg';


    const images = [
        "exercise2/54.jpg",
        "exercise2/55.jpg",
        "exercise2/56.jpg",
        "exercise2/57.jpg"
    ];
    /*******   Render  *******/

    return (
        <div>
            <Helmet>
                <title>'Thought' Exercise 2 | ASRC iBroadway</title>
            </Helmet>
            <InputForms
                title={title}
                content={content}
                questions={inputQuestions}
                link='part_1/thinking_exercise_2_sample_answers'
                type={'create_Excercise2'}
                images={images}
                situation={situation}
                sitnum={2}
                error={errorMessage}
                previousLink='part_1/thinking_exercise_1_sample_answers'
                tip=""
                situation_image={situation_image}
            />
        </div>
    );
};

export default ThinkingExercise2;