import axios from "axios";

import {
    BASE_URL
} from '../env/Baseurl';

const badge = () => {
    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    return axios.get(BASE_URL + `getuserbyid/?user_id=${user.userInfo._id}`, {
        "headers": {
            "content-type": "application/json",
            "Authorization": `Bearer ${user.token}`,
        },
    })
        .then((response) => {
            // console.log(response.data, "its data badge data")
            return response.data;
        })
}


export default badge;