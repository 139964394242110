import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL, IMAGE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetBehaviouralCausalThought } from "../../../../services/part4/behavioural-causal-thought";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4TenthPage = () => {
  const [behaviouralCausalThought, errorMessage] =
    GetBehaviouralCausalThought(); // Get Behavioural Data form Api

  /*******   States *******/
  const [isPlaying, setIsPlaying] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorApiMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;

    if (state.data.some((item) => item.question_id === inputId)) {
      let updatedData = state.data.map((item) => {
        if (item.question_id === inputId) {
          return { ...item, question_id: inputId, user_responce: value };
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [...state.data, { question_id: inputId, user_responce: value }],
      }));
    }

    if (value !== "" || value === "") {
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
            disabledMessage:
              "Only special characters are not allowed. Please add alphabets with it.",
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage:
              "Please submit all the challenge responses to continue.",
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user info
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL +
          `behavioural_causal_thought_userresponce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.data.length >= behaviouralCausalThought.count) {
        handleAudioStoreData();
        navigate(`/part_4/behavioural_causal_thought_sample_answers`, {
          replace: true,
        });
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(
          "Please check Part 4 Behavioural Casual Thought Userresponse Api"
        );
        ErrorLogging(
          "HTTP POST Part 4 /behavioural_causal_thought_userresponce/userId" +
            error
        );
        handle();
      }
    }
  };

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user info
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL +
          `behavioural_causal_thought_userresponce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage(
          "Please check Part 4 Behavioural Casual Thought Userresponse Api"
        );
        ErrorLogging(
          "HTTP POST Part 4 /behavioural_causal_thought_userresponce/userId" +
            error
        );
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/behavioural_causal_thought_sample_answers", {
      replace: true,
    });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/perceived_negative_evaluations`, { replace: true });
  };

  // close modal on its own

  setTimeout(handleClose, 1000);

  /*******   Hooks  *******/

  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.user_responce === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.user_responce))
    );

    if (
      behaviouralCausalThought !== undefined &&
      behaviouralCausalThought.length !== 0
    ) {
      const { data = [] } = behaviouralCausalThought.questions;

      if (
        state.data.length >= behaviouralCausalThought.count &&
        arr.length === 0 &&
        arr1.length === 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [state.data]);

  useEffect(() => {
    if (behaviouralCausalThought.count === 0) {
      setDisabled(false);
    }
  }, [behaviouralCausalThought]);

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      behaviouralCausalThought !== undefined &&
      behaviouralCausalThought.length !== 0 &&
      behaviouralCausalThought.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <Helmet>
          <title>Targeting my Causal Thought | ASRC iBroadway</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            {/* <div className="row header-titlewith-text m-0">
              <div className="col-md-6 p-0 main-title-style">
                Targeting My Causal Thought
              </div>
              <div className="col-md-6 text-right p-0">
                <span> Welcome</span> / Targeting My Causal Thought
              </div>
            </div> */}
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style mb-3">
                    Targeting My Causal Thought
                  </div>
                  <div className="card-body part4-tenthPage">
                    <div className="">
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : behaviouralCausalThought !== undefined &&
                        behaviouralCausalThought.length !== 0 ? (
                        <div className="content-wrap">
                          <div className="content-topheads-text">
                            <div className="user-res-situation">
                              <p className="component-sub-heading-style">
                                Your Casual Thought :{" "}
                                <span className="normal-text">
                                  {" "}
                                  {
                                    behaviouralCausalThought.utbus_data
                                      .statement
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="left-content">
                              <div className="row">
                                {behaviouralCausalThought.questions.map(
                                  (item, index) => {
                                    const card = (
                                      <div
                                        className="col-xl-6 mb-5"
                                        width="40%"
                                        key={index}
                                      >
                                        <div className="question-container">
                                          <p className="questions component-heading-style">
                                            {index +
                                              1 +
                                              "." +
                                              " " +
                                              item.question}
                                          </p>
                                        </div>
                                        <div className="main-container my-5">
                                          <div className=" sub-container center-align">
                                            <div className="image-container">
                                              <img
                                                src={`${IMAGE_URL}images/part4/page4tenthPage/204.png`}
                                                alt="Ben"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="textbox">
                                          <textarea
                                            readOnly={item.disable_status}
                                            className="form-control"
                                            id="ControlTextarea"
                                            rows="2"
                                            placeholder="Your Answer Here..."
                                            defaultValue={item.responce}
                                            onChange={handleChange(item._id)}
                                          />
                                        </div>
                                        {textBoxValidation.id === item._id && (
                                          <small className="text-danger">
                                            {textBoxValidation.errorMessage}
                                          </small>
                                        )}
                                      </div>
                                    );
                                    return card;
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                      <div>
                        <FooterOfPrint />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {behaviouralCausalThought !== undefined &&
                  behaviouralCausalThought.length !== 0 &&
                  behaviouralCausalThought.global_disable_status === false ? (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0  mr-2"
                        onClick={saveDraft}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {behaviouralCausalThought !== undefined &&
                  behaviouralCausalThought.length !== 0 &&
                  behaviouralCausalThought.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? textBoxValidation.disabledMessage
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              {successMessage ? (
                <Modal.Body>{successMessage}</Modal.Body>
              ) : (
                <Modal.Body>{errorMessage}</Modal.Body>
              )}
            </Modal>
            {/* error message modal */}

            <Modal show={showError}>
              <Modal.Body>{errorApiMessage}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="col-md-12 dashboard-footer">
              <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4TenthPage;
