import React, { useDebugValue, useEffect, useState } from "react";

const TermsAndConditions = ({ setChecked, checked, emptyCheck, values }) => {
  /*******   HelperComponent  *******/
  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label style={{ display: "flex", alignItems: "center" }}>
        <input type="checkbox" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

  /*******   HelperFunctions  *******/
  const checkboxHandleChange = () => {
    setChecked(!checked);
  };

  /*******   Render  *******/

  return (
    <div className="userInfoContainer">
      <div className="row align-items-center login-col-wrap m-0 justify-content-center">
        <div className="col-md-12 ">
          <div>
            <h3 className="text-center pagehead-texttitle">
              Terms and conditions
            </h3>
          </div>
          <div className="form-group cofirmlabel" style={{ padding: "20px" }}>
            <Checkbox
              label={
                <label
                  className="input-label-style"
                  style={{ marginTop: "10px" }}
                >
                  {values?.user_situation ===
                  "I am an adolescent who stutters" ? (
                    <span>
                      I{" "}
                      <b>
                        {values?.first_name} {values?.last_name}
                      </b>{" "}
                      and{" "}
                      <b>
                        {values?.guardian_first_name}{" "}
                        {values?.guardian_last_name}
                      </b>{" "}
                      have read and accept the{" "}
                      <a href="/terms-of-use" target="_blank">
                        terms and conditions
                      </a>
                    </span>
                  ) : (
                    <span>
                      I{" "}
                      <b>
                        {values?.first_name} {values?.last_name}
                      </b>{" "}
                      have read and accept the{" "}
                      <a href="/terms-of-use" target="_blank">
                        terms and conditions
                      </a>
                    </span>
                  )}
                </label>
              }
              value={checked}
              onChange={checkboxHandleChange}
            />
            {checked === false && (
              <small className="text-danger input-label-style error-message-style">
                {emptyCheck.terms}
              </small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
