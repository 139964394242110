import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_7/part7firstpage/part7firstPage.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part7FifthPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  /*******  Page Params  *******/
  const title = "My Final page";
  /********hooks *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  /****functionst***** */
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/part_7/handouts`, { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_7/ways_to_protect_yourself`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******  Render  *******/
  return (
    <div className="welcome-content">
      <Helmet>
        <title>My Final Page| ASRC iBroadway</title>
      </Helmet>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style  m-0">{title}</div>
                  <div className="card-body">
                    <p>
                      What’s the biggest mistake that anxious people make? –
                      they sweat the small stuff. Attaching so much anxiety to
                      such minor things is disastrous:
                    </p>
                    <div className="main-container mt-5 mb-4">
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part7/part7fifthPage/291.png`}
                            alt="anxiety1"
                          />
                        </div>
                      </div>
                      <div className="container">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part7/part7fifthPage/292.png`}
                            alt="anxiety2"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      All of these worries seem so ridiculous when you think
                      about the wonderful opportunity that each of us has to
                      grab life and enjoy it. Ruminating on these trivial issues
                      not only feels bad, but robs us of the pleasure of the
                      ‘now’.
                    </p>
                    <p>
                      For example, you’re sitting down to watch a movie tonight,
                      but your mind is dragged to your memory of the bus driver
                      frowning. The film cost millions of dollars to produce,
                      and utilized the combined talents of hundreds of actors,
                      scriptwriters, designers, cinematographers, make-up
                      artists, and the like. But you have missed critical parts
                      of the movie while you ruminated about the bus driver
                      frowning. Your enjoyment of the movie is diminished and
                      you have permanently lost these moments in time. You don’t
                      get the night back!
                    </p>
                    <div className="main-container my-4">
                      <div className=" sub-container center-align">
                        <div className="image-container">
                          <img
                            src={`${IMAGE_URL}images/part7/part7fifthPage/293.jpg`}
                            alt="Wonder"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row  mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part7FifthPage;
