// const options = [
//     "Never Avoid",
//     "Infrequently Avoid",
//     "Sometimes Avoid",
//     "Frequently Avoid",
//     "Always Avoid"
// ];    

const options = [
    "Does not apply to me",
    "Not at all",
    "Hardly ever",
    "Sometimes",
    "Most of the time",
    "All the time"
];
export {
    options
};