import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';


    const CounterInitialize = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
            const params = JSON.stringify({
                "data": "",
            });    
            const response = await axios.post(BASE_URL + `no_counter_initilize/${user.userInfo._id}`, params, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
         
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                CounterInitialize();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
            else{
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
        }     
    };
  

    
    const FlagInitialize = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
            const params = JSON.stringify({
                "data": "",
            });    
            const response = await axios.post(BASE_URL + `part4_flag_initilize/${user.userInfo._id}`, params, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                },   
            })
    
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                FlagInitialize();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
            else{
            ErrorLogging('HTTP GET /getAll_thinkingExcercise_Bysituation' + error);
            }
        }     
    };



export {
    CounterInitialize,
    FlagInitialize
}