import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeExercise from '../templates/InputForms/ChallengeExercise';
import { GetChallengeQuestions } from '../../../../services/part2/get-challenge-forms';

const Challenge4 = () => {

    const [challengeQuestions, errorMessage] = GetChallengeQuestions('challange_show4');     // Input Questions list from API

    /*******   Page Params  *******/

    // const title = "Challenge 4";
    const title = "Challenge My Thinking - Exercise 4";


    const content = "Three done. Getting close!";

    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge My Thinking - Exercise 4 | ASRC iBroadway</title>
            </Helmet>
            <ChallengeExercise
                title={title}
                content={content}
                questions={challengeQuestions}
                type={'create_chall4'}
                link='part_2/challenge_4_sample_answers'
                subtitle={''}
                error={errorMessage}
                previousLink='part_2/challenge_3_sample_answers'
                thoughNumber="fourth"
            />
        </div>
    );
};

export default Challenge4;