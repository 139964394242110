import "../../../../../styles/adminlte.min.css";
import "../../../../../styles/Slider/slider.css";

import React, { useState } from "react";

import { AUDIO_SUCCESS } from "../../../../../actions/types";
import { Button } from "react-bootstrap";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import Header from "../../../../global/Header";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChallengeAnswers = ({
  title,
  content,
  responses,
  link,
  type,
  error,
  previousLink,
  thoughtNumber,
}) => {
  /*******   States *******/
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   hooks  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();
  /****************functions****** */
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const postSubmission = () => {
    handleAudioStoreData();
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/${previousLink}`, { replace: true });
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style m-0">{title}</div>
                </div>
                <div className="card-body">
                  <p>Now compare your answers to our sample answers!</p>
                  {error ? (
                    <div className="container mt-5">
                      <div className="row justify-content-center">
                        <div
                          className="alert alert-danger text-center"
                          role="alert"
                        >
                          {error}
                        </div>
                      </div>
                    </div>
                  ) : responses !== undefined && responses.length !== 0 ? (
                    <div className="content-wrap">
                      <div className="content-topheads-text">
                        <div className="user-res-situation p-0">
                          <p className="component-sub-heading-style">
                            Your {thoughtNumber} causal thought:{" "}
                            <span className=" component-sub-heading-style medium-black-color">
                              {responses.statement}
                            </span>
                          </p>
                        </div>
                        <div className="thinkingEx">
                          {responses.user_responce.map((item, index) => {
                            const card = (
                              <div className="wrap" key={index}>
                                <p className="questions component-heading-style mb-3">
                                  {index + 1 + "." + " " + item?.question}
                                </p>
                                <div className="row mb-4">
                                  <div className="col-lg-6">
                                    <table
                                      className="thinking_table left_table"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr className="questions">
                                          <td>
                                            Your answers
                                            <br />
                                          </td>
                                        </tr>
                                        <tr className="answers">
                                          <td>{item.userRecord}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="col-lg-6">
                                    <table
                                      className="thinking_table left_table"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr className="questions">
                                          <td>
                                            Sample responses
                                            <br />
                                          </td>
                                        </tr>
                                        <tr className="answers">
                                          <td>{item.sample_answear}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            );
                            return card;
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="loader-icon">
                      <TailSpin color="#4f5962" height={50} width={50} />
                    </div>
                  )}
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={postSubmission}
                    disabled={disabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeAnswers;
