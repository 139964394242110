import React from "react";
import { Link } from 'react-router-dom';
import '../../styles/dashboard-style.css';

const StaticFooter = () => {

    /*******   Render  *******/

    return (
            <div className="footer">
                <div className="footerInnersection">
                    <div className="col d-flex justify-content-center" id="StaticFooter">
                       <Link to='/'> <p style={{paddingRight:'10px'}}>Home</p></Link>
                       <Link to='/terms-of-use'>   <p style={{paddingRight:'10px'}}>Terms of Use</p></Link>
                       <Link to='/disclaimer'> <p style={{paddingRight:'10px'}}>Disclaimer</p></Link>
                      <Link to='/privacy-policy'> <p style={{paddingRight:'10px'}}>Privacy</p></Link> 
                    </div>
            </div>

        </div>
    );

};

export default StaticFooter;