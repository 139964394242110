import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetEssayResponse } from "../../../../services/part6/user-essay-response";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import React from "react";
import Sidebar from "../../../global/Sidebar";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";

const Handout5 = () => {
  const [essayResponse, errorMessage] = GetEssayResponse();
  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>Handout – Challenging Your Thinking | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-10 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body p-0 mb-0 ">
                <div className="title page-title-style mb-0">
                  Handout – Challenging Your Thinking
                </div>
                <div className="card-body">
                  <p className="normal-text-black">
                    Here is the essay I wrote and the sample essay on why it
                    doesn't matter what other people think about me:
                  </p>
                  {essayResponse.length !== 0 && (
                    <p>{essayResponse?.eassay_responce?.eassy}</p>
                  )}

                  <p className="normal-text-black">
                    Our sample Essay - Why it does not matter what other people
                    think about me?
                  </p>
                  {/* <p>Why it doesn't matter what people think about me?</p> */}
                  <p>My key points :</p>
                  <div>
                    <p className="listing">
                      1) If I constantly need reassurance that I am worthwhile
                      or successful, I will be on an emotional roller-coaster
                      all the time. In contrast, if I value my own opinions
                      about my worth, I can have a stable emotional life.
                    </p>
                    <p className="listing">
                      2) If I can't handle minor criticisms well, I will never
                      be able to learn from my mistakes. I want to be
                      successful, so I need to learn not to overreact to
                      negative evaluation.
                    </p>
                    <p className="listing">
                      3) If I care too much about the way other people evaluate
                      me, they will be able to constantly take advantage of me.
                      I will end up doing EVERYTHING I can to prevent them from
                      disapproving of me.
                    </p>
                    <p className="listing">
                      4) If I don't care what other people think of me, I will
                      be more assertive and I'll stop feeling so resentful all
                      the time.
                    </p>
                    <p className="listing">
                      5) It is so much easier to be truly happy if I am not
                      worrying about what other people think of me all the time.
                    </p>
                    <p className="listing">
                      6) If I don't care what other people think of me, I can be
                      REAL. If I can listen to the beat of my own drum, rather
                      than constantly trying to please others, I will be able to
                      read what I want, go where I want, eat what I want, and be
                      WHO I AM.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FooterOfPrint />
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout5;
