export const Fallback = () => {
    return(
        <div className='row'>
        <div className='col-lg-3'></div>
            <div className="col-lg-6 region region-content">
                <div id="block-satp-user-page-title" className="block block-core block-page-title-block">
                    <div className="content">
                        <h1 className="title page-title">  Something went wrong !!! </h1>
                    </div>
                </div>
                <div id="block-satp-user-content" className="block block-system block-system-main-block">
                    <div className="content">
                            We are on it and will be back soon. Please try after sometime.
                    </div>
                </div>
            </div>
            <div className='col-lg-3'></div>
    </div>
    );
};