import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import audio from "./audio";
import badge from './badge'
export default combineReducers({
  auth,
  message,
  audio,
  badge
});