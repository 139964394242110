import React from "react";
import { Helmet } from "react-helmet";
import ExcerciseAnswers from "../templates/Answers/ExerciseAnswers";
import { GetUserResponse } from "../../../../services/part1/get-user-response";

const ThinkingResponse3 = () => {
  const [userResponse, errorMessage] = GetUserResponse(3); // User Responses from API

  /*******   Page Params  *******/
  const title = "'Thought' Exercise 3 - Your Answers";

  const content =
    "<p>Please compare the thoughts you wrote down with our examples about what Jackson could have told himself that would make him feel each of the 4 different emotions:</p>";

  const images = [
    "exercise3/87.jpg",
    "exercise3/89.jpg",
    "exercise3/88.jpg",
    "exercise3/90.jpg",
  ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>'Thought' Exercise 3 - Your Answers | ASRC iBroadway</title>
      </Helmet>
      <ExcerciseAnswers
        title={title}
        content={content}
        responses={userResponse}
        link="part_1/thinking_exercise_4"
        type={"ThinkingResponse1"}
        sitnum={3}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_3"
        images={images}
      />
    </div>
  );
};

export default ThinkingResponse3;
