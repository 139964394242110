import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../templates/ContentPages";
// import ReactToPrint from '../../../../actions/ReactToPrint';
import { useDispatch } from "react-redux";
import getBadgeInfo from "../../../../actions/badgeInfo";

const Welcome = () => {
  /************************Hooks and LocalStorge*****************/

  const dispatch = useDispatch();
  const moduleName = localStorage.setItem("moduleName", "Pre-Treatment"); // User visited module

  /*******   Page Params  *******/

  const title = "Welcome to the Online iBroadway Program";

  const content =
    "<div><p>Welcome to the Online iBroadway Program! Cognitive Behaviour Therapy (CBT) is a well-known technique for treating anxiety and has significant support for its effectiveness from over a THOUSAND psychology studies.</p><p>The Online iBroadway Program has seven components and our research from previous participants has indicated that the more of these that you complete, the more improvement you will gain from using the program. Teenagers who have completed all of the sections have experienced an improved quality of life, better mood, and decreased symptoms of anxiety. Therefore, we encourage you to stick to it and we will send you reminder emails if you have not logged on for a while.</p><p>The assessment at the beginning of the program is important to tailor the program around the particulars of your condition. Therefore, take your time and go through this assessment carefully. After you have completed the initial assessment battery, the program will become more dynamic and a little more fun!</p><p>We look forward to working with you over the next 5 months. In order to complete all seven sections within that time, we expect you to log in approximately twice per week. We hope you enjoy the program.<p>Sincerely,</p><p>The iBroadway Team</p></p></div>";

  /***********************UseEffect********************/
  // useEffect(() => {
  //   dispatch(getBadgeInfo())
  //     .then(() => {})
  //     .catch(() => {});
  // }, []);

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Welcome to the Online iBroadway Program | ASRC iBroadway</title>
      </Helmet>
      <ContentPage title={title} content={content} link="intro/how_to" />
    </div>
  );
};

export default Welcome;
