import React from 'react'
import '../../styles/audioProgressBar/audioProgressBar.css'

const AudioProgressBar = ({ audioRef, progressBarRef, timeProgress, duration }) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
        // console.log(progressBarRef.current.value, "its progress bar value onChange")
    }

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes =
                minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds =
                seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };
    return (
        <div className='audio-progress-bar'>
            <div className='time current'>{formatTime(timeProgress)}</div>
            <input type='range' ref={progressBarRef} defaultValue="0" onChange={handleProgressChange} />
            <div className='time'>{formatTime(duration)}</div>
        </div>

    )
}


export default AudioProgressBar;