import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeExercise from '../templates/InputForms/ChallengeExercise';
import { GetChallengeQuestions } from '../../../../services/part2/get-challenge-forms';

const Challenge2 = () => {

    const [challengeQuestions, errorMessage] = GetChallengeQuestions('challange_show2');    // Input Questions list from API

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    /*******   Page Params  *******/

    // const title = "Challenge 2";
    const title = "Challenge My Thinking - Exercise 2";


    // const content = "One down, four to go. Keep at it!";
    const content = "One down, three to go. You're doing well Your Name ";


    /*******   Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>Challenge My Thinking - Exercise 2 | ASRC iBroadway</title>
            </Helmet>
            <ChallengeExercise
                title={title}
                content={content}
                questions={challengeQuestions}
                type={'create_chall2'}
                link='part_2/challenge_2_sample_answers'
                subtitle={`One down, three to go. You're doing well ${user?.userInfo?.Fname + " " + user?.userInfo?.Lname}.`}
                error={errorMessage}
                previousLink='part_2/challenge_1_sample_answers'
                thoughNumber="second"
            />
        </div>
    );
};

export default Challenge2;