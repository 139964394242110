const options = [
    "Not at all characteristic of me",
    "A little characteristic of me",
    "Somewhat characteristic of me",
    "Very characteristic of me",
    "Entirely characteristic of me"
];

export {
    options
};