import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import "../fonts/Helvetica.ttf";
import "../fonts/Bangers-Regular.ttf";
import "../fonts/ComicNeue-Regular.ttf";
import "../fonts/ComicNeue-Bold.ttf";
import "../fonts/ComicNeue-Light.ttf";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AnxietyModel from "./screens/part3/pages/AnxietyModel";
import AnxietySymptoms from "./screens/part3/pages/AnxietySymptoms";
import AnxiousCauses from "./screens/part1/pages/AnxiousCauses";
import AvoidanceQuestionnaire from "./screens/pretreatment/pages/AvoidanceQuestionnaire";
import AvoidanceSafetyBehaviours from "./screens/part3/pages/AvoidanceSafetyBehaviours";
import Begin from "./screens/part1/pages/Begin";
import CasualBehaviours from "./screens/part3/pages/CasualBehaviours";
import CasualThought from "./screens/part1/pages/CasualThought";
import Challenge1 from "./screens/part2/pages/Challenge1";
import Challenge2 from "./screens/part2/pages/Challenge2";
import Challenge3 from "./screens/part2/pages/Challenge3";
import Challenge4 from "./screens/part2/pages/Challenge4";
import Challenge5 from "./screens/part2/pages/Challenge5";
import ChallengeExample from "./screens/part2/pages/ChallengeExample";
import ChallengeResponse1 from "./screens/part2/pages/ChallengeResponse1";
import ChallengeResponse2 from "./screens/part2/pages/ChallengeResponse2";
import ChallengeResponse3 from "./screens/part2/pages/ChallengeResponse3";
import ChallengeResponse4 from "./screens/part2/pages/ChallengeResponse4";
import ChallengeResponse5 from "./screens/part2/pages/ChallengeResponse5";
import ChangePassword from "./screens/authentication/ChangePassword";
import { CheckConnection } from "../services/check-connection";
import ChecklistCognitiveErrors from "./screens/part1/pages/ChecklistCognitiveErrors";
import CompleteProgram from "./screens/posttreatment/pages/CompleteProgram";
import DassQuestionnaire from "./screens/pretreatment/pages/DassQuestionnaire";
import Disclaimer from "./global/Disclaimer";
import ErrorPage from "./screens/error/404";
import ExampleBehaviours from "./screens/part3/pages/ExampleBehaviours";
import { Fallback } from "./screens/error/Fallback";
import FeelingSad from "./global/FeelingSad";
import FneQuestionnaire from "./screens/pretreatment/pages/FneQuestionnaire";
import { GetCurrentIndex } from "../services/sidebar/get-current-index";
import Handout1 from "./screens/handouts/pages/Handout1";
import Handout2 from "./screens/handouts/pages/Handout2";
import Handout3 from "./screens/handouts/pages/Handout3";
import Handout4 from "./screens/handouts/pages/Handout4";
import Handout5 from "./screens/handouts/pages/Handout5";
import Handout6 from "./screens/handouts/pages/Handout6";
import Hello from "./screens/pretreatment/pages/Hello";
import HowToUse from "./screens/pretreatment/pages/HowToUse";
import LetsRecap from "./screens/part3/pages/LetsRecap";
import LetsReview from "./screens/part1/pages/LetsReview";
import LoginScreen from "./screens/authentication/Login";
import MoreSafetyBehaviours from "./screens/part3/pages/MoreSafetyBehaviours";
import Part2complete from "./screens/part2/pages/PartComplete";
import Part3Reached from "./screens/part3/pages/Part3Reached";
import Part3complete from "./screens/part3/pages/PartComplete";
import Part4EighthPage from "./screens/part4/pages/Part4EighthPage";
import Part4EleventhPage from "./screens/part4/pages/Part4EleventhPage";
import Part4FifthPage from "./screens/part4/pages/Part4FifthPage";
import Part4FirstPage from "./screens/part4/pages/Part4FirstPage";
import Part4FourteenthPage from "./screens/part4/pages/Part4FourteenthPage";
import Part4FourthPage from "./screens/part4/pages/Part4FourthPage";
import Part4NinthPage from "./screens/part4/pages/Part4NinthPage";
import Part4SecondPage from "./screens/part4/pages/Part4SecondPage";
import Part4SeventhPage from "./screens/part4/pages/Part4SeventhPage";
import Part4SixthPage from "./screens/part4/pages/Part4SixthPage";
import Part4TenthPage from "./screens/part4/pages/Part4TenthPage";
import Part4ThirdPage from "./screens/part4/pages/Part4ThirdPage";
import Part4ThirteenthPage from "./screens/part4/pages/Part4ThirteenthPage";
import Part4TwelfthPage from "./screens/part4/pages/Part4TwelfthPage";
import Part4complete from "./screens/part4/pages/PartComplete";
import Part5Fifth from "./screens/part5/pages/part5Fifth";
import Part5First from "./screens/part5/pages/part5First";
import Part5Fourth from "./screens/part5/pages/part5Fourth";
import Part5Second from "./screens/part5/pages/part5Second";
import Part5Third from "./screens/part5/pages/part5Third";
import Part5complete from "./screens/part5/pages/PartComplete";
import Part6FifthPage from "./screens/part6/pages/Part6FifthPage";
import Part6FirstPage from "./screens/part6/pages/Part6FirstPage";
import Part6FourthPage from "./screens/part6/pages/Part6FourthPage";
import Part6SecondPage from "./screens/part6/pages/Part6SecondPage";
import Part6SixthPage from "./screens/part6/pages/Part6SixthPage";
import Part6ThirdPage from "./screens/part6/pages/Part6ThirdPage";
import Part6complete from "./screens/part6/pages/PartComplete";
import Part7FifthPage from "./screens/part7/pages/Part7FifthPage";
import Part7FirstPage from "./screens/part7/pages/Part7FirstPage";
import Part7FourthPage from "./screens/part7/pages/Part7FourthPage";
import Part7SecondPage from "./screens/part7/pages/Part7SecondPage";
import Part7SixthPage from "./screens/part7/pages/Part7SixthPage";
import Part7ThirdPage from "./screens/part7/pages/Part7ThirdPage";
import Part7complete from "./screens/part7/pages/PartComplete";
import PartComplete from "./screens/part1/pages/PartComplete";
import PostAvoidanceQuestionnaire from "./screens/posttreatment/pages/PostAvoidanceQuestionnaire";
import PostDassQuestionnaire from "./screens/posttreatment/pages/PostDassQuestionnaire";
import PostFneQuestionnaire from "./screens/posttreatment/pages/PostFneQuestionnaire";
import PostUtbasQuestionnaire from "./screens/posttreatment/pages/PostUtbasQuestionnaire";
import PrivacyPolicy from "./global/PrivacyPolicy";
import Protected from "./Protected";
import React from "react";
import RegisterScreen from "./screens/authentication/Register";
import ResetPasswordScreen from "./screens/authentication/ResetPassword";
import SafetyBehaviours from "./screens/part3/pages/SafetyBehaviours";
import SelfFocusedImagery from "./screens/part3/pages/SelfFocusedImagery";
import SocialAnxiety from "./screens/part3/pages/SocialAnxiety";
import StaticFooter from "./global/StaticFooter";
import { TailSpin } from "react-loader-spinner";
import TermsConditions from "./global/TermsConditions";
import ThinkingExercise1 from "./screens/part1/pages/ThinkingExercise1";
import ThinkingExercise2 from "./screens/part1/pages/ThinkingExercise2";
import ThinkingExercise3 from "./screens/part1/pages/ThinkingExercise3";
import ThinkingExercise4 from "./screens/part1/pages/ThinkingExercise4";
import ThinkingResponse1 from "./screens/part1/pages/ThinkingResponse1";
import ThinkingResponse2 from "./screens/part1/pages/ThinkingResponse2";
import ThinkingResponse3 from "./screens/part1/pages/ThinkingResponse3";
import ThinkingResponse4 from "./screens/part1/pages/ThinkingResponse4";
import UseIdleTimer from "../actions/UseIdleTimer";
import UserBadge from "./global/UserBadge";
import UsersInfoList from "./screens/admin/pages/UsersInfoList";
import UtbasQuestionnaire from "./screens/pretreatment/pages/UtbasQuestionnaire";
import VideoPlaying from "./screens/posttreatment/pages/videoPlay";
import Welcome from "./screens/pretreatment/pages/Welcome";
import WelcomePage from "./global/WelcomePage";
import WelcomeToPart5 from "./screens/part5/pages/WelcomeToPart5";
import WelcomeToPart6 from "./screens/part6/pages/WelcomeToPart6";
import WhatsCasualThought from "./screens/part1/pages/WhatsCasualThought";
import YourModel from "./screens/part3/pages/YourModel";
import YourModelAnxiety from "./screens/part3/pages/YourModelAnxiety";

const App = () => {
  const [checkApiConnection, errorMessage] = CheckConnection();
  const [currentIndex, indexErrorMessage] = GetCurrentIndex();
  const logout = () => alert("You have been loggedOut");
  const userState = localStorage.getItem("userState");

  if (checkApiConnection !== undefined && checkApiConnection.length !== 0) {
    if (checkApiConnection.status === 200) {
      return (
        <Router basename="/">
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route
              path="/intro/welcome"
              element={<Protected Cmp={Welcome} PageIndex={1} />}
            />
            <Route
              path="/intro/how_to"
              element={<Protected Cmp={HowToUse} PageIndex={2} />}
            />
            <Route
              path="/intro/utbas_questionnaire"
              element={<Protected Cmp={UtbasQuestionnaire} PageIndex={3} />}
            />
            <Route
              path="/intro/avoidance_questionnaire"
              element={<Protected Cmp={AvoidanceQuestionnaire} PageIndex={4} />}
            />
            <Route
              path="/intro/welcome_user"
              element={<Protected Cmp={Hello} PageIndex={5} />}
            />
            <Route
              path="/intro/fne_questionnaire"
              element={<Protected Cmp={FneQuestionnaire} PageIndex={6} />}
            />
            <Route
              path="/intro/dass_questionnaire"
              element={<Protected Cmp={DassQuestionnaire} PageIndex={7} />}
            />
            <Route
              path="/feeling_sad"
              element={<Protected Cmp={FeelingSad} PageIndex={1} />}
            />
            <Route
              path="/part_1/anxiety_is_important_in_stuttering"
              element={<Protected Cmp={Begin} PageIndex={8} />}
            />
            <Route
              path="/part_1/waiting_for_the_bus"
              element={<Protected Cmp={AnxiousCauses} PageIndex={9} />}
            />
            <Route
              path="/part_1/thinking_exercise_1"
              element={<Protected Cmp={ThinkingExercise1} PageIndex={10} />}
            />
            <Route
              path="/part_1/thinking_exercise_2"
              element={<Protected Cmp={ThinkingExercise2} PageIndex={13} />}
            />
            <Route
              path="/part_1/thinking_exercise_3"
              element={<Protected Cmp={ThinkingExercise3} PageIndex={18} />}
            />
            <Route
              path="/part_1/thinking_exercise_4"
              element={<Protected Cmp={ThinkingExercise4} PageIndex={20} />}
            />
            <Route
              path="/part_1/thinking_exercise_1_sample_answers"
              element={<Protected Cmp={ThinkingResponse1} PageIndex={12} />}
            />
            <Route
              path="/part_1/thinking_exercise_2_sample_answers"
              element={<Protected Cmp={ThinkingResponse2} PageIndex={14} />}
            />
            <Route
              path="/part_1/thinking_exercise_3_sample_answers"
              element={<Protected Cmp={ThinkingResponse3} PageIndex={19} />}
            />
            <Route
              path="/part_1/thinking_exercise_4_sample_answers"
              element={<Protected Cmp={ThinkingResponse4} PageIndex={21} />}
            />
            <Route
              path="/part_1/causal_thoughts"
              element={<Protected Cmp={CasualThought} PageIndex={16} />}
            />
            <Route
              path="/part_1/what_is_a_causal_thought"
              element={<Protected Cmp={WhatsCasualThought} PageIndex={15} />}
            />
            <Route
              path="/part_1/checklist_of_errors"
              element={
                <Protected Cmp={ChecklistCognitiveErrors} PageIndex={17} />
              }
            />
            <Route
              path="/part_1/lets_review"
              element={<Protected Cmp={LetsReview} PageIndex={11} />}
            />
            <Route
              path="/part_1/complete"
              element={<Protected Cmp={PartComplete} PageIndex={22} />}
            />
            <Route
              path="/part_2/challenge_example"
              element={<Protected Cmp={ChallengeExample} PageIndex={23} />}
            />
            <Route
              path="/part_2/challenge_1"
              element={<Protected Cmp={Challenge1} PageIndex={24} />}
            />
            <Route
              path="/part_2/challenge_2"
              element={<Protected Cmp={Challenge2} PageIndex={26} />}
            />
            <Route
              path="/part_2/challenge_3"
              element={<Protected Cmp={Challenge3} PageIndex={28} />}
            />
            <Route
              path="/part_2/challenge_4"
              element={<Protected Cmp={Challenge4} PageIndex={30} />}
            />
            <Route
              path="/part_2/challenge_5"
              element={<Protected Cmp={Challenge5} PageIndex={32} />}
            />
            <Route
              path="/part_2/challenge_1_sample_answers"
              element={<Protected Cmp={ChallengeResponse1} PageIndex={25} />}
            />
            <Route
              path="/part_2/challenge_2_sample_answers"
              element={<Protected Cmp={ChallengeResponse2} PageIndex={27} />}
            />
            <Route
              path="/part_2/challenge_3_sample_answers"
              element={<Protected Cmp={ChallengeResponse3} PageIndex={29} />}
            />
            <Route
              path="/part_2/challenge_4_sample_answers"
              element={<Protected Cmp={ChallengeResponse4} PageIndex={31} />}
            />
            <Route
              path="/part_2/challenge_5_sample_answers"
              element={<Protected Cmp={ChallengeResponse5} PageIndex={33} />}
            />
            <Route
              path="/part_2/complete"
              element={<Protected Cmp={Part2complete} PageIndex={33} />}
            />
            <Route
              path="/part_3/intro"
              element={<Protected Cmp={Part3Reached} PageIndex={34} />}
            />
            <Route
              path="/part_3/avoidance_and_safety_behaviours"
              element={
                <Protected Cmp={AvoidanceSafetyBehaviours} PageIndex={35} />
              }
            />
            <Route
              path="/part_3/another_example_of_safety_behaviours"
              element={<Protected Cmp={ExampleBehaviours} PageIndex={36} />}
            />
            <Route
              path="/part_3/lets_recap"
              element={<Protected Cmp={LetsRecap} PageIndex={37} />}
            />
            <Route
              path="/part_3/causal_thoughts_and_safety_behaviours"
              element={<Protected Cmp={CasualBehaviours} PageIndex={38} />}
            />
            <Route
              path="/part_3/more_safety_behaviours"
              element={<Protected Cmp={MoreSafetyBehaviours} PageIndex={39} />}
            />
            <Route
              path="/part_3/bens_model"
              element={<Protected Cmp={SocialAnxiety} PageIndex={40} />}
            />
            <Route
              path="/part_3/bens_model_2"
              element={<Protected Cmp={AnxietyModel} PageIndex={41} />}
            />
            <Route
              path="/part_3/safety_behaviours"
              element={<Protected Cmp={SafetyBehaviours} PageIndex={42} />}
            />
            <Route
              path="/part_3/physiological_anxiety_symptoms"
              element={<Protected Cmp={AnxietySymptoms} PageIndex={43} />}
            />
            <Route
              path="/part_3/self-focussed_imagery"
              element={<Protected Cmp={SelfFocusedImagery} PageIndex={44} />}
            />
            <Route
              path="/part_3/your_model"
              element={<Protected Cmp={YourModel} PageIndex={45} />}
            />
            <Route
              path="/part_3/your_model_of_social_anxiety"
              element={<Protected Cmp={YourModelAnxiety} PageIndex={46} />}
            />
            <Route
              path="/part_3/complete"
              element={<Protected Cmp={Part3complete} PageIndex={46} />}
            />
            <Route
              path="/part_4/intro"
              element={<Protected Cmp={Part4FirstPage} PageIndex={47} />}
            />
            <Route
              path="/part_4/perfectionism"
              element={<Protected Cmp={Part4SecondPage} PageIndex={48} />}
            />
            <Route
              path="/part_4/why_it_really_doesnt_matter"
              element={<Protected Cmp={Part4ThirdPage} PageIndex={49} />}
            />
            <Route
              path="/part_4/time_to_plan"
              element={<Protected Cmp={Part4FourthPage} PageIndex={50} />}
            />
            <Route
              path="/part_4/predictions"
              element={<Protected Cmp={Part4FifthPage} PageIndex={51} />}
            />
            <Route
              path="/part_4/prediction_probabilities"
              element={<Protected Cmp={Part4SixthPage} PageIndex={52} />}
            />
            <Route
              path="/part_4/the_experiment"
              element={<Protected Cmp={Part4SeventhPage} PageIndex={53} />}
            />
            <Route
              path="/part_4/experiment_completed"
              element={<Protected Cmp={Part4EighthPage} PageIndex={54} />}
            />
            <Route
              path="/part_4/perceived_negative_evaluations"
              element={<Protected Cmp={Part4NinthPage} PageIndex={55} />}
            />
            <Route
              path="/part_4/behavioural_causal_thought"
              element={<Protected Cmp={Part4TenthPage} PageIndex={56} />}
            />
            <Route
              path="/part_4/behavioural_causal_thought_sample_answers"
              element={<Protected Cmp={Part4EleventhPage} PageIndex={57} />}
            />
            <Route
              path="/part_4/what_was_learned"
              element={<Protected Cmp={Part4TwelfthPage} PageIndex={58} />}
            />
            <Route
              path="/part_4/avoid"
              element={<Protected Cmp={Part4ThirteenthPage} PageIndex={59} />}
            />
            <Route
              path="/part_4/continue"
              element={<Protected Cmp={Part4FourteenthPage} PageIndex={59} />}
            />
            <Route
              path="/part_4/complete"
              element={<Protected Cmp={Part4complete} PageIndex={59} />}
            />
            <Route
              path="/part_5/essay"
              element={<Protected Cmp={WelcomeToPart5} PageIndex={60} />}
            />
            <Route
              path="/part_5/should_statements"
              element={<Protected Cmp={Part5First} PageIndex={61} />}
            />
            <Route
              path="/part_5/tyranny_of_the_shoulds"
              element={<Protected Cmp={Part5Second} PageIndex={62} />}
            />
            <Route
              path="/part_5/what_do_my_shoulds_do_for_me"
              element={<Protected Cmp={Part5Third} PageIndex={63} />}
            />
            <Route
              path="/part_5/advantages_and_disadvantages_of_shoulds"
              element={<Protected Cmp={Part5Fourth} PageIndex={64} />}
            />
            <Route
              path="/part_5/should_sample_answers"
              element={<Protected Cmp={Part5Fifth} PageIndex={65} />}
            />
            <Route
              path="/part_5/complete"
              element={<Protected Cmp={Part5complete} PageIndex={65} />}
            />
            <Route
              path="/part_6/the_problem_of_self-conciousness"
              element={<Protected Cmp={WelcomeToPart6} PageIndex={66} />}
            />
            <Route
              path="/part_6/gaining_control"
              element={<Protected Cmp={Part6FirstPage} PageIndex={67} />}
            />
            <Route
              path="/part_6/att"
              element={<Protected Cmp={Part6SecondPage} PageIndex={68} />}
            />
            <Route
              path="/part_6/sar"
              element={<Protected Cmp={Part6ThirdPage} PageIndex={69} />}
            />
            <Route
              path="/part_6/sar_responses"
              element={<Protected Cmp={Part6FourthPage} PageIndex={70} />}
            />
            <Route
              path="/part_6/mental_imagery"
              element={<Protected Cmp={Part6FifthPage} PageIndex={71} />}
            />
            <Route
              path="/part_6/more_observer_bias"
              element={<Protected Cmp={Part6SixthPage} PageIndex={72} />}
            />
            <Route
              path="/part_6/complete"
              element={<Protected Cmp={Part6complete} PageIndex={72} />}
            />
            <Route
              path="/part_7/preventing_relapse"
              element={<Protected Cmp={Part7FirstPage} PageIndex={73} />}
            />
            <Route
              path="/part_7/preventing_relapse_2"
              element={<Protected Cmp={Part7SecondPage} PageIndex={74} />}
            />
            <Route
              path="/part_7/postevent_rumination"
              element={<Protected Cmp={Part7ThirdPage} PageIndex={75} />}
            />
            <Route
              path="/part_7/ways_to_protect_yourself"
              element={<Protected Cmp={Part7FourthPage} PageIndex={76} />}
            />
            <Route
              path="/part_7/a_final_thought"
              element={<Protected Cmp={Part7FifthPage} PageIndex={77} />}
            />
            <Route
              path="/part_7/handouts"
              element={<Protected Cmp={Part7SixthPage} PageIndex={78} />}
            />
            <Route
              path="/handout_1"
              element={<Protected Cmp={Handout1} PageIndex={78} />}
            />
            <Route
              path="/handout_2"
              element={<Protected Cmp={Handout2} PageIndex={78} />}
            />
            <Route
              path="/handout_3"
              element={<Protected Cmp={Handout3} PageIndex={78} />}
            />
            <Route
              path="/handout_4"
              element={<Protected Cmp={Handout4} PageIndex={78} />}
            />
            <Route
              path="/handout_5"
              element={<Protected Cmp={Handout5} PageIndex={78} />}
            />
            <Route
              path="/handout_6"
              element={<Protected Cmp={Handout6} PageIndex={78} />}
            />
            <Route
              path="/part_7/complete1"
              element={<Protected Cmp={Part7complete} PageIndex={78} />}
            />
            <Route
              path="/part_7/utbas_questionnaire"
              element={
                <Protected Cmp={PostUtbasQuestionnaire} PageIndex={79} />
              }
            />
            <Route
              path="/part_7/avoidance_questionnaire"
              element={
                <Protected Cmp={PostAvoidanceQuestionnaire} PageIndex={80} />
              }
            />
            <Route
              path="/part_7/dass_questionnaire"
              element={<Protected Cmp={PostDassQuestionnaire} PageIndex={81} />}
            />
            <Route
              path="/part_7/fne_questionnaire"
              element={<Protected Cmp={PostFneQuestionnaire} PageIndex={82} />}
            />
            <Route
              path="/part_7/complete"
              element={<Protected Cmp={CompleteProgram} PageIndex={83} />}
            />
            <Route
              path="/admin/panel"
              element={<Protected Cmp={UsersInfoList} />}
            />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route
              path="/home"
              element={<Protected Cmp={WelcomePage} PageIndex={1} />}
            />
            <Route path="/terms-of-use" element={<TermsConditions />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/footer" element={<StaticFooter />} />
            <Route path="/useIdleTimer" element={<UseIdleTimer />} />
            <Route path="/video" element={<VideoPlaying />} />
            <Route
              path="/userBadge"
              element={<Protected Cmp={UserBadge} PageIndex={2} />}
            />
          </Routes>
        </Router>
      );
    } else {
      return <Fallback />;
    }
  } else {
    <>
      <div className="loader-icon">
        <TailSpin color="#4f5962" height={50} width={50} />
      </div>
    </>;
  }
};

export default App;
