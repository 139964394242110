import React from "react";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import { options } from "../../../../constants/pretreatment/AvoidanceQuestionnaire";
import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";

const AvoidanceQuestionnaire = () => {
  const [questionnaire, errorMessage] = GetQuestionnaire("Avoidance"); // Questions list from API

  /*******   Page Params  *******/

  const title = "Avoidance Questionnaire";

  const content =
    "<p>The following questionnaire is also a very crucial component of this program. So, if you want maximum benefit from our treatment package, you need to think carefully about your answers to the questions below. Please indicate accurately on a range from 'Does not apply to me' to ‘All the time’ how often you avoid the following situations. It is extremely important for your progress that you give honest responses.</p>";

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>Avoidance Questionnaire | ASRC iBroadway</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/welcome_user"
        questions={questionnaire}
        options={options}
        type={"Avoidance"}
        error={errorMessage}
        previousLink="intro/utbas_questionnaire"
      />
    </div>
  );
};

export default AvoidanceQuestionnaire;
