import React, { useEffect, useState } from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const CompletePageUserResult = () => {


    const [userResult, setUserResult] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const params = JSON.stringify({
        "": ""
    });

    const fetchUserResult = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const response = await axios.post(BASE_URL + `compleate_page/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                },
            })
            setUserResult(response.data);
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchUserResult();
            }
            else if (error.response.status === 403 || error.response.status === 500 || error.response.status === 503) {
                setErrorMessage('Internal Server Error, Please Try After Sometime!');
                ErrorLogging('HTTP GET /getQuestionnairsBycatecory' + error);
            }
            else {
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP POST /compleate_page' + error);
            }
        }
    };

    useEffect(() => {
        fetchUserResult();
    }, []);

    return [userResult, errorMessage];
};

export {
    CompletePageUserResult,
}