import { GetQuestionnaire } from '../../../../services/pretreatment/get-questionnaire';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import React from 'react';
import { options } from '../../../../constants/pretreatment/DassQuestionnaire';

const DassQuestionnaire = () => {

    const [questionnaire, errorMessage] = GetQuestionnaire("DASS");     // Questions list from API
    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    /*******   Page Params  *******/

    const title = "Mood Scale";

    const content = `<p>Phew! Last questionnaire – well done <span class="normal-text-black">${user?.userInfo?.Fname}  ${user?.userInfo?.Lname}</span>. We all feel a bit down at times and this final questionnaire is going to help the program better understand how happy or sad you’ve been feeling lately. 
    </br>
    Please read each statement and select a number (0, 1, 2 or 3) which indicates how much the statement applied to you over the past week. There are no right or wrong answers. Do not spend too much time on any statement.</p> <p class='listing'>0 - Rarely or none of the time.</p> <p class='listing'>1 - Some or a little of the time.</p> <p class='listing'>2 - Occasionally or a moderate amount of time.</p><p class='listing mb-0'>3 - Most or all of the time.</p>`;

    /*******   Render  *******/

    return (
        <div>
            <Helmet>
                <title>Mood Scale | ASRC iBroadway</title>
            </Helmet>
            <Questionnaire
                title={title}
                content={content}
                link='part_1/anxiety_is_important_in_stuttering'
                questions={questionnaire}
                options={options}
                type={'DASS'}
                error={errorMessage}
                previousLink='intro/fne_questionnaire'
            />
        </div>
    );
};

export default DassQuestionnaire;