import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../styles/dashboard-style.css';
import Header from '../../components/global/Header';
import { color } from '@mui/system';
import StaticFooter from './StaticFooter';

const Disclaimer = () => {

    return (
        <>
         <div className="dashboardContent error-template">
            <Helmet>
                <title>Disclaimer | ASRC iGlebe</title>
            </Helmet>
            <div>
                <Header />
            </div>
            <div>

            <div className='row' >
                    <div className='col-md-3'></div>
                    <div className="col-lg-6 region region-content1">
                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" id='heading' >

                                Disclaimer

                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The University of Technology, Sydney (UTS) regulates publishing on the UTSWeb through its <span className='breakWord'> http://www.gsu.uts.edu.au/policies/webpolicy.html.</span> The conditions that must be observed, relating to currency and accuracy of information and relevant legal requirements, are defined by this Policy. UTS continually seeks to have the information on any of its sites as up to date and as accurate as possible.
                                </p>

                                <p>
                                    The UTSWeb may occasionally include links to reference information published on sites external to the UTSWeb. These links are provided for convenience, and should not be taken as an indication that UTS endorses or is responsible for the information or views published on these sites.
                                </p>

                                <p>
                                    While UTS makes every effort to make sure the information on this website is accurate and informative, the information does not take the place of professional or medical advice. You should obtain advice relevant to your particular circumstances from a health professional. To the maximum extent permitted by law, UTS excludes all liability for any loss or injury arising out of, or related to, the use, inability to use, authorised use, performance, or non-performance of sites on the UTSWeb however caused, including by negligence.
                                </p>
                                <p>
                                    Persons intending to act on information published on the UTSWeb should be aware that information on our website is subject to change without notice.
                                </p>
                            </div>
                        </div>



                        <div id="block-satp-user-content" class="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para"> Copyright Notice</h3>
                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    UTS operates in accordance with the Copyright Act 1968 (Cwlth), and information published on the UTSWeb is subject to this Act and equivalent copyright acts in other territories. Unless otherwise attributed, UTS, and individuals and organisations that are permitted to publish on the UTSWeb, own the copyright to the material they publish.
                                </p>
                                <p>
                                    Information on the UTSWeb may be utilised in unaltered form for personal use or other uses permitted by the Copyright Act. Copyright must be properly attributed and permission must be sought before publication for any other use, especially commercial uses, which reproduces information found on the UTSWeb.
                                </p>
                                <p>
                                    Users of information on the UTSWeb should act according to relevant copyright laws and the copyright details placed on websites on the UTSWeb. These details are generally located in the footer of UTSWeb sites, however occasionally there may be additional copyright attribution to information on some pages
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-3'></div>
                   <div className='fixedDiv col-md-12'>
                        <StaticFooter/>
                    </div>
                </div>
            </div>
        </div>
   
        </>
    );
};

export default Disclaimer;