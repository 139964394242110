import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthService from "../../../services/auth-service";
import { DOMAIN_NAME } from "../../../env/Baseurl";
import { BASE_URL } from "../../../env/Baseurl";
import "../../../styles/login.css";
import axios from "axios";
import { Helmet } from "react-helmet";

const ChangePassword = () => {
  const navigate = useNavigate();

  /*******   States  *******/
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emptyCheck, setEmptyCheck] = useState({
    password: "",
    confirmPassword: "",
  });

  // show password
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassShown, setConfirmPassShown] = useState(false);

  // success and error messages
  const [message, setMessage] = useState("");

  /*******   HelperFunctions  *******/
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPassShown(confirmPassShown ? false : true);
  };
  // password change
  const passwordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);
  };

  // confirmPassword

  const confirmPass = (e) => {
    const confirm = e.target.value;
    setConfirmPassword(confirm);
  };

  // changepassword function

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === "" || confirmPassword === "") {
      {
        password === ""
          ? setEmptyCheck({
              password: "Please fill out Password field to continue.",
            })
          : setEmptyCheck({
              confirmPassword:
                "Please fill out Confirm Password field to continue.",
            });
      }
    } else if (password !== confirmPassword) {
      setEmptyCheck({
        confirmPassword: "Password and Confirm Password do not match.",
      });
    } else if (password === confirmPassword) {
      try {
        let dataObject = JSON.stringify({
          newPassword: password,
          conformPassword: confirmPassword,
        });

        const response = await axios.post(
          BASE_URL + `forgetpassword_passwordchange/${searchParams.get("_id")}`,
          dataObject,
          {
            headers: {
              Authorization: `Bearer ${searchParams.get("token")}`,
              "content-type": "application/json",
            },
          }
        );

        setMessage(response.data.message);
      } catch (error) {
        if (error.response.status === 401) {
          setMessage(
            "Time Limit Exceded, Please Resend Reset verification Mail!"
          );
        }
      }
    }
  };

  // useEffect for protected

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  // useEffect for password change
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        message === "Time Limit Exceded, Please Resend Reset verification Mail!"
      ) {
        navigate("/reset-password", { replace: true });
      } else if (message === "Password updated successfully") {
        navigate("/", { replace: true });
      }
    }, 2000);
  }, [message]);

  /*******   Render  *******/

  return (
    <div className="d-flex container align-items-center login-container-wrap justify-content-center">
      <Helmet>
        <title>Reset your Password | ASRC iBroadway</title>
      </Helmet>
      <div className="row align-items-center login-col-wrap justify-content-center">
        {/* <div className="col-md-6">
                    <div className="text-center">
                        <img
                            height="auto"
                            width="500px"
                            src={`${IMAGE_URL}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div> */}
        <div className="col-12 col-md-8 col-lg-6">
          <div>
            <h3 className="text-center pagehead-texttitle">Reset Password</h3>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group forminut-passeye">
                  <div className="labelSection">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="input-label-style"
                    >
                      Password
                    </label>
                  </div>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control form-control-icon-space"
                    id="exampleInputPassword1"
                    placeholder="Your New Password"
                    onChange={passwordChange}
                  />
                  <div
                    className="passShow-eye"
                    onClick={togglePasswordVisiblity}
                  >
                    <i
                      className={
                        passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                  {password === "" && (
                    <small className="text-danger input-label-style error-message-style">
                      {emptyCheck.password}
                    </small>
                  )}
                </div>

                <div className="form-group forminut-confirmPassShow">
                  <div className="confirmpass-label-ins">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="input-label-style"
                    >
                      Confirm Password
                    </label>
                    <input
                      type={confirmPassShown ? "text" : "password"}
                      className="form-control form-control-icon-space"
                      id="exampleInputPassword2"
                      placeholder="Confirm New Password"
                      onChange={confirmPass}
                    />
                    <div
                      className="confirmPassShow-eye"
                      onClick={toggleConfirmPasswordVisiblity}
                    >
                      <i
                        className={
                          confirmPassShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>

                    {confirmPassword === "" ||
                    password !== confirmPassword ||
                    emptyCheck.confirmPassword === "Password match." ? (
                      <>
                        <small
                          className={
                            emptyCheck.confirmPassword === "Password match."
                              ? "text-success password-match-instr error-message-style"
                              : "text-danger password-match-instr error-message-style"
                          }
                        >
                          {emptyCheck.confirmPassword}
                        </small>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group login-submit">
                    <button
                      type="submit"
                      className="btn blue-style-btns btn-sm btn-block"
                      style={{
                        fontSize: "20px",
                        lineHeight: "21px",
                        height: "53px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {/* for message whether the password is set or not */}

            {message && (
              <div
                className="form-group px-3 pt-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className={
                    message === "Password updated successfully"
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
