// const options = [
//      "Never have the thought",
//      "Rarely have the thought",
//      "Sometimes have the thought",
//      "Often have the thought",
//      "Always have the thought"
//     ]; 
const options = [
    "Never or not at all",
    "Rarely or a little",
    "Sometimes or somewhat",
    "Often or a lot",
    "Always or totally"
];

export {
    options
};
