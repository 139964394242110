import "../../../../styles/adminlte.min.css";

import { Button, InputGroup, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import ReactTooltip from "react-tooltip";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

const ExcerciseAnswersHandout = ({
  title,
  content,
  responses,
  link,
  type,
  sitnum,
  error,
}) => {
  let navigate = useNavigate();
  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };
  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-10 content-widthsidebar content-wrapper"
          >
            {/* <div className="content"> */}
            {/* <div className="row header-titlewith-text m-0">
                                <div className="col-lg-6 p-0 main-title-style">
                                    {title}
                                </div>
                                <div className="col-lg-6 text-left p-0 mt-3 mt-lg-0 text-lg-right">
                                    <span> Welcome</span><span> / {title}</span>
                                </div>
                            </div> */}
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <div className="title page-title-style  m-0">{title}</div>
                  <div className="card-body">
                    <div>
                      <p className="normal-text">{content}</p>
                      {error ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {error}
                            </div>
                          </div>
                        </div>
                      ) : responses !== undefined && responses.length !== 0 ? (
                        <div className="content-wrap">
                          <div className="content-topheads-text">
                            <p className="component-sub-heading-style">
                              Situation: {sitnum}
                            </p>
                            <p>{responses.Situation}</p>
                            <div className="thinkingEx">
                              {responses.Exerciseresults?.map((item, index) => {
                                const renderSample =
                                  item.thinkingExcercise_id.sampleAnswear;
                                const card = (
                                  <div className="row mb-lg-3" key={index}>
                                    <div className="col-lg-6">
                                      <table
                                        className="thinking_table left_table"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr className="questions">
                                            <td>
                                              YOUR ANSWERS :<br />
                                              {
                                                item.thinkingExcercise_id
                                                  .yourAnswear
                                              }
                                            </td>
                                          </tr>
                                          <tr className="answers">
                                            <td>{item.userResponce}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="col-lg-6">
                                      <table
                                        className="thinking_table left_table"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr className="questions">
                                            <td>
                                              {index === 0 &&
                                                "OUR SAMPLE ANSWERS :"}
                                              {index === 0 && <br />}
                                              {
                                                item.thinkingExcercise_id
                                                  .ourSampleanswear
                                              }
                                            </td>
                                          </tr>
                                          <tr className="answers">
                                            <td>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: renderSample,
                                                }}
                                              ></div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                );
                                return card;
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
                    <FooterOfPrint />
                  </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcerciseAnswersHandout;
