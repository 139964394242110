const country_list = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Brunei', label: 'Brunei' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Cote d\'Ivoire', label: 'Cote d\'Ivoire' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Eswatini', label: 'Eswatini' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Korea, North', label: 'Korea, North' },
    { value: 'Korea, South', label: 'Korea, South' },
    { value: 'Kosovo', label: 'Kosovo' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia', label: 'Micronesia' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'North Macedonia', label: 'North Macedonia' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Sudan', label: 'South Sudan' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria', label: 'Syria' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Timor-Leste', label: 'Timor-Leste' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican City', label: 'Vatican City' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' }
]


const countries_list = [
    { value: 'Afghanistan', label: 'Afghanistan', code: 'AF', dial_code: '+93' },
    { value: 'Albania', label: 'Albania', code: 'AL', dial_code: '+355' },
    { value: 'Algeria', label: 'Algeria', code: 'DZ', dial_code: '+213' },
    { value: 'American Samoa', label: 'American Samoa', code: 'AS', dial_code: '+1-684' },
    { value: 'Andorra', label: 'Andorra', code: 'AD', dial_code: '+376' },
    { value: 'Angola', label: 'Angola', code: 'AO', dial_code: '+244' },
    { value: 'Anguilla', label: 'Anguilla', code: 'AI', dial_code: '+1-264' },
    { value: 'Antarctica', label: 'Antarctica', code: 'AQ', dial_code: '+672' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda', code: 'AG', dial_code: '+1-268' },
    { value: 'Argentina', label: 'Argentina', code: 'AR', dial_code: '+54' },
    { value: 'Armenia', label: 'Armenia', code: 'AM', dial_code: '+374' },
    { value: 'Aruba', label: 'Aruba', code: 'AW', dial_code: '+297' },
    { value: 'Australia', label: 'Australia', code: 'AU', dial_code: '+61' },
    { value: 'Austria', label: 'Austria', code: 'AT', dial_code: '+43' },
    { value: 'Azerbaijan', label: 'Azerbaijan', code: 'AZ', dial_code: '+994' },
    { value: 'Bahamas', label: 'Bahamas', code: 'BS', dial_code: '+1-242' },
    { value: 'Bahrain', label: 'Bahrain', code: 'BH', dial_code: '+973' },
    { value: 'Bangladesh', label: 'Bangladesh', code: 'BD', dial_code: '+880' },
    { value: 'Barbados', label: 'Barbados', code: 'BB', dial_code: '+1-246' },
    { value: 'Belarus', label: 'Belarus', code: 'BY', dial_code: '+375' },
    { value: 'Belgium', label: 'Belgium', code: 'BE', dial_code: '+32' },
    { value: 'Belize', label: 'Belize', code: 'BZ', dial_code: '+501' },
    { value: 'Benin', label: 'Benin', code: 'BJ', dial_code: '+229' },
    { value: 'Bermuda', label: 'Bermuda', code: 'BM', dial_code: '+1-441' },
    { value: 'Bhutan', label: 'Bhutan', code: 'BT', dial_code: '+975' },
    { value: 'Bolivia', label: 'Bolivia', code: 'BO', dial_code: '+591' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina', code: 'BA', dial_code: '+387' },
    { value: 'Botswana', label: 'Botswana', code: 'BW', dial_code: '+267' },
    { value: 'Brazil', label: 'Brazil', code: 'BR', dial_code: '+55' },
    { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory', code: 'IO', dial_code: '+246' },
    { value: 'British Virgin Islands', label: 'British Virgin Islands', code: 'VG', dial_code: '+1-284' },
    { value: 'Brunei', label: 'Brunei', code: 'BN', dial_code: '+673' },
    { value: 'Bulgaria', label: 'Bulgaria', code: 'BG', dial_code: '+359' },
    { value: 'Burkina Faso', label: 'Burkina Faso', code: 'BF', dial_code: '+226' },
    { value: 'Burundi', label: 'Burundi', code: 'BI', dial_code: '+257' },
    { value: 'Cambodia', label: 'Cambodia', code: 'KH', dial_code: '+855' },
    { value: 'Cameroon', label: 'Cameroon', code: 'CM', dial_code: '+237' },
    { value: 'Canada', label: 'Canada', code: 'CA', dial_code: '+1' },
    { value: 'Cape Verde', label: 'Cape Verde', code: 'CV', dial_code: '+238' },
    { value: 'Cayman Islands', label: 'Cayman Islands', code: 'KY', dial_code: '+1-345' },
    { value: 'Central African Republic', label: 'Central African Republic', code: 'CF', dial_code: '+236' },
    { value: 'Chad', label: 'Chad', code: 'TD', dial_code: '+235' },
    { value: 'Chile', label: 'Chile', code: 'CL', dial_code: '+56' },
    { value: 'China', label: 'China', code: 'CN', dial_code: '+86' },
    { value: 'Christmas Island', label: 'Christmas Island', code: 'CX', dial_code: '+61' },
    { value: 'Cocos Islands', label: 'Cocos Islands', code: 'CC', dial_code: '+61' },
    { value: 'Colombia', label: 'Colombia', code: 'CO', dial_code: '+57' },
    { value: 'Comoros', label: 'Comoros', code: 'KM', dial_code: '+269' },
    { value: 'Cook Islands', label: 'Cook Islands', code: 'CK', dial_code: '+682' },
    { value: 'Costa Rica', label: 'Costa Rica', code: 'CR', dial_code: '+506' },
    { value: 'Croatia', label: 'Croatia', code: 'HR', dial_code: '+385' },
    { value: 'Cuba', label: 'Cuba', code: 'CU', dial_code: '+53' },
    { value: 'Curacao', label: 'Curacao', code: 'CW', dial_code: '+599' },
    { value: 'Cyprus', label: 'Cyprus', code: 'CY', dial_code: '+357' },
    { value: 'Czech Republic', label: 'Czech Republic', code: 'CZ', dial_code: '+420' },
    { value: 'Democratic Republic of the Congo', label: 'Democratic Republic of the Congo', code: 'CD', dial_code: '+243' },
    { value: 'Denmark', label: 'Denmark', code: 'DK', dial_code: '+45' },
    { value: 'Djibouti', label: 'Djibouti', code: 'DJ', dial_code: '+253' },
    { value: 'Dominica', label: 'Dominica', code: 'DM', dial_code: '+1-767' },
    { value: 'Dominican Republic', label: 'Dominican Republic', code: 'DO', dial_code: '+1-809 and 1-829' },
    { value: 'Ecuador', label: 'Ecuador', code: 'EC', dial_code: '+593' },
    { value: 'Egypt', label: 'Egypt', code: 'EG', dial_code: '+20' },
    { value: 'El Salvador', label: 'El Salvador', code: 'SV', dial_code: '+503' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea', code: 'GQ', dial_code: '+240' },
    { value: 'Eritrea', label: 'Eritrea', code: 'ER', dial_code: '+291' },
    { value: 'Estonia', label: 'Estonia', code: 'EE', dial_code: '+372' },
    { value: 'Ethiopia', label: 'Ethiopia', code: 'ET', dial_code: '+251' },
    { value: 'Falkland Islands', label: 'Falkland Islands', code: 'FK', dial_code: '+500' },
    { value: 'Faroe Islands', label: 'Faroe Islands', code: 'FO', dial_code: '+298' },
    { value: 'Fiji', label: 'Fiji', code: 'FJ', dial_code: '+679' },
    { value: 'Finland', label: 'Finland', code: 'FI', dial_code: '+358' },
    { value: 'France', label: 'France', code: 'FR', dial_code: '+33' },
    { value: 'French Guiana', label: 'French Guiana', code: 'GF', dial_code: '+594' },
    { value: 'French Polynesia', label: 'French Polynesia', code: 'PF', dial_code: '+689' },
    { value: 'French Southern Territories', label: 'French Southern Territories', code: 'TF', dial_code: '+262' },
    { value: 'Gabon', label: 'Gabon', code: 'GA', dial_code: '+241' },
    { value: 'Gambia', label: 'Gambia', code: 'GM', dial_code: '+220' },
    { value: 'Georgia', label: 'Georgia', code: 'GE', dial_code: '+995' },
    { value: 'Germany', label: 'Germany', code: 'DE', dial_code: '+49' },
    { value: 'Ghana', label: 'Ghana', code: 'GH', dial_code: '+233' },
    { value: 'Gibraltar', label: 'Gibraltar', code: 'GI', dial_code: '+350' },
    { value: 'Greece', label: 'Greece', code: 'GR', dial_code: '+30' },
    { value: 'Greenland', label: 'Greenland', code: 'GL', dial_code: '+299' },
    { value: 'Grenada', label: 'Grenada', code: 'GD', dial_code: '+1473' },
    { value: 'Guadeloupe', label: 'Guadeloupe', code: 'GP', dial_code: '+590' },
    { value: 'Guam', label: 'Guam', code: 'GU', dial_code: '+1671' },
    { value: 'Guatemala', label: 'Guatemala', code: 'GT', dial_code: '+502' },
    { value: 'Guernsey', label: 'Guernsey', code: 'GG', dial_code: '+44' },
    { value: 'Guinea', label: 'Guinea', code: 'GN', dial_code: '+224' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau', code: 'GW', dial_code: '+245' },
    { value: 'Guyana', label: 'Guyana', code: 'GY', dial_code: '+592' },
    { value: 'Haiti', label: 'Haiti', code: 'HT', dial_code: '+509' },
    { value: 'Honduras', label: 'Honduras', code: 'HN', dial_code: '+504' },
    { value: 'Hong Kong', label: 'Hong Kong', code: 'HK', dial_code: '+852' },
    { value: 'Hungary', label: 'Hungary', code: 'HU', dial_code: '+36' },
    { value: 'Iceland', label: 'Iceland', code: 'IS', dial_code: '+354' },
    { value: 'India', label: 'India', code: 'IN', dial_code: '+91' },
    { value: 'Indonesia', label: 'Indonesia', code: 'ID', dial_code: '+62' },
    { value: 'Iran', label: 'Iran', code: 'IR', dial_code: '+98' },
    { value: 'Iraq', label: 'Iraq', code: 'IQ', dial_code: '+964' },
    { value: 'Ireland', label: 'Ireland', code: 'IE', dial_code: '+353' },
    { value: 'Isle of Man', label: 'Isle of Man', code: 'IM', dial_code: '+44' },
    { value: 'Israel', label: 'Israel', code: 'IL', dial_code: '+' },
    { value: "Jamaica", label: "Jamaica", code: "JM", dial_code: "+1-876" },
    { value: "Japan", label: "Japan", code: "JP", dial_code: "+81" },
    { value: "Jordan", label: "Jordan", code: "JO", dial_code: "+962" },
    { value: "Kazakhstan", label: "Kazakhstan", code: "KZ", dial_code: "+7" },
    { value: "Kenya", label: "Kenya", code: "KE", dial_code: "+254" },
    { value: "Kiribati", label: "Kiribati", code: "KI", dial_code: "+686" },
    { value: "Kuwait", label: "Kuwait", code: "KW", dial_code: "+965" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan", code: "KG", dial_code: "+996" },
    { value: "Laos", label: "Laos", code: "LA", dial_code: "+856" },
    { value: "Latvia", label: "Latvia", code: "LV", dial_code: "+371" },
    { value: "Lebanon", label: "Lebanon", code: "LB", dial_code: "+961" },
    { value: "Lesotho", label: "Lesotho", code: "LS", dial_code: "+266" },
    { value: "Liberia", label: "Liberia", code: "LR", dial_code: "+231" },
    { value: "Libya", label: "Libya", code: "LY", dial_code: "+218" },
    { value: "Liechtenstein", label: "Liechtenstein", code: "LI", dial_code: "+423" },
    { value: "Lithuania", label: "Lithuania", code: "LT", dial_code: "+370" },
    { value: "Luxembourg", label: "Luxembourg", code: "LU", dial_code: "+352" },
    { value: "Madagascar", label: "Madagascar", code: "MG", dial_code: "+261" },
    { value: "Malawi", label: "Malawi", code: "MW", dial_code: "+265" },
    { value: "Malaysia", label: "Malaysia", code: "MY", dial_code: "+60" },
    { value: "Maldives", label: "Maldives", code: "MV", dial_code: "+960" },
    { value: "Mali", label: "Mali", code: "ML", dial_code: "+223" },
    { value: "Malta", label: "Malta", code: "MT", dial_code: "+356" },
    { value: "Marshall Islands", label: "Marshall Islands", code: "MH", dial_code: "+692" },
    { value: "Mauritania", label: "Mauritania", code: "MR", dial_code: "+222" },
    { value: "Mauritius", label: "Mauritius", code: "MU", dial_code: "+230" },
    { value: "Mexico", label: "Mexico", code: "MX", dial_code: "+52" },
    { value: "Micronesia", label: "Micronesia", code: "FM", dial_code: "+691" },
    { value: "Moldova", label: "Moldova", code: "MD", dial_code: "+373" },
    { value: "Monaco", label: "Monaco", code: "MC", dial_code: "+377" },
    { value: "Mongolia", label: "Mongolia", code: "MN", dial_code: "+976" },
    { value: "Montenegro", label: "Montenegro", code: "ME", dial_code: "+382" },
    { value: "Morocco", label: "Morocco", code: "MA", dial_code: "+212" },
    { value: "Mozambique", label: "Mozambique", code: "MZ", dial_code: "+258" },
    { value: "Myanmar", label: "Myanmar", code: "MM", dial_code: "+95" },
    { value: 'Namibia', label: 'Namibia', code: 'NA', dial_code: '+264' },
    { value: 'Nauru', label: 'Nauru', code: 'NR', dial_code: '+674' },
    { value: 'Nepal', label: 'Nepal', code: 'NP', dial_code: '+977' },
    { value: 'Netherlands', label: 'Netherlands', code: 'NL', dial_code: '+31' },
    { value: 'New Caledonia', label: 'New Caledonia', code: 'NC', dial_code: '+687' },
    { value: 'New Zealand', label: 'New Zealand', code: 'NZ', dial_code: '+64' },
    { value: 'Nicaragua', label: 'Nicaragua', code: 'NI', dial_code: '+505' },
    { value: 'Niger', label: 'Niger', code: 'NE', dial_code: '+227' },
    { value: 'Nigeria', label: 'Nigeria', code: 'NG', dial_code: '+234' },
    { value: 'Niue', label: 'Niue', code: 'NU', dial_code: '+683' },
    { value: 'Norfolk Island', label: 'Norfolk Island', code: 'NF', dial_code: '+672' },
    { value: 'North Korea', label: 'North Korea', code: 'KP', dial_code: '+850' },
    { value: 'North Macedonia', label: 'North Macedonia', code: 'MK', dial_code: '+389' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands', code: 'MP', dial_code: '+1670' },
    { value: 'Norway', label: 'Norway', code: 'NO', dial_code: '+47' },
    { value: 'Oman', label: 'Oman', code: 'OM', dial_code: '+968' },
    { value: 'Pakistan', label: 'Pakistan', code: 'PK', dial_code: '+92' },
    { value: 'Palau', label: 'Palau', code: 'PW', dial_code: '+680' },
    { value: 'Palestine', label: 'Palestine', code: 'PS', dial_code: '+970' },
    { value: 'Panama', label: 'Panama', code: 'PA', dial_code: '+507' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea', code: 'PG', dial_code: '+675' },
    { value: 'Paraguay', label: 'Paraguay', code: 'PY', dial_code: '+595' },
    { value: 'Peru', label: 'Peru', code: 'PE', dial_code: '+51' },
    { value: 'Philippines', label: 'Philippines', code: 'PH', dial_code: '+63' },
    { value: 'Pitcairn', label: 'Pitcairn', code: 'PN', dial_code: '+64' },
    { value: 'Poland', label: 'Poland', code: 'PL', dial_code: '+48' },
    { value: 'Portugal', label: 'Portugal', code: 'PT', dial_code: '+351' },
    { value: 'Puerto Rico', label: 'Puerto Rico', code: 'PR', dial_code: '+1' },
    { value: 'Qatar', label: 'Qatar', code: 'QA', dial_code: '+974' },
    { value: 'Russia', label: 'Russia', code: 'RU', dial_code: '+7' },
    { value: 'Rwanda', label: 'Rwanda', code: 'RW', dial_code: '+250' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis', code: 'KN', dial_code: '+1' },
    { value: 'Saint Lucia', label: 'Saint Lucia', code: 'LC', dial_code: '+1' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines', code: 'VC', dial_code: '+1' },
    { value: 'Samoa', label: 'Samoa', code: 'WS', dial_code: '+685' },
    { value: 'San Marino', label: 'San Marino', code: 'SM', dial_code: '+378' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe', code: 'ST', dial_code: '+239' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia', code: 'SA', dial_code: '+966' },
    { value: 'Senegal', label: 'Senegal', code: 'SN', dial_code: '+221' },
    { value: 'Serbia', label: 'Serbia', code: 'RS', dial_code: '+381' },
    { value: 'Seychelles', label: 'Seychelles', code: 'SC', dial_code: '+248' },
    { value: 'Sierra Leone', label: 'Sierra Leone', code: 'SL', dial_code: '+232' },
    { value: 'Singapore', label: 'Singapore', code: 'SG', dial_code: '+65' },
    { value: 'Slovakia', label: 'Slovakia', code: 'SK', dial_code: '+421' },
    { value: 'Slovenia', label: 'Slovenia', code: 'SI', dial_code: '+386' },
    { value: 'Solomon Islands', label: 'Solomon Islands', code: 'SB', dial_code: '+677' },
    { value: 'Somalia', label: 'Somalia', code: 'SO', dial_code: '+252' },
    { value: 'South Africa', label: 'South Africa', code: 'ZA', dial_code: '+27' },
    { value: 'South Sudan', label: 'South Sudan', code: 'SS', dial_code: '+211' },
    { value: 'Spain', label: 'Spain', code: 'ES', dial_code: '+34' },
    { value: 'Sri Lanka', label: 'Sri Lanka', code: 'LK', dial_code: '+94' },
    { value: 'Sudan', label: 'Sudan', code: 'SD', dial_code: '+249' },
    { value: 'Suriname', label: 'Suriname', code: 'SR', dial_code: '+597' },
    { value: 'Swaziland', label: 'Swaziland', code: 'SZ', dial_code: '+268' },
    { value: 'Sweden', label: 'Sweden', code: 'SE', dial_code: '+46' },
    { value: 'Switzerland', label: 'Switzerland', code: 'CH', dial_code: '+41' },
    { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic', code: 'SY', dial_code: '+963' },
    { value: 'Taiwan', label: 'Taiwan', code: 'TW', dial_code: '+886' },
    { value: 'Tajikistan', label: 'Tajikistan', code: 'TJ', dial_code: '+992' },
    { value: 'Tanzania', label: 'Tanzania', code: 'TZ', dial_code: '+255' },
    { value: 'Thailand', label: 'Thailand', code: 'TH', dial_code: '+66' },
    { value: 'Timor-Leste', label: 'Timor-Leste', code: 'TL', dial_code: '+670' },
    { value: 'Togo', label: 'Togo', code: 'TG', dial_code: '+228' },
    { value: 'Tokelau', label: 'Tokelau', code: 'TK', dial_code: '+690' },
    { value: 'Tonga', label: 'Tonga', code: 'TO', dial_code: '+676' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago', code: 'TT', dial_code: '+1868' },
    { value: 'Tunisia', label: 'Tunisia', code: 'TN', dial_code: '+216' },
    { value: 'Turkey', label: 'Turkey', code: 'TR', dial_code: '+90' },
    { value: 'Turkmenistan', label: 'Turkmenistan', code: 'TM', dial_code: '+993' },
    { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands', code: 'TC', dial_code: '+1649' },
    { value: 'Tuvalu', label: 'Tuvalu', code: 'TV', dial_code: '+688' },
    { value: 'Uganda', label: 'Uganda', code: 'UG', dial_code: '+256' },
    { value: 'Ukraine', label: 'Ukraine', code: 'UA', dial_code: '+380' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates', code: 'AE', dial_code: '+971' },
    { value: 'United Kingdom', label: 'United Kingdom', code: 'GB', dial_code: '+44' },
    { value: 'United States', label: 'United States', code: 'US', dial_code: '+1' },
    { value: 'Uruguay', label: 'Uruguay', code: 'UY', dial_code: '+598' },
    { value: 'Uzbekistan', label: 'Uzbekistan', code: 'UZ', dial_code: '+998' },
    { value: 'Vanuatu', label: 'Vanuatu', code: 'VU', dial_code: '+678' },
    { value: 'Venezuela', label: 'Venezuela', code: 'VE', dial_code: '+58' },
    { value: 'Vietnam', label: 'Vietnam', code: 'VN', dial_code: '+84' },
    { value: 'Wallis and Futuna', label: 'Wallis and Futuna', code: 'WF', dial_code: '+681' },
    { value: 'Western Sahara', label: 'Western Sahara', code: 'EH', dial_code: '+212' },
    { value: 'Yemen', label: 'Yemen', code: 'YE', dial_code: '+967' },
    { value: 'Zambia', label: 'Zambia', code: 'ZM', dial_code: '+260' },
    { value: 'Zimbabwe', label: 'Zimbabwe', code: 'ZW', dial_code: '+263' },
]



export { country_list, countries_list };