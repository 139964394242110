import axios from "axios";

import { BASE_URL } from '../env/Baseurl';
import ErrorLogging from './error-logs';
import RefreshToken from "./refresh-token";

const ModuleCompletionEmails =  async (partNum) => {
    
    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

        try{
            const params = JSON.stringify({
                "data":  ''
            });
   
            const response = await axios.post(BASE_URL + `${partNum}/${user.userInfo._id}`, params, {
                "headers": {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${user.token}`,
                },
            })

        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                ModuleCompletionEmails();
            }
            else{
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
            }

        }
    
  
};

export default ModuleCompletionEmails;