import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { logout } from "../actions/auth";
import { useDispatch } from "react-redux";


const UseIdleTimer = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const timeout = 10 * 60 * 1000
    // const timeout = 20 * 1000

    const [remaining, setRemaining] = useState(timeout)
    const [elapsed, setElapsed] = useState(0)
    const [lastActive, setLastActive] = useState(+new Date())
    const [isIdle, setIsIdle] = useState(false)

    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => {
        logOut();
    }
    const {
        getRemainingTime,
        getLastActiveTime,
        getElapsedTime
    } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    })

    //Logout Function
    const logOut = () => {
        localStorage.setItem("userState", 'false');
        dispatch(logout());
        if (localStorage.getItem('userState') === 'false') {
            navigate('/');
        }
    };

    useEffect(() => {
        setRemaining(getRemainingTime())
        setLastActive(getLastActiveTime())
        setElapsed(getElapsedTime())

        setInterval(() => {
            setRemaining(getRemainingTime())
            setLastActive(getLastActiveTime())
            setElapsed(getElapsedTime())
        }, 1000)
    }, [])

    return (
        <div>
        </div>
    )
}

export default UseIdleTimer;