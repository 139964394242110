import { useEffect, useState } from "react";
import axios from "axios";

import { BASE_URL } from "../env/Baseurl";
import ErrorLogging from "./error-logs";
import RefreshToken from "./refresh-token";

const GetWelcomeUser = () => {
  const [currentUser, setCurrentUser] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchCurrentUser = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: "",
      });

      const response = await axios.post(
        BASE_URL + `welcome_page/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setCurrentUser(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        fetchCurrentUser();
      } else {
        setErrorMessage("There is some error, please try again later.");
        ErrorLogging("HTTP GET /userResponce_ofchallexcersizepart" + error);
      }
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return [currentUser, errorMessage];
};

export { GetWelcomeUser };
