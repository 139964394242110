import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import { Button, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetAllAvoidance } from "../../../../services/part4/get-all-avoidance";
import { GetAllUtbas } from "../../../../services/part4/get-all-utbas";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetStepMessage } from "../../../../services/part4/get-step-message";

const Part4FourthPage = () => {
  const [avoidanceQuestionnaire, avoidanceErrorMessage] = GetAllAvoidance(); // Get Avoidance Questionnaire from api
  const [utbasQuestionnaire, utbasErrorMessage] = GetAllUtbas(); // Get UTBAS Questionnnaire from api
  const [stepMessageText, stepErrorMessage] = GetStepMessage(); //get step message text

  /*******   States *******/
  const [utbasState, setutbasState] = useState({});
  const [avoidanceState, setAvoidanceState] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [showMore, setShowMore] = useState({
    avoidance: false,
    utbas: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);

  // /***********error modal*****/
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  const numberOfAvoidance = showMore.avoidance
    ? avoidanceQuestionnaire.avoidance_data.length
    : 10;
  const numberOfUtbas = showMore.utbas
    ? utbasQuestionnaire.questionnairs.length
    : 10;

  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user info
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        question_id: avoidanceState.question_id,
      });

      const response = await axios.post(
        BASE_URL + `create_overComeanxity/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 4 Create Overcomeanxity Api");
        ErrorLogging("HTTP POST Part 4 /create_overComeanxity/userId" + error);
        handle();
      }
    }

    try {
      const params = JSON.stringify({
        question_id: utbasState.question_id,
      });
      const response = await axios.post(
        BASE_URL + `create_reason_overComeanxity/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 4 Create Reason Overcomeanxity Api");
        ErrorLogging(
          "HTTP POST  Part 4 /create_reason_overComeanxity/userId" + error
        );
        handle();
      }
    }
    if (disabled === false) {
      navigate("/part_4/predictions", { replace: true });
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/predictions", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/why_it_really_doesnt_matter`, { replace: true });
  };

  const handleChangeUtbas = (e) => {
    const { value } = e.target;
    setutbasState({ question_id: value });
  };

  const handleAvoidanceShowMore = () => {
    setShowMore({
      avoidance: true,
    });
  };

  const handleUtbasShowMore = () => {
    setShowMore({
      utbas: true,
    });
  };

  const handleChangeAvoidance = (e) => {
    const { value } = e.target;
    setAvoidanceState({ question_id: value });
  };

  /******print function****/
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      utbasQuestionnaire !== undefined &&
      utbasQuestionnaire.length !== 0 &&
      utbasQuestionnaire.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Hooks  *******/
  // useEffect(() => {
  //     if (Object.keys(utbasState).length === 1) {
  //         setDisabled(false);
  //     }
  // }, [utbasState]);

  useEffect(() => {
    if (
      Object.keys(avoidanceState).length === 0 ||
      Object.keys(utbasState).length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [avoidanceState, utbasState]);
  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> Planning My Behavioural Experiment | ASRC iBroadway</title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body  p-0 mb-0">
                  <div className="title page-title-style">
                    Planning My Behavioural Experiment
                  </div>
                  <div className="card-body">
                    {/* <p>
                      It’s time to plan a behavioural experiment. These
                      experiments are a critical part of increasing your social
                      confidence. Think carefully about the activity you choose
                      from the list below. As you’ll eventually be doing 3 of
                      these experiments, try starting with the social situation
                      that you think would be the easiest of the 5 to face.
                    </p> */}
                    <p>
                      {stepMessageText?.message || ""}
                    </p>
                    <div id="edit-feared-situation-text">
                      <div>
                        <p className="component-sub-heading-style">
                          {/* {avoidanceQuestionnaire.length !== 0 && <>{avoidanceQuestionnaire.statement}</>} */}
                          1. Choose 1 from your list of personally avoided
                          situations
                        </p>
                      </div>
                      {/* <p className='newSec'>
                                                {avoidanceQuestionnaire.length !== 0 && <>{avoidanceQuestionnaire.substatement}</>}
                                            </p> */}

                      <div id="edit-feared-situation">
                        {avoidanceErrorMessage ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {avoidanceErrorMessage}
                              </div>
                            </div>
                          </div>
                        ) : avoidanceQuestionnaire !== undefined &&
                          avoidanceQuestionnaire.length !== 0 ? (
                          <>
                            <div className="content-wrap">
                              {avoidanceQuestionnaire?.avoidance_data
                                ?.slice(0, numberOfAvoidance)
                                ?.map((item, index) => {
                                  const card = (
                                    <div
                                      className="left-content checkbx-sty listing"
                                      key={index}
                                    >
                                      <InputGroup.Checkbox
                                        defaultChecked={item.responce}
                                        disabled={item.disable_status}
                                        className="radio-size"
                                        type="radio"
                                        name="avoidance"
                                        value={item._id}
                                        onChange={handleChangeAvoidance}
                                      />
                                      {item.question}
                                    </div>
                                  );
                                  return card;
                                })}
                            </div>
                            {/* {avoidanceQuestionnaire.avoidance_data.length > 1 ? (
                                                                    <Button
                                                                        className='loadmoreBtn'
                                                                        onClick={handleAvoidanceShowMore}
                                                                    >
                                                                        {
                                                                            showMore.avoidance ?
                                                                                "" :
                                                                                <>
                                                                                    Show more Feared Situations
                                                                                </>
                                                                        }
                                                                    </Button>
                                                                )
                                                                    :
                                                                    ""
                                                                } */}
                          </>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>

                      <div>
                        <div id="edit-reason-for-avoidance-text">
                          <p className="component-sub-heading-style mb-0 ">
                            2. Choose 1 from your list of reasons why you’d
                            avoid the situation selected above
                          </p>
                          {/* <p className='secondSec'>
                                                        {utbasQuestionnaire.length !== 0 && <>{utbasQuestionnaire.statement}</>}
                                                    </p> */}
                        </div>
                        <div id="edit-reason-for-avoidance">
                          {utbasErrorMessage ? (
                            <div className="container mt-5">
                              <div className="row justify-content-center">
                                <div
                                  className="alert alert-danger text-center"
                                  role="alert"
                                >
                                  {utbasErrorMessage}
                                </div>
                              </div>
                            </div>
                          ) : utbasQuestionnaire !== undefined &&
                            utbasQuestionnaire.length !== 0 ? (
                            <>
                              <div className="content-wrap">
                                {utbasQuestionnaire?.questionnairs
                                  ?.slice(0, numberOfUtbas)
                                  ?.map((item, index) => {
                                    const card = (
                                      <div
                                        className="left-content checkbx-sty"
                                        key={index}
                                      >
                                        <InputGroup.Checkbox
                                          defaultChecked={item.responce}
                                          disabled={item.disable_status}
                                          className="radio-size"
                                          type="radio"
                                          value={item._id}
                                          name="utbas"
                                          onChange={handleChangeUtbas}
                                        />
                                        {item.question}
                                      </div>
                                    );
                                    return card;
                                  })}
                              </div>
                              {/* <Button
                                                                        className='loadmoreBtn'
                                                                        onClick={handleUtbasShowMore}
                                                                    >
                                                                        {
                                                                            showMore.utbas ?
                                                                                "" :
                                                                                <>
                                                                                    Show more Reasons for Avoidance
                                                                                </>
                                                                        }
                                                                    </Button> */}
                            </>
                          ) : (
                            <div className="loader-icon">
                              <TailSpin
                                color="#4f5962"
                                height={50}
                                width={50}
                              />
                            </div>
                          )}
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  {utbasQuestionnaire !== undefined &&
                    utbasQuestionnaire.length !== 0 &&
                    utbasQuestionnaire.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? "Please select a thought that you would use in the above situation."
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          Continue
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4FourthPage;
