import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../actions/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CLEAR_MESSAGE } from "../../../actions/types";
import "../../../styles/login.css";
import { DOMAIN_NAME, IMAGE_URL } from "../../../env/Baseurl";

const LoginScreen = (props) => {
  /*******   States  *******/
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState({
    value: "",
    hasError: false,
    touched: false,
  });
  const [logineEmptyCheck, setloginEmptyCheck] = useState({
    email: "",
    password: "",
  });

  /*******   LocalStorage  *******/
  const userState = localStorage.getItem("userState");
  // console.log(userState, "its user state")
  const userLastVisited = localStorage.getItem("last_visited_url");

  /*******   Redux  *******/
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { user } = useSelector((state) => state.auth);
  // console.log(user)
  const dispatch = useDispatch();

  /*******   HelperFunctions  *******/
  let navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const changeHandler = (e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    let hasError = false;
    if (
      !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
        inputValue
      )
    ) {
      hasError = true;
    }
    setEmail((currentValue) => ({
      ...currentValue,
      value: e.target.value,
      hasError,
    }));
  };

  const blurHandler = () => {
    setEmail((currentValue) => ({
      ...currentValue,
      touched: true,
    }));
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.value === "") {
      setloginEmptyCheck({ email: "Please fill out Email field to login." });
    } else if (password === "") {
      setloginEmptyCheck({
        password: "Please fill out Password field to login.",
      });
    } else if (
      email.value !== "" &&
      password !== "" &&
      email.hasError === false
    ) {
      dispatch(login(email.value, password))
        .then(() => {})
        .catch(() => {});
    } else {
    }
  };

  /*******   Hooks  *******/

  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGE,
    });
    if (userLastVisited !== null) {
      navigate(userLastVisited);
    } else if (
      userState &&
      userState === "true" &&
      user?.userInfo?.role_id === 1
    ) {
      navigate(user.userInfo.last_visited_page);
    } else if (
      userState &&
      userState === "true" &&
      user?.userInfo?.role_id === 0
    ) {
      navigate("/admin/panel");
    } else {
      navigate("/");
    }
  }, [userState]);

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>Log in | iBroadway</title>
      </Helmet>
      <div className="d-flex container align-items-center login-container-wrap justify-content-center px-0">
        <div className="row align-items-center login-col-wrap justify-content-center">
          <div className="col-md-6 loginimagestyle">
            <div className="text-center">
              <img
                height="auto"
                width="450px"
                src={`${IMAGE_URL}images/login/Untitled-3.png`}
                alt="Login Group"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h3 className="text-center pagehead-texttitle">Login</h3>
              <form onSubmit={handleLogin}>
                <div className="card-body">
                  <div className="form-group forminut-passeye">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="input-label-style"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-icon-space"
                      id="exampleInputEmail1"
                      placeholder="Your Email Address..."
                      onChange={changeHandler}
                      onKeyUp={blurHandler}
                    />
                    <div className="passShow-eye">
                      <i className="fa fa-user"></i>
                    </div>
                    <div>
                      {email.value === "" && (
                        <small className="text-danger input-label-style error-message-style">
                          {" "}
                          {logineEmptyCheck.email}
                        </small>
                      )}
                    </div>
                    <div>
                      {email.touched && email.hasError && (
                        <small className="text-danger input-label-style error-message-style">
                          Please enter a valid email
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-group forminut-passeye">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="input-label-style"
                    >
                      Password
                    </label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control form-control-icon-space"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onKeyUp={onChangePassword}
                    />
                    {password === "" && (
                      <small className="text-danger input-label-style error-message-style">
                        {logineEmptyCheck.password}
                      </small>
                    )}
                    <div
                      className="passShow-eye"
                      onClick={togglePasswordVisiblity}
                    >
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="mt-1 input-label-style">
                      <Link to="/reset-password">Forgotten password?</Link>
                    </div>
                  </div>
                  <div className="form-group login-submit">
                    <button
                      type="submit"
                      className="btn blue-style-btns  btn-sm btn-block input-label-style"
                      style={{
                        fontSize: "20px",
                        lineHeight: "21px",
                        height: "53px",
                      }}
                    >
                      Log in
                    </button>
                  </div>
                  <div className="form-group login-sign-up text-center signuptext input-label-style">
                    <p className="input-label-style">
                      Don't have an account?{" "}
                      <Link to="/register">Sign Up!</Link>
                    </p>
                  </div>
                </div>
                {message && (
                  <div className="form-group ">
                    <div
                      className={
                        message ===
                        "Successfully logged in. You will be redirected to dashboard"
                          ? "alert alert-success text-center input-label-style"
                          : "alert alert-danger text-center input-label-style"
                      }
                      role="alert"
                    >
                      <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
