export const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused
      ? "1px solid #6A3851 !important"
      : "1px solid #cccccc !important",
    // This line disable the blue border
    boxShadow: "none",
    background: state.isFocused ? "#ffffff" : "#ECF1FE !important",
    fontFamily: "ComicNeue-Regular"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#6A3851"
          : isFocused
            ? "#9b5a7b"
            : undefined,
      color: isDisabled
        ? undefined
        : isSelected
          ? "#ffffff"
          : isFocused
            ? "#FFFFFF"
            : '#323232',
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#6A3851"
            : "#9b5a7b"
          : undefined,
      },
    };
  },
};
