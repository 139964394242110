import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { GetCurrentIndex } from "../../services/sidebar/get-current-index";
import { display } from "@mui/system";

const SidebarAccordian = ({ title, items, url, expandedState, menuindex }) => {
  /*******   States *******/
  const [accordianToogle, setAccordianToggle] = useState(expandedState);

  const location = useLocation();
  const relativePath = location?.pathname;
  // console.log(location, "itw relative path")

  const [currentIndex, errorMessage] = GetCurrentIndex();
  // console.log(currentIndex, "its current index");

  /*******   Render  *******/
  let lastUrl = localStorage.getItem("last_visited_url");

  const styles = {
    none: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  return (
    <div>
      <Accordion
        expanded={accordianToogle}
        onClick={() => setAccordianToggle(!accordianToogle)}
        style={menuindex <= currentIndex.index ? styles.show : styles.none}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="menu-parent">
            <Typography component="span">
              <span className="headText">
                <Link
                  to={url}
                  className={
                    menuindex <= currentIndex.index
                      ? "link-enable"
                      : "link-disable"
                  }
                  // style={(menuindex <= currentIndex.index ? styles.show : styles.none)}
                >
                  {title}
                </Link>
              </span>
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {items.map((item, index) => (
            <Typography key={index} component="span">
              <span
                className={
                  lastUrl === item.url ||
                  lastUrl === item?.subTitle?.url ||
                  lastUrl === item?.subTitle1?.url ||
                  lastUrl === item?.subTitle2?.url ||
                  lastUrl === item?.subTitle3?.url ||
                  lastUrl === item?.subTitle4?.url
                    ? "item-menu-list active"
                    : relativePath === item.url ||
                      relativePath === item?.subTitle?.url ||
                      relativePath === item.subTitle1?.url ||
                      relativePath === item.subTitle2?.url ||
                      relativePath === item.subTitle3?.url ||
                      relativePath === item?.subTitle4?.url
                    ? "item-menu-list active previous"
                    : ""
                }
              >
                <div
                  className={
                    item.index <= currentIndex.index
                      ? "link-enable-wrap"
                      : "link-disable-wrap"
                  }
                  // style={(item.index <= currentIndex.index ? styles.show : styles.none)}
                >
                  <Link
                    to={item.url}
                    className={
                      item.index <= currentIndex.index
                        ? "link-enable"
                        : "link-disable"
                    }
                    // style={(item.index <= currentIndex.index ? styles.show : styles.none)}
                  >
                    {item.title}
                  </Link>
                </div>
              </span>
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(SidebarAccordian);
