import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetCurrentIndex } from "../../../../services/sidebar/get-current-index";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useNavigate } from "react-router-dom";

// import { checkForContinueButton } from "../../../../services/helpers";

const HowToUse = () => {
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);
  const [currentIndex, errorMessage] = GetCurrentIndex();

  /*******   Page Params/constant  *******/
  const title1 = "How to use iBroadway?";
  const title2 = "How to use My Program?";
  const title3 = "Why the Questionnaires?";

  /*******************hooks************** */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { audio } = useSelector((state) => state.audio);
  /*************Functions**************** */
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };
  const nextPage = () => {
    handleAudioStoreData();
    navigate(`/intro/utbas_questionnaire`, { replace: true });
  };
  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/intro/welcome`, { replace: true });
  };

  /******useEffect***** */
  const checkForContinueButton = async () => {
    // let currentIndex = await currentIndex?.index;
    // if (currentIndex > currentPageIndex) {
    //   setButtonState(false);
    // } else {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  /*******   Render  *******/
  return (
    <div className="welcome-content">
      <Helmet>
        <title>How to use the Online iBroadway Program | ASRC iBroadway</title>
      </Helmet>
      <>
        <div id="printAble"></div>
        <div id="mainContainer" className="dashboardContent">
          <div>
            <Header
              targeted_print_component_id={"main"}
              myprintFunction={myprintFunction}
            />
          </div>
          <div className="row m-0">
            <div className="col-md-2  position-static pl-0">
              <div className="leftside">
                <Sidebar />
              </div>
            </div>

            <div className="col-md-10 content-widthsidebar content-wrapper">
              <div className="card">
                <div id="main">
                  <div className="card-body p-0 mb-0">
                    <div>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-heading"
                        >
                          <Typography>
                            <p className="page-title-style m-0">{title1}</p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="card-body p-0">
                              <div className="">
                                <p>What will happen:</p>
                                <ul class="listing">
                                  <li>
                                    The program will be designed uniquely for
                                    YOU.
                                  </li>
                                  <li>
                                    It will be based on your answers to 4
                                    questionnaires that you will complete
                                    shortly.
                                  </li>
                                  <li>
                                    After completing these questionnaires you
                                    will learn how to increase your social
                                    confidence with 7 on-line modules.
                                  </li>
                                </ul>
                                <p>What won't happen:</p>
                                <ul class="listing">
                                  <li>
                                    You won’t be asked to do anything
                                    embarrassing or distressing.
                                  </li>
                                  <li>
                                    The program won’t allow other users to know
                                    what you are doing. It is all between you
                                    and us (the clinicians).
                                  </li>
                                </ul>
                                <p>
                                  The more you put into YOUR program, the more
                                  you’ll get out of it.
                                </p>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        disabled={currentAudio?.enabledAudio < 1 ? true : false}
                        onClick={() => {
                          if (currentAudio?.enabledAudio < 1) return false;
                          else {
                            setIsPlaying(true);
                            setCurrentAudio({ ...currentAudio, audio: 1 });
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-heading"
                        >
                          <Typography>
                            <p className="page-title-style m-0">{title2}</p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="card-body p-0">
                              <ul class="listing">
                                <li>
                                  Complete all questions and activities on each
                                  page to allow you to move to the next page.
                                </li>
                                <li>
                                  Previous answers can be reviewed, but not
                                  changed.
                                </li>
                                <li>
                                  Log out any time you wish and come back later.
                                </li>
                                <li>
                                  On every page there will be a voiceover of your clinician who will guide you through the program. It’s important that you listen to these voiceovers as your clinician will give you a lot of information in addition to what you will see on each page. Please note that these voiceovers should play automatically but if they don’t you can click the play icon on the bottom of the page.
                                </li>
                                <li>
                                  For the best experience using this program it is recommended that you use a computer, tablet or iPad.
                                </li>
                                <li>
                                  If at any stage during this program you feel
                                  overwhelmed by your anxiety or feelings of
                                  depression, we encourage you to seek help in
                                  your local area, or hit the 'Feeling Sad?'
                                  button at the bottom of any page for contacts.
                                </li>
                              </ul>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        disabled={currentAudio?.enabledAudio < 2 ? true : false}
                        onClick={() => {
                          if (currentAudio?.enabledAudio < 2) return false;
                          else {
                            setIsPlaying(true);
                            setCurrentAudio({ ...currentAudio, audio: 2 });
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-heading"
                        >
                          <Typography>
                            <p className="page-title-style m-0">{title3}</p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="card-body p-0">
                              <ul class="listing">
                                <li>
                                  To make this treatment package uniquely yours,
                                  it’s really important that you answer all 4
                                  different questionnaires. It may seem like a
                                  pain, but your answers will help to design a
                                  program that’s targeted to YOU.{" "}
                                </li>
                                <li>
                                  It’s pretty amazing to see how the program
                                  does this when it’s all finished.
                                </li>
                                <li>
                                  Don’t forget, along the way you will collect 8
                                  badges and loads of points to become the
                                  ultimate Superhuman! Once you finish the
                                  program you will gain access to your free
                                  comic book and Superhero movie!
                                </li>
                              </ul>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
                {title1 !== "Welcome to the Online iGlebe Program" ? (
                  <div className="row mx-0 bottom-button-container">
                    <div className="previous col-5 px-0">
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={previousPage}
                      >
                        Previous
                      </Button>
                    </div>
                    <div className="next col-7 px-0">
                      <Button
                        disabled={buttonState}
                        className="user-reponse-submit btn btn-primary link-sty m-0"
                        onClick={nextPage}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                )}
              </div>

              {/* </div> */}
            </div>
            <div className="col-md-12 dashboard-footer">
              <Footer
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default HowToUse;
