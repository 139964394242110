import React, { useEffect, useRef, memo } from "react";
import { useLocation } from "react-router-dom";
import { DOMAIN_NAME, IMAGE_URL } from "../../env/Baseurl";
import {
  items1,
  items2,
  items3,
  items4,
  items5,
  items6,
  items7,
  items8,
  items9,
} from "../../constants/global/Sidebar";
import SidebarAccordian from "./Accordion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Co2Sharp } from "@mui/icons-material";
import { BASE_URL } from "../../env/Baseurl";
import axios from "axios";
import userprofile from "../../assets/userProfile/userProfile.png";
import { useSelector, useDispatch } from "react-redux";
import getBadgeInfo from "../../actions/badgeInfo";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let inputFile = useRef(null);
  const [imageUpdate, setImageUpdate] = useState(false);
  const [profile, setProfile] = useState("");
  const styles = {
    none: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  /*************************Redux****************** */
  const { badge } = useSelector((state) => state.badge);

  /*******   Localstorage  *******/
  const moduleName = localStorage.getItem("moduleName");
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  // const userProfileImg = localStorage.getItem('userProfile')
  // console.log(userProfileImg, "its user image")

  /*****************************************update profile Image /avatar********** */
  const addFile = async (e) => {
    try {
      const formData = new FormData();
      formData.append("user_avtar", e.target.files[0]);

      const response = await axios.post(
        BASE_URL + `profileupdate/?user_id=${user.userInfo._id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      // console.log(response.data, 'its api response')
      const object = {
        ...user,
        userInfo: {
          ...user.userInfo,
          image_url: response?.data?.userInfo?.image_url,
          total_points: response?.data?.userInfo?.total_points,
        },
      };
      // console.log(object, "its object to store image")
      localStorage.setItem("user", JSON.stringify(object));
      setImageUpdate(!imageUpdate);
      // localStorage.setItem('userProfile', response?.data?.userInfo?.image_url)
    } catch (error) {
      console.log(error, "Error while updating avatar");
      alert("Oops!, Failed to update avatar.");
    }
  };
  useEffect(() => {
    dispatch(getBadgeInfo())
      .then(() => {})
      .catch(() => {});
  }, []);
  /*******   Render  *******/
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="sidebar">
          <div className="user-panel d-flex web-logo">
            <div className="info">
              <img
                src={`${IMAGE_URL}images/sidebar/Rectangle.jpg`}
                alt="logo"
              ></img>
            </div>
            <p className="logo-name">IBroadway</p>
          </div>
          <div className="user-info-box name">
            <div className="user-points-box user-name-box">
              <img
                className="logo-style"
                src={
                  user?.userInfo?.image_url
                    ? user?.userInfo?.image_url
                    : userprofile
                }
                onClick={() => {
                  inputFile.current.value = null;
                  inputFile.current.click();
                }}
              ></img>
              <a href="#" className="d-block user-name">
                {user.userInfo.Fname + " " + user.userInfo.Lname}
              </a>
            </div>
            <input
              type="file"
              accept="image/*"
              name="uploadImage"
              id="uploadImage"
              ref={inputFile}
              onChange={(e) => addFile(e)}
              hidden
            />
            <Link to="/userBadge">
              <div className="user-points-box">
                {/* <a href="#" className="d-block">{user.userInfo.Fname + " " + user.userInfo.Lname}</a> */}
                <img
                  className={` logo-style ${
                    badge?.userInfo?.complete_module == 0
                      ? "sidebar-badge-style-blur"
                      : null
                  }`}
                  src={
                    {
                      0: `${IMAGE_URL}images/Badges/badge1.png`,
                      1: `${IMAGE_URL}images/Badges/badge1.png`,
                      2: `${IMAGE_URL}images/Badges/badge2.png`,
                      3: `${IMAGE_URL}images/Badges/badge3.png`,
                      4: `${IMAGE_URL}images/Badges/badge4.png`,
                      5: `${IMAGE_URL}images/Badges/badge5.png`,
                      6: `${IMAGE_URL}images/Badges/badge6.png`,
                      7: `${IMAGE_URL}images/Badges/badge7.png`,
                      8: `${IMAGE_URL}images/Badges/badge8.png`,
                    }[badge?.userInfo?.complete_module]
                  }
                ></img>
                <span>{user?.userInfo?.total_points} Points</span>
              </div>
            </Link>
          </div>
          <div className="user-panel mt-2 pb-2 mb-2 d-flex navigation-link">
            <div className="info">
              <div
                className={
                  moduleName === "Pre-Treatment"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
              >
                <SidebarAccordian
                  items={items1}
                  title={"Welcome!"}
                  url={"/intro/welcome"}
                  expandedState={moduleName === "Pre-Treatment" ? true : false}
                  menuindex={1}
                />
              </div>
              <div
                className={
                  moduleName === "Part 1"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(
                //   moduleName === "Part 1" || moduleName === "Part 2" || moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part1Accessed === "true") ? styles.show : styles.none}

                // style={(currentIndex.index > 7 ? styles.show : styles.none)}
              >
                <SidebarAccordian
                  items={items2}
                  title={"Part 1 - Let's Begin"}
                  url={"/part_1/anxiety_is_important_in_stuttering"}
                  expandedState={moduleName === "Part 1" ? true : false}
                  menuindex={8}
                />
              </div>
              <div
                className={
                  moduleName === "Part 2"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>22 ? styles.show :styles.none  )}
                // style={(moduleName === "Part 2" || moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part2Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items3}
                  title={"Part 2 - Challenge Your Thinking"}
                  url={"/part_2/challenge_example"}
                  expandedState={moduleName === "Part 2" ? true : false}
                  menuindex={23}
                />
              </div>
              <div
                className={
                  moduleName === "Part 3"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>33 ? styles.show :styles.none  )}
                // style={(moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part3Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items4}
                  title={"Part 3 - Social Anxiety"}
                  url={"/part_3/intro"}
                  expandedState={moduleName === "Part 3" ? true : false}
                  menuindex={34}
                />
              </div>
              <div
                className={
                  moduleName === "Part 4"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>46 ? styles.show :styles.none  )}
                //  style={(moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part4Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items5}
                  title={"Part 4 - Behavioural Experiments"}
                  url={"/part_4/intro"}
                  expandedState={moduleName === "Part 4" ? true : false}
                  menuindex={47}
                />
              </div>
              <div
                className={
                  moduleName === "Part 5"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>59 ? styles.show :styles.none  )}

                //  style={(moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part5Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items6}
                  title={"Part 5 - 'Should' Statements"}
                  url={"/part_5/essay"}
                  expandedState={moduleName === "Part 5" ? true : false}
                  menuindex={60}
                />
              </div>
              <div
                className={
                  moduleName === "Part 6"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>65 ? styles.show :styles.none  )}

                // style={(moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part6Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items7}
                  title={"Part 6 - Gaining Control"}
                  url={"/part_6/the_problem_of_self-conciousness"}
                  expandedState={moduleName === "Part 6" ? true : false}
                  menuindex={66}
                />
              </div>
              <div
                className={
                  moduleName === "Part 7"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>72 ? styles.show :styles.none  )}

                //  style={(moduleName === "Part 7" || moduleName === "Post-Treatment") || (part7Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items8}
                  title={"Part 7 - Relapse Prevention"}
                  url={"/part_7/preventing_relapse"}
                  expandedState={moduleName === "Part 7" ? true : false}
                  menuindex={73}
                />
              </div>
              <div
                className={
                  moduleName === "Post-Treatment"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>78 ? styles.show :styles.none  )}

                // style={(moduleName === "Post-Treatment") || (postTreatmentAccess === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items9}
                  title={"Final Questionnaires"}
                  url={"/part_7/utbas_questionnaire"}
                  expandedState={moduleName === "Post-Treatment" ? true : false}
                  menuindex={79}
                />
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default memo(Sidebar);
