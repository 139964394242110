import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GetPageIndex } from '../services/sidebar/get-page-index';
import UnauthorizedPage from './global/UnauthorizedPage';
import { useSelector } from "react-redux";
import UseIdleTimer from "../actions/UseIdleTimer"
const Protected = (props) => {

    let [currentIndex, indexErrorMessage] = GetPageIndex(props.PageIndex);

    /*******   LocalStorage  *******/
    const userState = localStorage.getItem('userState');
    const { user } = useSelector(state => state.auth);

    let PageIndex = props.PageIndex;
    let Cmp = props.Cmp;
    const navigate = useNavigate();

    const location = useLocation();
    const relativePath = location?.pathname;


    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigate('/');
        }

        // if (!localStorage.getItem('user') && relativePath !=='/admin/panel') {
        //     navigate('/');
        // }

        // if admin try to access program from his credentials

        if (localStorage.getItem('user') && userState && userState === 'true' && user?.userInfo?.role_id === 0) {
            navigate('/admin/panel');
        }


    }, []);



    // for disabling back button

    if (localStorage.getItem('user')) {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }


    return (


        <>
            {userState && userState === 'true' && user?.userInfo?.role_id === 0 ?
                <Cmp />
                :
                <>
                    {currentIndex !== undefined && currentIndex.length !== 0 ? (
                        <div>
                            {PageIndex <= currentIndex.index + 2 ?
                                <Cmp />
                                :
                                <UnauthorizedPage />
                            }
                        </div>
                    )
                        :
                        <>
                        </>

                    }
                </>
            }

            {userState && userState === 'true' && user?.userInfo?.role_id === 1 ?
                <UseIdleTimer /> :
                null
            }

        </>


    )
}

export default Protected;