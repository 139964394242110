import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";

import React, { useEffect, useState } from "react";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import { BASE_URL } from "../../../../env/Baseurl";
import { Button } from "react-bootstrap";
import ErrorLogging from "../../../../services/error-logs";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { GetExperimentCompleted } from "../../../../services/part4/experiment-completed";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import RefreshToken from "../../../../services/refresh-token";
import Sidebar from "../../../global/Sidebar";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Part4EighthPage = () => {
  const [experimentCompleted, errorMessage] = GetExperimentCompleted(); // Get Experiment info from api

  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorApiMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*******   Helper Functions  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;
    if (state.data.some((item) => item.question_id === inputId)) {
      let updatedData = state.data.map((item) => {
        if (item.question_id === inputId) {
          return { ...item, question_id: inputId, user_responce: value };
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [...state.data, { question_id: inputId, user_responce: value }],
      }));
    }

    if (value !== "" || value === "") {
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
          id: inputId,
          errorMessage:
            "Only special characters are not allowed. Please add alphabets with it.",
          disabledMessage:
            "Only special characters are not allowed. Please add alphabets with it.",
        })
        : setTextboxValidation({
          id: inputId,
          errorMessage: "",
          disabledMessage:
            "Please submit all the challenge responses to continue.",
        });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `experiment_completed_userresponceapi/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.data.length >= experimentCompleted.count) {
        handleAudioStoreData();
        navigate("/part_4/perceived_negative_evaluations", { replace: true });
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(
          "Please check Part 4 Experiment Completed Userresponse Api"
        );
        ErrorLogging(
          "HTTP POST Part 4 /experiment_completed_userresponceapi/userId" +
          error
        );
        handle();
      }
    }
  };

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `experiment_completed_userresponceapi/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage(
          "Please check Part 4 Experiment Completed Userresponse Api"
        );
        ErrorLogging(
          "HTTP POST Part 4 /experiment_completed_userresponceapi/userId" +
          error
        );
        handle();
      }
    }
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/perceived_negative_evaluations", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/the_experiment`, { replace: true });
  };

  // close modal on its own
  setTimeout(handleClose, 1000);

  /*******   Hooks  *******/

  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.user_responce === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.user_responce))
    );
    if (experimentCompleted !== undefined && experimentCompleted.length !== 0) {
      const { data = [] } = experimentCompleted.pred_prob_data;

      if (
        state.data.length >= experimentCompleted.count &&
        arr.length === 0 &&
        arr1.length === 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [state.data]);

  useEffect(() => {
    if (experimentCompleted.count === 0) {
      setDisabled(false);
    }
  }, [experimentCompleted]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            Reviewing my progress after the exposure | ASRC iBroadway
          </title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body p-0 mb-0">
                <div id="main">
                  <div className="title page-title-style mb-0">
                    Reviewing my progress after the exposure
                  </div>
                  <div className="card-body">
                    <p className="component-heading-style mb-0">
                      Evaluating your predictions
                    </p>
                    <p className="mb-0">
                      Welcome back{" "}
                      {user?.userInfo?.Fname + " " + user?.userInfo?.Lname}.
                      Let’s see how you went with your behavioural experiment by
                      reviewing your predictions.
                    </p>
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : experimentCompleted !== undefined &&
                      experimentCompleted.length !== 0 ? (
                      <div>
                        {experimentCompleted.pred_prob_data.map(
                          (item, index) => {
                            const card = (
                              <div className="pt-4" key={index}>
                                <p className="component-heading-style mb-0">
                                  {{ 0: "1st", 1: "2nd", 2: "3rd" }[index]}{" "}
                                  predictions
                                </p>
                                <p className="mb-3">{item.statement}</p>
                                <div className="row predictions">
                                  <div className="col-lg-6 pr-3 pl-3">
                                    <div className="textbox">
                                      <textarea
                                        readOnly={item.disable_status}
                                        className="form-control"
                                        id="ControlTextarea"
                                        rows="5"
                                        defaultValue={item.user_responce}
                                        onChange={handleChange(item.id)}
                                      />
                                    </div>
                                    {textBoxValidation.id === item.id && (
                                      <small className="text-danger">
                                        {textBoxValidation.errorMessage}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="">
                                      Possible answers include:
                                    </p>
                                    <div
                                      className="listing"
                                      dangerouslySetInnerHTML={{
                                        __html: item.sample_answear,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            );
                            return card;
                          }
                        )}
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  <div>
                    <FooterOfPrint />
                  </div>
                </div>
                <div className="row mx-0 bottom-button-container">
                  <div className="draft-button-container">
                    <Button className="sm-draft-button" onClick={saveDraft}>
                      Save as Draft
                    </Button>
                  </div>
                  <div className="previous1 col-3 col-lg-6 px-0">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty m-0"
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="next col-9 col-lg-6 px-0">
                    {/* {experimentCompleted !== undefined && experimentCompleted.length !== 0 && experimentCompleted.global_disable_status === false ? (
                                            <>
                                                <Button
                                                    className="draftButton"
                                                    onClick={saveDraft}
                                                >
                                                    Save as Draft
                                                </Button>
                                            </>
                                        ) :
                                            <>
                                            </>
                                        } */}
                    <Button className="draftButton" onClick={saveDraft}>
                      Save as Draft
                    </Button>
                    {experimentCompleted !== undefined &&
                      experimentCompleted.length !== 0 &&
                      experimentCompleted.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? textBoxValidation.disabledMessage
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty m-0"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            Continue
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty m-0"
                          onClick={nextPage}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              {successMessage ? (
                <Modal.Body>{successMessage}</Modal.Body>
              ) : (
                <Modal.Body>{errorMessage}</Modal.Body>
              )}
            </Modal>
            {/* error message modal */}

            <Modal show={showError}>
              <Modal.Body>{errorApiMessage}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part4EighthPage;
