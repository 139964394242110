import axios from "axios";
import { BASE_URL } from '../env/Baseurl';

const ErrorLogging = async (errorName) => {
    

        try{
            const params = JSON.stringify({
                "error_name": errorName,
            });    
            const response = await axios.post(BASE_URL + "create_server_error", params, {
                "headers": {
                "content-type": "application/json",
                },   
            })
        } catch(error){
          
        }     
    
};

export default ErrorLogging;