import React from 'react';
import { Helmet } from 'react-helmet';
import ExcerciseAnswers from '../templates/Answers/ExerciseAnswers';
import { GetUserResponse } from '../../../../services/part1/get-user-response';

const ThinkingResponse2 = () => {

    const [userResponse, errorMessage] = GetUserResponse(2);         // User Responses from API

    /*******   Page Params  *******/

    // const title = "'Thinking' Exercise 2 - Your Answers";
    const title = "'Thought' Exercise 2 - Review";


    const content = "<p class='normal-text'>Please compare the thoughts you wrote down with our example thoughts about what Mia could have told herself to make her feel each of the 4 different emotions.</p class='normal-text'>";

    const images = ['exercise2/54.jpg', 'exercise2/55.jpg', 'exercise2/56.jpg', 'exercise2/57.jpg']


    /*******   Render  *******/
    return (
        <div>
            <Helmet>
                <title>'Thought' Exercise 2 - Your Answers | ASRC iBroadway</title>
            </Helmet>
            <ExcerciseAnswers
                title={title}
                content={content}
                responses={userResponse}
                link='part_1/what_is_a_causal_thought'
                type={'ThinkingResponse1'}
                sitnum={2}
                error={errorMessage}
                previousLink='part_1/thinking_exercise_2'
                images={images}
            />
        </div>
    );
};

export default ThinkingResponse2;