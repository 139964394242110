// Table.js

import React, { useState, useEffect } from "react";
import { useFilters, useSortBy, usePagination, useRowSelect, useTable } from "react-table";
import Select from 'react-select';
import { Button } from "react-bootstrap";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from "../../../../actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

const SelectOptions = [
  { value: 'download', label: 'Download' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'Make as Admin', label: 'Make as Admin' },
];

const SelectOptions1 = [
  { value: 'Make as User', label: 'Make as User' },
];

export default function Table({ columns, data, refreshData, setRole, loader }) {

  const [option, setOption] = useState('');
  const [filterInput, setFilterInput] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [userIds, setUserIds] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState('');
  const [adminMessage, setAdminMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [toggleData, setToggleData] = useState(true);
  const [finalSelectOptions, setFinalSelectOptions] = useState(SelectOptions);
  const [checked, setChecked] = useState(false);
  const [AdminColor, setAdminColor] = useState(false);
  const [allUser, setAllUser] = useState(false);
  const [load, setLoad] = useState(false)
  const [adminPage, setAdminPage] = useState(false);
  // modal
  const [show, setShow] = useState(false);
  const handle = () => setShow(true);
  let handleClose = () => setShow(false);

  // Set the selected option
  const selectHandler = (value) => {
    setOption(value);

  };


  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <div className="checkBox">
          <input type="checkbox" className="chckBox" ref={resolvedRef} {...rest} onClick={() => { setChecked(!checked) }} />
        </div>
      )
    }
  )
  let navigate = useNavigate()

  const dispatch = useDispatch();
  // for admin success message

  const success = () => {
    toast.success('The user is added as an administrator.', {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",

    });
  }
  // for user success message
  const userSuccess = () => {
    toast.success('The Admin is added as an user.', {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",

    });
  }

  // for Error message
  const Error = () => {
    toast.error('Please select atleast one user and one action.', {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",

    });
  }


  // for exporting data
  const exportData = () => {
    toast.success('Download is in progress, please wait for some time.', {
      position: "bottom-left",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",

    });
  }


  const userAction = async () => {

    const userIdsAction = selectedFlatRows.map(function (item) {
      return {
        _id: item.original._id,
      }
    });

    if (selectedFlatRows.length === 0 || option.length === 0) {
      Error();
      return;
    }

    else if (userIdsAction.length === 0) {

      Error();
      return;
    }

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    // for downloading files
    if (option === "download") {

      try {
        const params = JSON.stringify({
          "data": userIdsAction
        });

        const response = await axios.post(BASE_URL + `download_user_by_id_over200/${user.userInfo._id}`, params, {
          "headers": {
            "Authorization": `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }).then((response) => {

          if (response.data.status === false) {
            localStorage.setItem("userState", 'false');
            dispatch(logout());

            if (localStorage.getItem('userState') === 'false') {
              navigate('/');

            }
          }

          else {
            refreshData();
            setOption("");

            const linkSource = `data:application/octet-stream;base64,${response.data}`;
            const downloadLink = document.createElement("a");
            const fileName = "users_data.csv";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setSuccessMessage(response.data);
          }

        },

        );
      } catch (error) {

        if (error.response.status === 401) {
          RefreshToken(user.userInfo._id)
          userAction();
        }
        else {
          setErrorMessage('Please Post Admin Action Api');
          ErrorLogging('HTTP POST /action_user_by_id/userId' + error);
        }
      }
    }

    // for making user as admin
    if (option === 'Make as Admin') {

      const userIdsAction1 = selectedFlatRows.map(function (item) {
        return {
          _id: item.original._id,
        }
      });

      try {
        const params = JSON.stringify({
          "data": userIdsAction1
        });

        const response = await axios.post(BASE_URL + `make_admin_from_adminpanel/${user.userInfo._id}`, params, {
          "headers": {
            "Authorization": `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }).then((response) => {
          setOption("");
          if (response.data.status === true) {
            success();

          }

          else if (response.data.status === false) {
            localStorage.setItem("userState", 'false');
            dispatch(logout());

            if (localStorage.getItem('userState') === 'false') {
              navigate('/');

            }
          }

        });
      } catch (error) {

        if (error.response.status === 401) {
          RefreshToken(user.userInfo._id)
          userAction();
        }
        else {
          setErrorMessage('Please Post Admin Action Api');
          ErrorLogging('HTTP POST /action_user_by_id/userId' + error);
        }
      }
    }


    // for making admin to user
    if (option === 'Make as User') {

      const userIdsAction2 = selectedFlatRows.map(function (item) {
        return {
          _id: item.original._id,
        }
      });

      try {
        const params = JSON.stringify({
          "data": userIdsAction2
        });

        const response = await axios.post(BASE_URL + `make_user_from_adminpanel/${user.userInfo._id}`, params, {
          "headers": {
            "Authorization": `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }).then((response) => {
          setOption("");
          if (response.data.status === true) {
            userSuccess();

          }

          else if (response.data.status === false) {
            localStorage.setItem("userState", 'false');
            dispatch(logout());

            if (localStorage.getItem('userState') === 'false') {
              navigate('/');

            }
          }

        });
      } catch (error) {

        if (error.response.status === 401) {
          RefreshToken(user.userInfo._id)
          userAction();
        }
        else {
          setErrorMessage('Please Post Admin Action Api');
          ErrorLogging('HTTP POST /action_user_by_id/userId' + error);
        }
      }
    }

    else {
      try {
        const params = JSON.stringify({
          "data": userIdsAction
        });

        const response = await axios.post(BASE_URL + `${option}_user_by_id/${user.userInfo._id}`, params, {
          "headers": {
            "Authorization": `Bearer ${user.token}`,
            "content-type": "application/json",
          },
        }).then((response) => {

          setSuccessMessage(response.data);

          if (response.data.status === false) {
            localStorage.setItem("userState", 'false');
            dispatch(logout());

            if (localStorage.getItem('userState') === 'false') {
              navigate('/');

            }
          }

        });
      } catch (error) {
        if (error.response.status === 401) {
          RefreshToken(user.userInfo._id)
          userAction();
        }
        else {
          setErrorMessage('Please Post Admin Action Api');
          ErrorLogging('HTTP POST /action_user_by_id/userId' + error);
        }
      }
    }

    setDisabled(!disabled);
    refreshData(!toggleData);
    setOption("");
  };


  // Export all users
  const ExportAll = async () => {
    setLoad(true);
    exportData();

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    try {

      const response = await axios.post(BASE_URL + `download_user_by_id/${user.userInfo._id}`, {}, {
        "headers": {
          "Authorization": `Bearer ${user.token}`,
          "content-type": "application/json",
        },
      }).then((response) => {

        setLoad(false);
        if (response.data.status === false) {
          localStorage.setItem("userState", 'false');
          dispatch(logout());

          if (localStorage.getItem('userState') === 'false') {
            navigate('/');

          }
        }
        else {

          const linkSource = `data:application/octet-stream;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "users_data.csv";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setSuccessMessage(response.data);


        }
      },

      );
    } catch (error) {
      setLoad(false);

      if (error?.response?.status === 401) {
        RefreshToken(user.userInfo._id)
        ExportAll();
      }
      else {
        setErrorMessage('Please Post Admin Action Api');
        ErrorLogging('HTTP POST /action_user_by_id/userId' + error);
      }
    }
  }




  // close modal on its own

  setTimeout(handleClose, 7000);

  // Update the state when input changes
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilterInput(value);
    setFilter("hidden", value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value


  };



  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    selectedFlatRows,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, hiddenColumns: ['hidden'] }

  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */


  const Admin = () => {

    setRole('admin');
    setFinalSelectOptions(SelectOptions1);
    setAdminColor(true);
    setFilterInput("");
    setAdminPage(true);

  }

  const User = () => {

    setRole('user');
    setFinalSelectOptions(SelectOptions);
    setAdminColor(false);
    setFilterInput("");
    setAdminPage(false);
  }


  return (
    <div className="adminPanel">
      <div className="oterDiv">
        <div className="row toprow">
          <div className="col-lg-6">
            <h2>
              {AdminColor ?
                <>
                  <span onClick={User} style={{ cursor: "pointer" }}>
                    User</span>  <span className="asd" onClick={Admin}>Admin
                  </span>
                </>
                :
                <>
                  <span className="asd" onClick={User}>
                    User</span>  <span onClick={Admin} style={{ cursor: "pointer" }}>Admin
                  </span>
                </>
              }
            </h2>
          </div>
          <div className="col-lg-6 text-right">
            <div>

              {adminPage === true ? <><span>User</span> / <span className="blueText"> Admin</span>  </> : <> <span className="blueText">User</span> / <span>Admin</span> </>}

            </div>
          </div>

        </div>
        <div className="tableMain">
          {load || loader ? <div className="loader-icon">
            <TailSpin color="#4f5962" height={50} width={50} />
          </div> :
            <>
              <div className="row topBar">
                <div className="col-lg-4">

                </div>

                <div className="col-lg-2">
                  <input className="searchIp" value={filterInput} onChange={handleFilterChange} placeholder={"Search"} />
                </div>
                <div className="col-lg-3">
                  <Select placeholder="Select action for selected users" options={finalSelectOptions} onChange={e => {
                    selectHandler(e.value)
                  }}
                  />
                </div>
                <div className="col-lg-1">
                  <Button className="user-reponse-submit" onClick={userAction}>Apply</Button>
                </div>
                {adminPage === true ? <></> :
                  <div className="col-lg-2">
                    <Button className="user-reponse-submit1" onClick={ExportAll}>Export All Users</Button>
                  </div>
                }
              </div>


              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={
                            column.isSorted
                              ? (column.isSortedDesc
                                ? "sort-desc"
                                : "sort-asc")
                              : ""
                          }

                        >{column.render("Header")}
                        </th>

                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>

                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>

              </table>
            </>
          }
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <i className="fa fa-angle-left" aria-hidden="true"></i><i className="fa fa-angle-left" aria-hidden="true"></i>
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <i className="fa fa-angle-right" aria-hidden="true"></i><i className="fa fa-angle-right" aria-hidden="true"></i>
            </button>{' '}
            <span className="pageList">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            &nbsp;
            <span>
              Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              className="dropDown"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50, 200].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>


          {/* admin modal */}

          <Modal show={show} onHide={handleClose}>

            <Modal.Body>{adminMessage}</Modal.Body>


          </Modal>
        </div>
      </div>
      {/* popup message */}
      <ToastContainer />
    </div>

  );
}