import superBrave1 from "../../assets/superHeroMotivators/module1/superBrave/superBrave1.jpg";
import superBrave2 from "../../assets/superHeroMotivators/module1/superBrave/46.jpg";
import superBrave21 from "../../assets/superHeroMotivators/module2/superBrave/48.jpg";
import superBrave22 from "../../assets/superHeroMotivators/module2/superBrave/49.jpg";
import superBrave31 from "../../assets/superHeroMotivators/module3/superBrave/51.jpg";
import superBrave32 from "../../assets/superHeroMotivators/module3/superBrave/52.jpg";
import superBrave41 from "../../assets/superHeroMotivators/module4/superBrave/54.jpg";
import superBrave42 from "../../assets/superHeroMotivators/module4/superBrave/55.jpg";
import superBrave51 from "../../assets/superHeroMotivators/module5/superBrave/57.jpg";
import superBrave52 from "../../assets/superHeroMotivators/module5/superBrave/58.jpg";
import superBrave61 from "../../assets/superHeroMotivators/module6/superBrave/60.jpg";
import superBrave62 from "../../assets/superHeroMotivators/module6/superBrave/61.jpg";
import superBrave71 from "../../assets/superHeroMotivators/module7/superBrave/63.jpg";
import superBrave72 from "../../assets/superHeroMotivators/module7/superBrave/64.jpg";
import superBraveQ from "../../assets/superHeroMotivators/questionnaires/45.jpg";
import superCalm1 from "../../assets/superHeroMotivators/module1/superCalm/superCalm1.jpg";
import superCalm2 from "../../assets/superHeroMotivators/module1/superCalm/110.jpg";
import superCalm21 from "../../assets/superHeroMotivators/module2/superCalm/112.jpg";
import superCalm22 from "../../assets/superHeroMotivators/module2/superCalm/121.jpg";
import superCalm31 from "../../assets/superHeroMotivators/module3/superCalm/114.jpg";
import superCalm32 from "../../assets/superHeroMotivators/module3/superCalm/115.jpg";
import superCalm41 from "../../assets/superHeroMotivators/module4/superCalm/117.jpg";
import superCalm42 from "../../assets/superHeroMotivators/module4/superCalm/118.jpg";
import superCalm51 from "../../assets/superHeroMotivators/module5/superCalm/120.jpg";
import superCalm52 from "../../assets/superHeroMotivators/module5/superCalm/121.jpg";
import superCalm61 from "../../assets/superHeroMotivators/module6/superCalm/123.jpg";
import superCalm62 from "../../assets/superHeroMotivators/module6/superCalm/124.jpg";
import superCalm71 from "../../assets/superHeroMotivators/module7/superCalm/126.jpg";
import superCalm72 from "../../assets/superHeroMotivators/module7/superCalm/127.jpg";
import superCalmQ from "../../assets/superHeroMotivators/questionnaires/130.jpg";
import superFearless1 from "../../assets/superHeroMotivators/module1/superFearless/superFearless1.jpg";
import superFearless2 from "../../assets/superHeroMotivators/module1/superFearless/67.jpg";
import superFearless21 from "../../assets/superHeroMotivators/module2/superFearless/69.jpg";
import superFearless22 from "../../assets/superHeroMotivators/module2/superFearless/70.jpg";
import superFearless31 from "../../assets/superHeroMotivators/module3/superFearless/72.jpg";
import superFearless32 from "../../assets/superHeroMotivators/module3/superFearless/73.jpg";
import superFearless41 from "../../assets/superHeroMotivators/module4/superFearless/75.jpg";
import superFearless42 from "../../assets/superHeroMotivators/module4/superFearless/76.jpg";
import superFearless51 from "../../assets/superHeroMotivators/module5/superFearless/78.jpg";
import superFearless52 from "../../assets/superHeroMotivators/module5/superFearless/79.jpg";
import superFearless61 from "../../assets/superHeroMotivators/module6/superFearless/81.jpg";
import superFearless62 from "../../assets/superHeroMotivators/module6/superFearless/82.jpg";
import superFearless71 from "../../assets/superHeroMotivators/module7/superFearless/84.jpg";
import superFearless72 from "../../assets/superHeroMotivators/module7/superFearless/85.jpg";
import superFearlessQ from "../../assets/superHeroMotivators/questionnaires/86.jpg";
import superResilient1 from "../../assets/superHeroMotivators/module1/superSilient/superResilient1.jpg";
import superResilient2 from "../../assets/superHeroMotivators/module1/superSilient/25.jpg";
import superResilient21 from "../../assets/superHeroMotivators/module2/superSilient/27.jpg";
import superResilient22 from "../../assets/superHeroMotivators/module2/superSilient/28.jpg";
import superResilient31 from "../../assets/superHeroMotivators/module3/superSilient/30.jpg";
import superResilient32 from "../../assets/superHeroMotivators/module3/superSilient/31.jpg";
import superResilient41 from "../../assets/superHeroMotivators/module4/superSilient/33.jpg";
import superResilient42 from "../../assets/superHeroMotivators/module4/superSilient/34.jpg";
import superResilient51 from "../../assets/superHeroMotivators/module5/superSilient/36.jpg";
import superResilient52 from "../../assets/superHeroMotivators/module5/superSilient/37.jpg";
import superResilient61 from "../../assets/superHeroMotivators/module6/superSilient/39.jpg";
import superResilient62 from "../../assets/superHeroMotivators/module6/superSilient/40.jpg";
import superResilient71 from "../../assets/superHeroMotivators/module7/superSilient/42.jpg";
import superResilient72 from "../../assets/superHeroMotivators/module7/superSilient/43.jpg";
import superResilientQ from "../../assets/superHeroMotivators/questionnaires/24.jpg";
import superSmart1 from "../../assets/superHeroMotivators/module1/superSmart/superSmart1.jpg";
import superSmart2 from "../../assets/superHeroMotivators/module1/superSmart/89.jpg";
import superSmart21 from "../../assets/superHeroMotivators/module2/superSmart/91.jpg";
import superSmart22 from "../../assets/superHeroMotivators/module2/superSmart/103.jpg";
import superSmart31 from "../../assets/superHeroMotivators/module3/superSmart/93.jpg";
import superSmart32 from "../../assets/superHeroMotivators/module3/superSmart/94.jpg";
import superSmart41 from "../../assets/superHeroMotivators/module4/superSmart/96.jpg";
import superSmart42 from "../../assets/superHeroMotivators/module4/superSmart/97.jpg";
import superSmart51 from "../../assets/superHeroMotivators/module5/superSmart/99.jpg";
import superSmart52 from "../../assets/superHeroMotivators/module5/superSmart/100.jpg";
import superSmart61 from "../../assets/superHeroMotivators/module6/superSmart/102.jpg";
import superSmart62 from "../../assets/superHeroMotivators/module6/superSmart/103.jpg";
import superSmart71 from "../../assets/superHeroMotivators/module7/superSmart/105.jpg";
import superSmart72 from "../../assets/superHeroMotivators/module7/superSmart/106.jpg";
import superSmartQ from "../../assets/superHeroMotivators/questionnaires/108.jpg";
import superStrong1 from "../../assets/superHeroMotivators/module1/superStrong/3.jpg";
import superStrong2 from "../../assets/superHeroMotivators/module1/superStrong/4.jpg";
//for module 2
import superStrong21 from "../../assets/superHeroMotivators/module2/superStrong/6.jpg";
import superStrong22 from "../../assets/superHeroMotivators/module2/superStrong/7.jpg";
//for module 3
import superStrong31 from "../../assets/superHeroMotivators/module3/superStrong/9.jpg";
import superStrong32 from "../../assets/superHeroMotivators/module3/superStrong/10.jpg";
//for module 4
import superStrong41 from "../../assets/superHeroMotivators/module4/superStrong/12.jpg";
import superStrong42 from "../../assets/superHeroMotivators/module4/superStrong/13.jpg";
//for module 5
import superStrong51 from "../../assets/superHeroMotivators/module5/superStrong/15.jpg";
import superStrong52 from "../../assets/superHeroMotivators/module5/superStrong/16.jpg";
//for module 6
import superStrong61 from "../../assets/superHeroMotivators/module6/superStrong/18.jpg";
import superStrong62 from "../../assets/superHeroMotivators/module6/superStrong/19.jpg";
//for module 7
import superStrong71 from "../../assets/superHeroMotivators/module7/superStrong/21.jpg";
import superStrong72 from "../../assets/superHeroMotivators/module7/superStrong/22.jpg";
// for module 1
import superStrongQ from "../../assets/superHeroMotivators/questionnaires/2.jpg";

const item1 = {
  superStrong: [superStrongQ, superStrong1, superStrong2],
  superResilient: [superResilientQ, superResilient1, superResilient2],
  superCalm: [superCalmQ, superCalm1, superCalm2],
  superBrave: [superBraveQ, superBrave1, superBrave2],
  superFearless: [superFearlessQ, superFearless1, superFearless2],
  superSmart: [superSmartQ, superSmart1, superSmart2],
};

const item2 = {
  superStrong: [superStrong21, superStrong22],
  superResilient: [superResilient21, superResilient22],
  superCalm: [superCalm21, superCalm22],
  superBrave: [superBrave21, superBrave22],
  superFearless: [superFearless21, superFearless22],
  superSmart: [superSmart21, superSmart22],
};

const item3 = {
  superStrong: [superStrong31, superStrong32],
  superResilient: [superResilient31, superResilient32],
  superCalm: [superCalm31, superCalm32],
  superBrave: [superBrave31, superBrave32],
  superFearless: [superFearless31, superFearless32],
  superSmart: [superSmart31, superSmart32],
};
const item4 = {
  superStrong: [superStrong41, superStrong42],
  superResilient: [superResilient41, superResilient42],
  superCalm: [superCalm41, superCalm42],
  superBrave: [superBrave41, superBrave42],
  superFearless: [superFearless41, superFearless42],
  superSmart: [superSmart41, superSmart42],
};
const item5 = {
  superStrong: [superStrong51, superStrong52],
  superResilient: [superResilient51, superResilient52],
  superCalm: [superCalm51, superCalm52],
  superBrave: [superBrave51, superBrave52],
  superFearless: [superFearless51, superFearless52],
  superSmart: [superSmart51, superSmart52],
};
const item6 = {
  superStrong: [superStrong61, superStrong62],
  superResilient: [superResilient61, superResilient62],
  superCalm: [superCalm61, superCalm62],
  superBrave: [superBrave61, superBrave62],
  superFearless: [superFearless61, superFearless62],
  superSmart: [superSmart61, superSmart62],
};
const item7 = {
  superStrong: [superStrong71, superStrong72],
  superResilient: [superResilient71, superResilient72],
  superCalm: [superCalm71, superCalm72],
  superBrave: [superBrave71, superBrave72],
  superFearless: [superFearless71, superFearless72],
  superSmart: [superSmart71, superSmart72],
};

export { item1, item2, item3, item4, item5, item6, item7 };
