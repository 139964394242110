import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import "../../../../styles/part_6/page6thirdpage/page6thirdpage.css";
import "../../../../styles/accordian/accordian.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUDIO_SUCCESS } from "../../../../actions/types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "react-bootstrap";
import { DOMAIN_NAME, IMAGE_URL } from "../../../../env/Baseurl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import Header from "../../../global/Header";
import { Helmet } from "react-helmet";
import Sidebar from "../../../global/Sidebar";
import Typography from "@mui/material/Typography";
import idea from "../../../../assets/tip/idea.png";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import tipBackground from "../../../../assets/tip/Vector.png";
import { useNavigate } from "react-router-dom";

const Part4ThirdPage = () => {
  const [currentAudio, setCurrentAudio] = useState({
    audio: 0,
    enabledAudio: 0,
    completed: false,
  });
  const [isPlaying, setIsPlaying] = useState(true);
  const [buttonState, setButtonState] = useState(true);
  const title1 = "Approval Addiction";
  const title2 = "The Social Anxiety Formula";

  /*******  hooks  *******/
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { audio } = useSelector((state) => state.audio);

  /****Functions********/
  const handleAudioStoreData = () => {
    return dispatch({
      type: AUDIO_SUCCESS,
      payload: [],
    });
  };

  const nextPage = () => {
    handleAudioStoreData();
    navigate("/part_4/time_to_plan", { replace: true });
  };

  const previousPage = () => {
    handleAudioStoreData();
    navigate(`/part_4/perfectionism`, { replace: true });
  };

  const checkForContinueButton = async () => {
    let audios = audio?.audioData?.length;
    if (audios > 0) {
      if (currentAudio?.audio === audios - 1) {
        setButtonState(false);
      }
    } else if (audios === 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkForContinueButton();
  }, [currentAudio.audio]);

  // //Function to print
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;
    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    document.getElementById("printFooter").style.display = "block";
    window.print();
    // document.getElementById('main').innerHTML = realData;
    document.getElementById("printAble").innerHTML = secondData;
    // document.getElementById("printFooter").style.display = "block";
    // document.getElementById("main").style.display = "block";
    document.getElementById("mainContainer").style.display = "block";
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>
            {" "}
            Approval Addiction the Social Anxiety Formula | ASRC iBroadway
          </title>
        </Helmet>
        <div>
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div id="main">
                <div className="card-body p-0 mb-0">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title1}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p className="component-heading-style">
                            Learning to let go of what other people think of you
                          </p>
                          <p>
                            If someone disapproves of something you have said or
                            done, it doesn’t have to affect you. The impact of
                            the disapproval of others depends on you buying into
                            their thinking. The less you care what others think
                            of you, the more confident and happy you’ll be. This
                            is a skill that successful people in virtually all
                            areas of life have mastered.
                          </p>
                          <div className="main-container my-5">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part4/part4thirdPage/196.png`}
                                  alt="learning"
                                />
                              </div>
                            </div>
                          </div>
                          <p className="component-heading-style">
                            Avoid giving people power over you
                          </p>
                          <p>
                            When you’re in constant need of approval from
                            someone, you’re giving that person POWER over you.
                            This person only needs to stop smiling at you one
                            morning, and you may spend the rest of the day
                            wondering what you have done wrong. The most likely
                            explanation of why they didn’t smile will be
                            something trivial such as:
                          </p>
                          <div className="main-container my-5">
                            <div className=" sub-container center-align">
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part4/part4thirdPage/197.png`}
                                  alt="power"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            Most of the time other people’s moods will have
                            nothing to do with you. And if someone does
                            disapprove of you, so what? Social disapproval
                            rarely comes with a real cost. You won’t lose a limb
                            because someone didn’t like the way you spoke, or
                            the colour of your hair, or the way you dress.
                          </p>
                          <div className="tip-container">
                            <div className="tip-image-container">
                              <img
                                className="part6_bar_image"
                                src={tipBackground}
                                alt="tip-background"
                                width="923px"
                              />
                            </div>
                            <div className="idea">
                              <img
                                className="part6_bar_image"
                                src={idea}
                                alt="tip"
                              />
                            </div>
                            <div className="tip-content">
                              <span className="whiteText">
                                Often people have
                                <span className="yellowText"> power</span> over
                                us because we let them
                              </span>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={
                      audio?.audioData?.length > 0
                        ? currentAudio?.enabledAudio < 1
                          ? true
                          : false
                        : false
                    }
                    onClick={() => {
                      if (currentAudio?.enabledAudio < 1) return false;
                      else {
                        setIsPlaying(true);
                        setCurrentAudio({ ...currentAudio, audio: 1 });
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordion-heading"
                    >
                      <Typography>
                        <div className="page-title-style m-0">{title2}</div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="card-body p-0">
                          <p>
                            In general, most people exaggerate the real
                            LIKELIHOOD and COST of social disapproval. You need
                            to perceive a LIKELIHOOD and a COST of disapproval
                            to create the feeling of anxiety.
                          </p>
                          <div className="main-container my-5">
                            <div
                              className=" sub-container center-align"
                              style={{ maxWidth: "800px" }}
                            >
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part4/part4thirdPage/formula.jpg`}
                                  alt="formule"
                                />
                              </div>
                            </div>
                          </div>
                          <p>
                            Of course, positive feedback feels good, and nobody
                            is telling you to stop enjoying that. But being
                            overly dependent on it gives other people power over
                            you. When you become dependent on the approval of
                            others, you are handing over your emotional life to
                            these people. Your mood will swing with the approval
                            and disapproval of others. You need to learn to
                            value yourself without constant reassurance from
                            others.
                          </p>
                          <p>
                            Just a final note – think about the last time you
                            disapproved of someone. Did it really change them in
                            any way? Does someone become a lesser person just
                            because you disapprove of them? Why give anyone else
                            the power over who you are, your moods and your
                            self-worth? It doesn’t make any sense.
                          </p>
                          <p>
                            Before finishing this section, we want you to read
                            the closing line on ‘Approval Addiction’ from David
                            Burn’s book, Feeling Good:
                          </p>
                          <div className="main-container my-5">
                            <div
                              className="sub-container center-align"
                              style={{ maxWidth: "800px" }}
                            >
                              <div className="image-container">
                                <img
                                  src={`${IMAGE_URL}images/part4/part4thirdPage/201.jpg`}
                                  alt="approval addiction"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="tip-container">
                            <div className="tip-image-container">
                              <img
                                className="part6_bar_image"
                                style={{ maxWidth: "1050px" }}
                                src={tipBackground}
                                alt="tip-background"
                                // width="923px"
                                // max-width='1050px'
                              />
                            </div>
                            <div className="idea">
                              <img
                                className="part6_bar_image"
                                src={idea}
                                alt="tip"
                              />
                            </div>
                            <div className="tip-content">
                              <span className="whiteText">
                                Not accepting others criticisms of you is
                                empowering because it shows they don’t have
                                control over you.
                                {/* <span className="yellowText"> perfectionism</span> */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <FooterOfPrint />
                </div>
              </div>
              <div className="row mx-0 bottom-button-container">
                <div className="previous1 col-6 px-0">
                  <Button
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                </div>
                <div className="next col-6 px-0">
                  <Button
                    disabled={
                      audio?.audioData?.length > 0 ? buttonState : false
                    }
                    className="user-reponse-submit btn btn-primary link-sty m-0"
                    onClick={nextPage}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 dashboard-footer">
          <Footer
            currentAudio={currentAudio}
            setCurrentAudio={setCurrentAudio}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        </div>
        {/* </div > */}
      </div>
    </>
  );
};

export default Part4ThirdPage;
