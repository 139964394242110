import React, { useState, useEffect, memo } from "react";
import "../../styles/Slider/slider.css";
import book from "../../assets/GIf/Icon awesome-book-open.png";
import close from "../../assets/GIf/close.svg";
// import { closeMotivatorsSlider } from "../../services/helpers";
import { DOMAIN_NAME, IMAGE_URL } from "../../env/Baseurl";

const SuperHeroSlider = ({
  motivatorsImage,
  openSuperHeroSlider,
  setOpenSuperHeroSlider,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [freeComicBookSlider, setFreeComicBookSlider] = useState(false);
  const [lastCallout, setLastCallout] = useState(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const callOutRef = localStorage.getItem("lastCallOut");
  // console.log(callOutRef, "its last call out");

  const hideSlider = () => {
    // setIsClose(true)
    setOpenSuperHeroSlider(false);
  };

  return (
    <>
      {callOutRef && (
        <div
          className={`comic-position ${lastCallout ? "comic" : ""}`}
          id="comic-motivators"
        >
          <div className="topBanner">
            <h1>
              {
                {
                  superStrong: "SUPERSTRONG",
                  superBrave: "SUPERBRAVE",
                  superCalm: "SUPERCALM",
                  superFearless: "SUPERFEARLESS",
                  superResilient: "SUPERRESILIENT",
                  superSmart: "SUPERSMART",
                }[user?.userInfo?.superherosect]
              }{" "}
              Says
            </h1>
            <img
              id="close"
              onClick={() => setLastCallout(false)}
              className="imageStyle"
              src={close}
              alt="close"
            />
          </div>
          <div className="imageDiv">
            <img className="imageStyle" src={callOutRef} alt="heroQuote" />
          </div>
        </div>
      )}
      {/* {free Comic book callout} */}
      <div
        className={`comic-position ${freeComicBookSlider ? "comic" : ""}`}
        id="comic-motivators"
      >
        <div className="topBanner">
          <h1>
            {
              {
                superStrong: "SUPERSTRONG",
                superBrave: "SUPERBRAVE",
                superCalm: "SUPERCALM",
                superFearless: "SUPERFEARLESS",
                superResilient: "SUPERRESILIENT",
                superSmart: "SUPERSMART",
              }[user?.userInfo?.superherosect]
            }{" "}
            Says
          </h1>
          <img
            id="close"
            onClick={() => setFreeComicBookSlider(false)}
            className="imageStyle"
            src={close}
            alt="close"
          />
        </div>
        <div className="imageDiv">
          <img
            className="imageStyle"
            src={
              {
                superStrong: `${IMAGE_URL}images/motivatorComicBook/SuperStrongComic.jpg`,
                superBrave: `${IMAGE_URL}images/motivatorComicBook/SuperBraveComic.jpg`,
                superCalm: `${IMAGE_URL}images/motivatorComicBook/SuperCalmComic.jpg`,
                superFearless: `${IMAGE_URL}images/motivatorComicBook/SuperFearlessComic.jpg`,
                superResilient: `${IMAGE_URL}images/motivatorComicBook/SuperResilientComie.jpg`,
                superSmart: `${IMAGE_URL}images/motivatorComicBook/SuperSmartComic.jpg`,
              }[user?.userInfo?.superherosect]
            }
            alt="heroQuote"
          />
        </div>
      </div>
      {/* {slider } */}
      <div
        className={`sliderFromSide-position ${
          openSuperHeroSlider ? "sliderFromSide" : ""
        }`}
      >
        <section className="section">
          <div className="firstDiv">
            <img
              className="imageStyle"
              src={
                {
                  superStrong: `${IMAGE_URL}images/superheroImages/superStrong.png`,
                  superBrave: `${IMAGE_URL}images/superheroImages/SuperBrave.png`,
                  superCalm: `${IMAGE_URL}images/superheroImages/SuperCalm.png`,
                  superFearless: `${IMAGE_URL}images/superheroImages/SuperFearless.png`,
                  superResilient: `${IMAGE_URL}images/superheroImages/SuperSilient.png`,
                  superSmart: `${IMAGE_URL}images/superheroImages/SuperSmart.png`,
                }[user?.userInfo?.superherosect]
              }
              alt="super-Hero"
            />
          </div>
          <div className="secondDiv">
            <div className="seprator">
              <p className="heading">
                {
                  {
                    superStrong: "SUPERSTRONG",
                    superBrave: "SUPERBRAVE",
                    superCalm: "SUPERCALM",
                    superFearless: "SUPERFEARLESS",
                    superResilient: "SUPERRESILIENT",
                    superSmart: "SUPERSMART",
                  }[user?.userInfo?.superherosect]
                }
              </p>
              <p onClick={() => hideSlider()}>X</p>
            </div>
            <p className="details">
              {
                {
                  superStrong:
                    "SuperStrong is a brave and fearless character. They have the power to not care what other people think of them. SuperStrong fights off bullies by ignoring everything they say",
                  superBrave:
                    "SuperBrave is a courageous and brainy character. They have the power to accept the things they can’t change. SuperBrave accepts people as they are. They recognise not everyone likes them but they seem unmoved or unconcerned by this.",
                  superCalm:
                    "SuperCalm is a cool and graceful character. They have the power to ignore the negative chatter in their mind and stay in the present. Supercalm continues to pursue their interests even when roadblocks get in the way.",
                  superFearless:
                    "SuperFearless is unafraid of anything. SuperFearless can speak confidently in front of groups of people, to teachers, powerful people, and strangers. They don’t care if they stutter when speaking.",
                  superResilient:
                    "SuperResilient is powerful. They move towards their fears and into uncomfortable situations. SuperResilient can brush off things that go wrong with ease.",
                  superSmart:
                    "Supersmart is a powerhouse. Supersmart is a hardworking scientist who conducts experiments everyday by facing their fears. They never avoid talking to friends, workmates, or powerful people and work towards their goals every day.",
                }[user?.userInfo?.superherosect]
              }
            </p>
            <div
              className="callOutButton"
              onClick={() => {
                setLastCallout(true);
                setFreeComicBookSlider(false);
              }}
            >
              <p>View Last Callout</p>
            </div>
            <div
              className="comicBook"
              onClick={() => {
                setFreeComicBookSlider(true);
                setLastCallout(false);
              }}
            >
              <img className="imageStyle" src={book} alt="Book" />
              <p>Collect free Comic Book</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default memo(SuperHeroSlider);
